import swal from 'sweetalert2'

const CustomAlert = ({
    title,
    text = '',
    icon = 'success',
    showConfirmButton = true,
    showCloseButton = true,
    showDenyButton = false,
    showCancelButton = false,
    confirmButtonText = 'Continuar',
    denyButtonText = 'Cancelar',
    // optional props
    handleThen,
    handleConfirm,
    handleCancel,
    ...props
}) => {
    let alert = {
        title,
        text,
        icon,
        showConfirmButton,
        showCloseButton,
        confirmButtonText,
        showCancelButton,
        showDenyButton,
        denyButtonText,
    }
    if (props.timer) {
        alert.timerProgressBar = true
    }
    swal.fire({ ...alert, ...props }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (handleThen) handleThen()
        if (result.isConfirmed && handleConfirm) handleConfirm()
        else if (result.isDenied && handleCancel) handleCancel()
    })
}

export default CustomAlert
