import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { create } from 'jss'
import rtl from 'jss-rtl'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import GlobalStyles from 'src/components/GlobalStyles'
import ScrollReset from 'src/components/ScrollReset'
import GoogleAnalytics from 'src/components/GoogleAnalytics'
// import SettingsNotification from 'src/components/SettingsNotification';
import useSettings from 'src/hooks/useSettings'
import { createThemeApp } from 'src/theme'
import routes, { renderRoutes } from 'src/routes'
import AuthState from './contexts/auth/AuthState'
import VehicleState from './contexts/vehicle/VehicleState'
import PreownedState from './contexts/preowned/PreownedState'
import AlertState from './contexts/alert/AlertState'
import UserState from './contexts/user/UserState'
import StoreState from './contexts/store/StoreState'
import MakeState from './contexts/make/MakeState'
import VersionState from './contexts/version/VersionState'
import PromotionState from './contexts/promotion/PromotionState'
import CategoryState from './contexts/category/CategoryState'
import JobCategoryState from './contexts/jobCategory/JobCategoryState'
import MediaState from './contexts/media/MediaState'
import JobState from './contexts/job/JobState'
import ApplicantState from './contexts/applicant/ApplicantState'
import CarState from './contexts/car/CarState'
import DesignState from './contexts/design/DesignState'
import CampaignState from './contexts/campaign/CampaignState'
import SegmentationState from './contexts/segmentation/SegmentationState'
import CardState from './contexts/card/CardState'
import TicketState from './contexts/ticket/TicketState'
import HistoryState from './contexts/history/HistoryState'
import ComplaintState from './contexts/complaint/ComplaintState'
import PlanResultsState from './contexts/planResults/planResultsState'
import PlanState from './contexts/plan/PlanState'
import NotificationState from './contexts/notification/NotificationState'
import SupplierState from './contexts/supplier/SupplierState'
import ShoppingState from './contexts/shopping/ShoppingState'
import './assets/css/main.css'

import { LicenseInfo } from '@mui/x-license-pro'
LicenseInfo.setLicenseKey(process.env.REACT_APP_DATAGRID_KEY)

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const history = createBrowserHistory()

const App = () => {
    const { settings } = useSettings()

    const theme = createThemeApp({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
    })

    return (
        <ThemeProvider theme={theme}>
            <StylesProvider jss={jss}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <SnackbarProvider dense maxSnack={3}>
                        <Router history={history}>
                            <AlertState>
                                <PlanState>
                                    <PlanResultsState>
                                        <AuthState>
                                            <UserState>
                                                <StoreState>
                                                    <MakeState>
                                                        <VehicleState>
                                                            <PreownedState>
                                                                <VersionState>
                                                                    <PromotionState>
                                                                        <CategoryState>
                                                                            <JobCategoryState>
                                                                                <MediaState>
                                                                                    <JobState>
                                                                                        <CarState>
                                                                                            <SegmentationState>
                                                                                                <DesignState>
                                                                                                    <ApplicantState>
                                                                                                        <CampaignState>
                                                                                                            <ComplaintState>
                                                                                                                <CardState>
                                                                                                                    <NotificationState>
                                                                                                                        <TicketState>
                                                                                                                            <GlobalStyles />
                                                                                                                            <ScrollReset />
                                                                                                                            <GoogleAnalytics />
                                                                                                                            <HistoryState>
                                                                                                                                <ShoppingState>
                                                                                                                                    <SupplierState>
                                                                                                                                        {/* <SettingsNotification /> */}
                                                                                                                                        {renderRoutes(
                                                                                                                                            routes
                                                                                                                                        )}
                                                                                                                                    </SupplierState>
                                                                                                                                </ShoppingState>
                                                                                                                            </HistoryState>
                                                                                                                        </TicketState>
                                                                                                                    </NotificationState>
                                                                                                                </CardState>
                                                                                                            </ComplaintState>
                                                                                                        </CampaignState>
                                                                                                    </ApplicantState>
                                                                                                </DesignState>
                                                                                            </SegmentationState>
                                                                                        </CarState>
                                                                                    </JobState>
                                                                                </MediaState>
                                                                            </JobCategoryState>
                                                                        </CategoryState>
                                                                    </PromotionState>
                                                                </VersionState>
                                                            </PreownedState>
                                                        </VehicleState>
                                                    </MakeState>
                                                </StoreState>
                                            </UserState>
                                        </AuthState>
                                    </PlanResultsState>
                                </PlanState>
                            </AlertState>
                        </Router>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </ThemeProvider>
    )
}

export default App
