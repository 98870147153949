import React, { useReducer } from 'react'
import VersionContext from './versionContext'
import VersionReducer from './versionReducer'
import api from '../../api/api'
import {
    GET_VERSIONS,
    GET_VERSIONS_BY_VEHICLE,
    GET_VERSION,
    DELETE_VERSION,
    UPDATE_VERSION,
    CREATE_VERSION,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
} from '../types'

const VersionState = props => {
    const initialState = {
        versions: [],
        version: {},
        loading: false,
        error: null,
    }

    const [state, dispatch] = useReducer(VersionReducer, initialState)

    //Get Versions
    const getVersionsByVehicle = async vehicleId => {
        setLoading()
        try {
            const res = await api.get(`vehicles/${vehicleId}/versions`)
            dispatch({ type: GET_VERSIONS_BY_VEHICLE, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Versions
    const getVersions = async () => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/versions?sort=model`)
            dispatch({ type: GET_VERSIONS, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Versions
    const getVersion = async versionId => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/versions/${versionId}`)
            dispatch({ type: GET_VERSION, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Version
    const deleteVersion = async versionId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/versions/${versionId}`, config)
            dispatch({ type: DELETE_VERSION, payload: res.data.deletedId })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create Version
    const createVersion = async version => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            let res
            if (version.file) {
                let image = await api.post(
                    '/utils/uploads/image',
                    {
                        type: version.file.type,
                        fileName: version.file.name,
                        folder: version.model,
                    },
                    config
                )
                await api.put(image.data.url, version.file, {
                    headers: {
                        'Content-Type': version.file ? version.file.type : null,
                    },
                })
                version.image = image.data.key
                delete version.file
            }

            res = await api.post(
                `vehicles/${version.model}/versions`,
                version,
                config
            )
            dispatch({ type: CREATE_VERSION, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Update Version
    const updateVersion = async (version, versionId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.put(`/versions/${versionId}`, version, config)
            dispatch({ type: UPDATE_VERSION, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <VersionContext.Provider
            value={{
                loading: state.loading,
                versions: state.versions,
                version: state.version,
                error: state.error,
                getVersionsByVehicle,
                getVersions,
                getVersion,
                createVersion,
                deleteVersion,
                updateVersion,
                clearState,
                setLoading,
            }}
        >
            {props.children}
        </VersionContext.Provider>
    )
}

export default VersionState
