import React, { useReducer } from 'react'
import supplierReducer from './supplierReducer'
import SupplierContext from './supplierContext'
import api from '../../api/api'
import {
    GET_PROVIDERS,
    GET_PROVIDER,
    DELETE_PROVIDER,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    CREATE_PROVIDER,
    GET_PROVIDERSV3,
    UPDATE_PROVIDER,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

const SupplierState = props => {
    const initialState = {
        proveedores: [],
        proveedor: {},
        loading: false,
        error: null,
        count: 0,
    }

    const [state, dispatch] = useReducer(supplierReducer, initialState)

    //Get providers
    const getSuppliers = async () => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/providers`)
            dispatch({ type: GET_PROVIDERS, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Promotion
    const getSupplier = async providerId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            const res = await api.get(`/providers/${providerId}`, config)
            dispatch({ type: GET_PROVIDER, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Promotion
    const deleteProvider = async providerId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/providers/${providerId}`, config)
            dispatch({ type: DELETE_PROVIDER, payload: res.data.deletedId })
            CustomAlert({
                title: 'Provider Delete',
                icon: 'error',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }
    const getSupplierV3 = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`/providers/getProviderv3`, params)
            dispatch({
                type: GET_PROVIDERSV3,
                payload: res.data.results.data,
                count: res.data.results.pagination,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create Promotion
    const createProvider = async value => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            if (value && value.photo) {
                let image = await api.post(
                    '/utils/uploads/image',
                    {
                        type: value.photo.type,
                        fileName: value.photo.name,
                        folder: 'provider',
                    },
                    config
                )

                await api.put(image.data.url, value.photo, {
                    headers: {
                        'Content-Type': value.photo ? value.photo.type : null,
                    },
                })
                value.image = image.data.key
                delete value.photo
            }

            const res = await api.post(`/providers`, { ...value }, config)
            dispatch({ type: CREATE_PROVIDER, payload: res.data.data })
            CustomAlert({
                title: 'Provider created',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }
    //update provider
    const updateProvider = async (provider, providerId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            //se pregunta si existe un cambio en la foto de la proveedor para poder actualizarla
            if (provider.photo) {
                let image = await api.post(
                    '/utils/uploads/image',
                    {
                        type: provider.photo.type,
                        fileName: provider.photo.name,
                        folder: 'provider',
                    },
                    config
                )

                await api.put(image.data.url, provider.photo, {
                    headers: {
                        'Content-Type': provider.photo
                            ? provider.photo.type
                            : null,
                    },
                })
                provider.image = image.data.key
                delete provider.photo
            }
            const res = await api.put(
                `/providers/${providerId}`,
                provider,
                config
            )
            dispatch({ type: UPDATE_PROVIDER, payload: res.data.data })
            CustomAlert({
                title: 'provider updated',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <SupplierContext.Provider
            value={{
                loading: state.loading,
                proveedores: state.proveedores,
                proveedor: state.proveedor,
                error: state.error,
                count: state.count,
                createProvider,
                getSuppliers,
                getSupplierV3,
                getSupplier,
                deleteProvider,
                clearState,
                setLoading,
                updateProvider,
            }}
        >
            {props.children}
        </SupplierContext.Provider>
    )
}

export default SupplierState
