import React, { useReducer } from 'react'
import HistoryContext from './historyContext'
import HistoryReducer from './historyReducer'
import api from '../../api/api'
import {
    GET_HISTORY_BY_VEHICLE,
    CREATE_HISTORY,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    GET_HISTORY_BY_PREOWNED,
    GET_HISTORY,
} from '../types'

const HistoryState = props => {
    const initialState = {
        histories: [],
        history: {},
        loading: false,
        error: null,
    }
    const [state, dispatch] = useReducer(HistoryReducer, initialState)
    //Get History
    const getHistory = async vehicleId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            const res = await api.get(`history/vehicle/${vehicleId}`, config)
            dispatch({ type: GET_HISTORY_BY_VEHICLE, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }
    //get history by preowned
    const getHistoryByPreowned = async preownedId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            const res = await api.get(`history/preowned/${preownedId}`, config)
            dispatch({ type: GET_HISTORY_BY_PREOWNED, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //get history by promotion
    const getHistoryByPromotion = async promotionId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            const res = await api.get(
                `history/promotion/${promotionId}`,
                config
            )
            dispatch({ type: GET_HISTORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //get history by car
    const getHistoryByCar = async carId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            const res = await api.get(`history/car/${carId}`, config)
            dispatch({ type: GET_HISTORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //get history by card
    const getHistoryByCard = async cardId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            const res = await api.get(`history/card/${cardId}`, config)
            dispatch({ type: GET_HISTORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //get history by store
    const getHistoryByStore = async storeId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            const res = await api.get(`history/store/${storeId}`, config)
            dispatch({ type: GET_HISTORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //get history by campaign
    const getHistoryByCampaign = async campaignId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            const res = await api.get(`history/campaign/${campaignId}`, config)
            dispatch({ type: GET_HISTORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //get history by segmentation
    const getHistoryBySegmentation = async segmentationId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            const res = await api.get(
                `history/segmentation/${segmentationId}`,
                config
            )
            dispatch({ type: GET_HISTORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //get history by designs
    const getHistoryByDesign = async designId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            const res = await api.get(`history/designs/opv/${designId}`, config)
            dispatch({ type: GET_HISTORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //get history by Shoppings
    const getHistoryByShopping = async shoppingId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            const res = await api.get(
                `history/shoppings/shop/${shoppingId}`,
                config
            )
            dispatch({ type: GET_HISTORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const createHistory = async history => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            const res = await api.post(`/history/ ${history}`, config)
            dispatch({ type: CREATE_HISTORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }
    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })
    return (
        <HistoryContext.Provider
            value={{
                loading: state.loading,
                count: state.count,
                histories: state.histories,
                history: state.history,
                error: state.error,
                clearState,
                setLoading,
                getHistory,
                createHistory,
                getHistoryByPreowned,
                getHistoryByPromotion,
                getHistoryByCar,
                getHistoryByCard,
                getHistoryByStore,
                getHistoryByCampaign,
                getHistoryBySegmentation,
                getHistoryByDesign,
                getHistoryByShopping,
            }}
        >
            {props.children}
        </HistoryContext.Provider>
    )
}
export default HistoryState
