import React, { useReducer } from 'react'
import VehicleContext from './vehicleContext'
import VehicleReducer from './vehicleReducer'
import api from '../../api/api'
import {
    GET_VEHICLES,
    GET_VEHICLES_BY_MAKE,
    GET_VEHICLE,
    DELETE_VEHICLE,
    UPDATE_VEHICLE,
    CREATE_VEHICLE,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    CHANGE_INDEX,
    UPDATE_INDEX,
} from '../types'

const VehicleState = props => {
    const initialState = {
        vehicles: [],
        vehicle: {},
        loading: false,
        error: null,
        count: 0,
        success: false,
    }

    const [state, dispatch] = useReducer(VehicleReducer, initialState)

    //Get Vehicles
    const getVehiclesByMake = async (makeId, { page, limit, query }) => {
        setLoading()
        try {
            const res = await api.get(
                `/vehicles/getVehiclesDashboard?page=${page +
                    1}&limit=${limit}&searchIndex=model-category-make-year&searchText=${query}&makeUser=${makeId}&sort=index&dash=2`
            )
            dispatch({
                type: GET_VEHICLES_BY_MAKE,
                payload: res.data.data,
                count: res.data.pagination,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Vehicles
    const getVehiclesByMakeId = async makeId => {
        setLoading()
        try {
            const res = await api.get(
                `/vehicles/getVehiclesByMakeId?make=${makeId}`
            )
            dispatch({
                type: GET_VEHICLES_BY_MAKE,
                payload: res.data.data,
                count: 0,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Vehicles
    const getVehiclesByMultipleMakes = async (
        arrayMakes,
        { page, limit, query }
    ) => {
        setLoading()
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            }
            const res = await api.post(
                `/vehicles/getVehiclesMutipleDashboard?page=${page +
                    1}&limit=${limit}&searchIndex=model-category-make-year&searchText=${query}&sort=index&dash=2`,
                { makes: arrayMakes },
                config
            )
            dispatch({
                type: GET_VEHICLES_BY_MAKE,
                payload: res.data.data,
                count: res.data.pagination,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const getAllVehiclesByMake = async makeId => {
        setLoading()
        try {
            const res = await api.get(`/vehicles?make=${makeId}&sort=index`)
            dispatch({
                type: GET_VEHICLES_BY_MAKE,
                payload: res.data.data,
                count: res.data.pagination,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Change index
    const setIndex = async (newIndex, oldIndex, start, end) => {
        // setLoading();
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        try {
            const res = await api.post(
                `vehicles/setIndex`,
                { newIndex, oldIndex },
                config
            )
            dispatch({
                type: CHANGE_INDEX,
                payload: { ...res.data.data, start, end },
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const updateIndex = async ({ newData, oldData }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        try {
            let indexes = oldData.map(vehicle => vehicle.index)
            let updatedVehicles = []
            let vehicles = newData.map((vehicle, vehicleIndex) => {
                updatedVehicles.push({
                    ...vehicle,
                    index: indexes[vehicleIndex],
                })
                return {
                    _id: vehicle._id,
                    index: indexes[vehicleIndex],
                }
            })
            await api.post(`vehicles/updateIndex`, { vehicles }, config)
            dispatch({
                type: UPDATE_INDEX,
                payload: updatedVehicles,
            })
        } catch (err) {
            console.log(err)
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Vehicles
    const getVehicles = async ({ limit, page, query }) => {
        setLoading()
        try {
            const res = await api.get(
                `/vehicles/getVehiclesDashboard?page=${page +
                    1}&limit=${limit}&searchIndex=model-category-make-year&searchText=${query}&sort=index`
            )
            dispatch({
                type: GET_VEHICLES,
                payload: res.data.data,
                count: res.data.pagination,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const getVehiclesV3 = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`vehicles/getVehiclesV3`, params)
            dispatch({
                type: GET_VEHICLES,
                payload: res.data.results.data,
                count: res.data.results.pagination.total,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Vehicles
    const getVehicle = async vehicleId => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/vehicles/${vehicleId}`)
            dispatch({ type: GET_VEHICLE, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Vehicle
    const deleteVehicle = async vehicleId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/vehicles/${vehicleId}`, config)
            dispatch({ type: DELETE_VEHICLE, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create Vehicle
    const createVehicle = async vehicle => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const {
                colors,
                gallery,
                features,
                versions,
                meta,
                promotions,
            } = vehicle

            const filesColors = []
            let newColors = []

            const filesGallery = []
            let newGallery = []

            const filesPromotions = []
            let newPromotions = []

            const filesFeatures = []
            let newFeatures = []

            const filesVersions = []
            let newVersions = []

            gallery.map(item => filesGallery.push(item.file))

            promotions.map(item => filesPromotions.push(item.file))

            colors.map(item => filesColors.push(item))

            features.map(item => filesFeatures.push(item))

            versions.map(item => filesVersions.push(item))

            //Load Meta Information
            if (meta && meta.fileImage) {
                let metaImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: meta.fileImage.type,
                        fileName: meta.fileImage.name,
                        folder: 'vehicles',
                    },
                    config
                )
                await api.put(metaImage.data.url, meta.fileImage, {
                    headers: {
                        'Content-Type': meta.fileImage
                            ? meta.fileImage.type
                            : null,
                    },
                })

                vehicle.meta.image = metaImage.data.key
                delete vehicle.meta.fileImage
            }

            if (vehicle.fileMainImage) {
                let mainImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: vehicle.fileMainImage.type,
                        fileName: vehicle.fileMainImage.name,
                        folder: 'news',
                    },
                    config
                )
                await api.put(mainImage.data.url, vehicle.fileMainImage, {
                    headers: {
                        'Content-Type': vehicle.fileMainImage
                            ? vehicle.fileMainImage.type
                            : null,
                    },
                })
                vehicle.mainImage = mainImage.data.key
                delete vehicle.fileMainImage
            }

            if (vehicle.technicalFile) {
                let technicalImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: vehicle.technicalFile.type,
                        fileName: vehicle.technicalFile.name,
                        folder: vehicle._id,
                    },
                    config
                )
                await api.put(technicalImage.data.url, vehicle.technicalFile, {
                    headers: {
                        'Content-Type': vehicle.technicalFile
                            ? vehicle.technicalFile.type
                            : null,
                    },
                })
                vehicle.technicalSheet = technicalImage.data.key
                delete vehicle.technicalFile
            }

            if (vehicle.fileBannerImage) {
                let banner = await api.post(
                    '/utils/uploads/image',
                    {
                        type: vehicle.fileBannerImage.type,
                        fileName: vehicle.fileBannerImage.name,
                        folder: vehicle._id,
                    },
                    config
                )
                await api.put(banner.data.url, vehicle.fileBannerImage, {
                    headers: {
                        'Content-Type': vehicle.fileBannerImage
                            ? vehicle.fileBannerImage.type
                            : null,
                    },
                })
                vehicle.banner = banner.data.key
                delete vehicle.fileBannerImage
            }

            if (vehicle.fileMobileBannerImage) {
                let banner = await api.post(
                    '/utils/uploads/image',
                    {
                        type: vehicle.fileMobileBannerImage.type,
                        fileName: vehicle.fileMobileBannerImage.name,
                        folder: vehicle._id,
                    },
                    config
                )
                await api.put(banner.data.url, vehicle.fileMobileBannerImage, {
                    headers: {
                        'Content-Type': vehicle.fileMobileBannerImage
                            ? vehicle.fileMobileBannerImage.type
                            : null,
                    },
                })
                vehicle.mobileBanner = banner.data.key
                delete vehicle.fileMobileBannerImage
            }

            for (let i = 0; i < filesVersions.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesVersions[i].file.type,
                        fileName: filesVersions[i].file.name,
                        folder: 'news',
                    },
                    config
                )
                await api.put(resImage.data.url, filesVersions[i].file, {
                    headers: {
                        'Content-Type': filesVersions[i].file
                            ? filesVersions[i].file.type
                            : null,
                    },
                })
                delete filesVersions[i].file
                newVersions.push({
                    ...filesVersions[i],
                    image: resImage.data.key,
                })
            }

            for (let i = 0; i < filesFeatures.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesFeatures[i].file.type,
                        fileName: filesFeatures[i].file.name,
                        folder: 'news',
                    },
                    config
                )
                await api.put(resImage.data.url, filesFeatures[i].file, {
                    headers: {
                        'Content-Type': filesFeatures[i].file
                            ? filesFeatures[i].file.type
                            : null,
                    },
                })
                delete filesFeatures[i].file
                newFeatures.push({
                    ...filesFeatures[i],
                    image: resImage.data.key,
                })
            }

            for (let i = 0; i < filesGallery.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesGallery[i].type,
                        fileName: filesGallery[i].name,
                        folder: 'news',
                    },
                    config
                )
                await api.put(resImage.data.url, filesGallery[i], {
                    headers: {
                        'Content-Type': filesGallery[i]
                            ? filesGallery[i].type
                            : null,
                    },
                })
                newGallery.push({ image: resImage.data.key })
            }

            for (let i = 0; i < filesPromotions.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesPromotions[i].type,
                        fileName: filesPromotions[i].name,
                        folder: 'news',
                    },
                    config
                )
                await api.put(resImage.data.url, filesPromotions[i], {
                    headers: {
                        'Content-Type': filesPromotions[i]
                            ? filesPromotions[i].type
                            : null,
                    },
                })
                newPromotions.push({ image: resImage.data.key })
            }

            for (let i = 0; i < filesColors.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesColors[i].file.type,
                        fileName: filesColors[i].file.name,
                        folder: 'news',
                    },
                    config
                )
                await api.put(resImage.data.url, filesColors[i].file, {
                    headers: {
                        'Content-Type': filesColors[i].file
                            ? filesColors[i].file.type
                            : null,
                    },
                })
                delete filesColors[i].file
                newColors.push({
                    ...filesColors[i].color,
                    image: resImage.data.key,
                })
            }

            vehicle = {
                ...vehicle,
                promotions: [...newPromotions],
                gallery: [...newGallery],
                colors: [...newColors],
                features: [...newFeatures],
                versions: [...newVersions],
            }

            const res = await api.post(
                `makes/${vehicle.make}/vehicles`,
                vehicle,
                config
            )
            dispatch({ type: CREATE_VEHICLE, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Update Vehicle
    const updateVehicle = async (vehicle, vehicleId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const {
                colors,
                gallery,
                features,
                promotions,
                versions,
                meta,
            } = vehicle

            const filesColors = []
            let newColors = []
            const oldColors = []

            const filesGallery = []
            let newGallery = []
            const oldGallery = []

            const filesPromotions = []
            let newPromotions = []
            const oldPromotions = []

            const filesFeatures = []
            let newFeatures = []
            const oldFeatures = []

            const orderedVersions = []

            //Load Meta Information
            if (meta && meta.fileImage) {
                let metaImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: meta.fileImage.type,
                        fileName: meta.fileImage.name,
                        folder: vehicle._id,
                    },
                    config
                )
                await api.put(metaImage.data.url, meta.fileImage, {
                    headers: {
                        'Content-Type': meta.fileImage
                            ? meta.fileImage.type
                            : null,
                    },
                })

                vehicle.meta.image = metaImage.data.key
                delete vehicle.meta.fileImage
            }

            gallery.map(item => {
                if (item.file) return filesGallery.push(item.file)
                return oldGallery.push(item)
            })

            promotions.map(item => {
                if (item.file) return filesPromotions.push(item.file)
                return oldPromotions.push(item)
            })

            colors.map(item => {
                if (item.file) return filesColors.push(item)
                return oldColors.push(item)
            })

            features.map(item => {
                if (item.file) return filesFeatures.push(item)
                return oldFeatures.push(item)
            })

            for (let i = 0; i < versions.length; i++) {
                if (versions[i].file) {
                    let resImage = await api.post(
                        '/utils/uploads/image',
                        {
                            type: versions[i].file.type,
                            fileName: versions[i].file.name,
                            folder: vehicle._id,
                        },
                        config
                    )
                    await api.put(resImage.data.url, versions[i].file, {
                        headers: {
                            'Content-Type': versions[i].file
                                ? versions[i].file.type
                                : null,
                        },
                    })
                    delete versions[i].file
                    orderedVersions.push({
                        ...versions[i],
                        image: resImage.data.key,
                    })
                } else {
                    orderedVersions.push(versions[i])
                }
            }

            if (vehicle.fileMainImage) {
                let mainImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: vehicle.fileMainImage.type,
                        fileName: vehicle.fileMainImage.name,
                        folder: vehicle._id,
                    },
                    config
                )
                await api.put(mainImage.data.url, vehicle.fileMainImage, {
                    headers: {
                        'Content-Type': vehicle.fileMainImage
                            ? vehicle.fileMainImage.type
                            : null,
                    },
                })

                vehicle.mainImage = mainImage.data.key
                delete vehicle.fileMainImage
            }

            if (vehicle.technicalFile) {
                let technicalImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: vehicle.technicalFile.type,
                        fileName: vehicle.technicalFile.name,
                        folder: vehicle._id,
                    },
                    config
                )
                await api.put(technicalImage.data.url, vehicle.technicalFile, {
                    headers: {
                        'Content-Type': vehicle.technicalFile
                            ? vehicle.technicalFile.type
                            : null,
                    },
                })
                vehicle.technicalSheet = technicalImage.data.key
                delete vehicle.technicalFile
            }

            if (vehicle.fileBannerImage) {
                let banner = await api.post(
                    '/utils/uploads/image',
                    {
                        type: vehicle.fileBannerImage.type,
                        fileName: vehicle.fileBannerImage.name,
                        folder: vehicle._id,
                    },
                    config
                )
                await api.put(banner.data.url, vehicle.fileBannerImage, {
                    headers: {
                        'Content-Type': vehicle.fileBannerImage
                            ? vehicle.fileBannerImage.type
                            : null,
                    },
                })
                vehicle.banner = banner.data.key
                delete vehicle.fileBannerImage
            }

            if (vehicle.fileMobileBannerImage) {
                let banner = await api.post(
                    '/utils/uploads/image',
                    {
                        type: vehicle.fileMobileBannerImage.type,
                        fileName: vehicle.fileMobileBannerImage.name,
                        folder: vehicle._id,
                    },
                    config
                )
                await api.put(banner.data.url, vehicle.fileMobileBannerImage, {
                    headers: {
                        'Content-Type': vehicle.fileMobileBannerImage
                            ? vehicle.fileMobileBannerImage.type
                            : null,
                    },
                })
                vehicle.mobileBanner = banner.data.key
                delete vehicle.fileMobileBannerImage
            }

            for (let i = 0; i < filesGallery.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesGallery[i].type,
                        fileName: filesGallery[i].name,
                        folder: vehicle._id,
                    },
                    config
                )
                await api.put(resImage.data.url, filesGallery[i], {
                    headers: {
                        'Content-Type': filesGallery[i]
                            ? filesGallery[i].type
                            : null,
                    },
                })
                newGallery.push({ image: resImage.data.key })
            }

            for (let i = 0; i < filesPromotions.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesPromotions[i].type,
                        fileName: filesPromotions[i].name,
                        folder: vehicle._id,
                    },
                    config
                )
                await api.put(resImage.data.url, filesPromotions[i], {
                    headers: {
                        'Content-Type': filesPromotions[i]
                            ? filesPromotions[i].type
                            : null,
                    },
                })
                newPromotions.push({ image: resImage.data.key })
            }

            for (let i = 0; i < filesColors.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesColors[i].file.type,
                        fileName: filesColors[i].file.name,
                        folder: vehicle._id,
                    },
                    config
                )
                await api.put(resImage.data.url, filesColors[i].file, {
                    headers: {
                        'Content-Type': filesColors[i].file
                            ? filesColors[i].file.type
                            : null,
                    },
                })
                newColors.push({
                    ...filesColors[i],
                    image: resImage.data.key,
                })
            }

            for (let i = 0; i < filesFeatures.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesFeatures[i].file.type,
                        fileName: filesFeatures[i].file.name,
                        folder: vehicle._id,
                    },
                    config
                )
                await api.put(resImage.data.url, filesFeatures[i].file, {
                    headers: {
                        'Content-Type': filesFeatures[i].file
                            ? filesFeatures[i].file.type
                            : null,
                    },
                })
                newFeatures.push({
                    ...filesFeatures[i],
                    image: resImage.data.key,
                })
            }

            vehicle = {
                ...vehicle,
                gallery: [...oldGallery, ...newGallery],
                promotions: [...oldPromotions, ...newPromotions],
                colors: [...oldColors, ...newColors],
                features: [...oldFeatures, ...newFeatures],
                versions: orderedVersions,
            }
            const res = await api.put(`/vehicles/${vehicleId}`, vehicle, config)
            dispatch({ type: UPDATE_VEHICLE, payload: res.data.data })
        } catch (err) {
            if (err && err.response && err.response.data) {
                dispatch({ type: SET_ERROR, payload: err.response.data })
            } else {
                dispatch({ type: SET_ERROR, payload: err })
            }
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <VehicleContext.Provider
            value={{
                loading: state.loading,
                count: state.count,
                vehicles: state.vehicles,
                vehicle: state.vehicle,
                error: state.error,
                success: state.success,
                getVehiclesByMake,
                getVehiclesByMakeId,
                getVehicles,
                getVehicle,
                createVehicle,
                deleteVehicle,
                updateVehicle,
                clearState,
                setLoading,
                setIndex,
                getAllVehiclesByMake,
                getVehiclesByMultipleMakes,
                getVehiclesV3,
                updateIndex,
            }}
        >
            {props.children}
        </VehicleContext.Provider>
    )
}

export default VehicleState
