import React, { useReducer } from 'react'
import CardContext from './cardContext'
import CardReducer from './cardReducer'
import api from '../../api/api'
import {
    GET_CARDS,
    GET_CARD,
    UPDATE_CARD,
    DELETE_CARD,
    CREATE_CARD,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'
import { useHistory } from 'react-router-dom'

const CardState = props => {
    const history = useHistory()
    const initialState = {
        cards: [],
        card: {},
        loading: false,
        error: null,
        count: 0,
    }

    const [state, dispatch] = useReducer(CardReducer, initialState)

    //Get Cards
    const getCards = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`/cards/getCardsV3`, params)
            dispatch({
                type: GET_CARDS,
                payload: res.data.results.data,
                count: res.data.results.pagination,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Single Item by ID
    const getCard = async cardId => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/cards/${cardId}`)
            dispatch({
                type: GET_CARD,
                payload: res.data.data,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Card
    const deleteCard = async cardId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/cards/${cardId}`, config)
            dispatch({ type: DELETE_CARD, payload: res.data.deletedId })
            history.push('/app/management/presentation-cards')
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create Card
    const createCard = async card => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            if (card.fileImage) {
                let image = await api.post(
                    '/utils/uploads/image',
                    {
                        type: card.fileImage.type,
                        fileName: card.fileImage.name,
                        folder: 'presentation_cards',
                    },
                    config
                )
                await api.put(image.data.url, card.fileImage, {
                    headers: {
                        'Content-Type': card.fileImage
                            ? card.fileImage.type
                            : null,
                    },
                })
                card.image = image.data.key
                delete card.fileImage
            }

            if (card.fileCard) {
                let final = await api.post(
                    '/utils/uploads/image',
                    {
                        type: card.fileCard.type,
                        fileName: card.fileCard.name,
                        folder: 'presentation_cards',
                    },
                    config
                )
                await api.put(final.data.url, card.fileCard, {
                    headers: {
                        'Content-Type': card.fileCard
                            ? card.fileCard.type
                            : null,
                    },
                })
                card.card = final.data.key
                delete card.fileCard
            }
            const res = await api.post(`/cards`, { ...card }, config)
            dispatch({ type: CREATE_CARD, payload: res.data.data })
            CustomAlert({
                title: 'Tarjeta Creada',
                icon: 'success',
                timer: ErrorShowTime,
            })
            history.push('/app/management/presentation-cards')
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Update Card
    const updateCard = async (card, cardId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            if (card.fileImage) {
                let image = await api.post(
                    '/utils/uploads/image',
                    {
                        type: card.fileImage.type,
                        fileName: card.fileImage.name,
                        folder: 'presentation_cards',
                    },
                    config
                )
                await api.put(image.data.url, card.fileImage, {
                    headers: {
                        'Content-Type': card.fileImage
                            ? card.fileImage.type
                            : null,
                    },
                })
                card.image = image.data.key
                delete card.fileImage
            }

            if (card.fileCard) {
                let final = await api.post(
                    '/utils/uploads/image',
                    {
                        type: card.fileCard.type,
                        fileName: card.fileCard.name,
                        folder: 'presentation_cards',
                    },
                    config
                )
                await api.put(final.data.url, card.fileCard, {
                    headers: {
                        'Content-Type': card.fileCard
                            ? card.fileCard.type
                            : null,
                    },
                })
                card.card = final.data.key
                delete card.fileCard
            }
            const res = await api.put(`/cards/${cardId}`, { ...card }, config)
            dispatch({ type: UPDATE_CARD, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <CardContext.Provider
            value={{
                loading: state.loading,
                cards: state.cards,
                card: state.card,
                error: state.error,
                count: state.count,
                getCards,
                getCard,
                deleteCard,
                createCard,
                updateCard,
                clearState,
                setLoading,
            }}
        >
            {props.children}
        </CardContext.Provider>
    )
}

export default CardState
