import React, { useReducer } from 'react'
import MediaContext from './mediaContext'
import MediaReducer from './mediaReducer'
import api from '../../api/api'
import {
    GET_MEDIAS,
    GET_MEDIAS_BY_VEHICLE,
    GET_MEDIA,
    DELETE_MEDIA,
    UPDATE_MEDIA,
    CREATE_MEDIA,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    GET_MEDIAS_MAIN_BANNER,
} from '../types'

const MediaState = props => {
    const initialState = {
        medias: [],
        mediasMainBanner: [],
        media: {},
        loading: false,
        error: null,
    }

    const [state, dispatch] = useReducer(MediaReducer, initialState)

    const getMediasMainBanner = async (query = '') => {
        setLoading()
        try {
            const res = await api.get(`/medias?section=main-banner${query}`)
            dispatch({ type: GET_MEDIAS_MAIN_BANNER, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Medias
    const getMediasByVehicle = async vehicleId => {
        setLoading()
        try {
            const res = await api.get(`vehicles/${vehicleId}/medias`)
            dispatch({ type: GET_MEDIAS_BY_VEHICLE, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Medias
    const getMedias = async () => {
        setLoading()
        try {
            const res = await api.get(`/medias`)
            dispatch({ type: GET_MEDIAS, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Medias
    const getMedia = async mediaId => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/medias/${mediaId}`)
            dispatch({ type: GET_MEDIA, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Media
    const deleteMedia = async (mediaId, tipo = null) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/medias/${mediaId}`, config)
            dispatch({ type: DELETE_MEDIA, payload: res.data.deletedId, tipo })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const exampleUploadMedia = async (ticket, files) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            let images = []

            for (let i = 0; i < files.length; i++) {
                let response = await api.post(
                    '/utils/uploads/image',
                    {
                        type: files[i].type,
                        fileName: files[i].name,
                        folder: 'tickets',
                    },
                    config
                )

                const { data } = response
                const { url, key } = data

                await api.put(url, files[i], {
                    headers: { 'Content-Type': files[i].type },
                })

                images.push(key)
            }

            ticket.medias = images
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response })
        }
    }

    //Create Media
    const createMedia = async (media, storeId = 'Global') => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            let image = await api.post(
                '/utils/uploads/image',
                { type: media.type, fileName: media.name, folder: storeId },
                config
            )

            await api.put(image.data.url, media, {
                headers: { 'Content-Type': media ? media.type : null },
            })

            let res = await api.post(
                `stores/${storeId}/medias`,
                {
                    name: media.name,
                    image: image.data.key,
                    type: media.type,
                    size: media.size,
                    section: 'main-banner',
                },
                config
            )
            dispatch({
                type: CREATE_MEDIA,
                payload: res.data.data,
                tipo: 'mediasMainBanner',
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response })
        }
    }

    //Update Media
    const updateMedia = async (media, mediaId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.put(`/medias/${mediaId}`, media, config)
            dispatch({
                type: UPDATE_MEDIA,
                payload: res.data.data,
                updatedId: res.data.updatedId,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <MediaContext.Provider
            value={{
                loading: state.loading,
                medias: state.medias,
                media: state.media,
                mediasMainBanner: state.mediasMainBanner,
                error: state.error,
                getMediasByVehicle,
                getMedias,
                getMediasMainBanner,
                getMedia,
                createMedia,
                deleteMedia,
                exampleUploadMedia,
                updateMedia,
                clearState,
                setLoading,
            }}
        >
            {props.children}
        </MediaContext.Provider>
    )
}

export default MediaState
