/*eslint no-loop-func: "off"*/
/*eslint-env es6*/
import {
    GET_CATEGORIES,
    GET_CATEGORY,
    CREATE_CATEGORY,
    DELETE_CATEGORY,
    UPDATE_CATEGORY,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    CHANGE_INDEX,
    UPDATE_INDEX,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

export default (state, action) => {
    switch (action.type) {
        case UPDATE_INDEX:
            CustomAlert({
                title: 'Categorias Reordenadas',
                icon: 'success',
            })
            return {
                ...state,
                categories: action.payload,
                loading: false,
                error: null,
                success: true,
            }
        case CHANGE_INDEX:
            const { oldIndex, newIndex, start, end } = action.payload
            const old = state.categories.findIndex(
                item => item.index === oldIndex
            )
            const id = state.categories[old]._id
            let suma = 1
            let currentIndex
            if (end < start) {
                for (let i = end; i < start; i++) {
                    //eslint-disable-next-line
                    currentIndex = state.categories.findIndex(
                        item => item.index === oldIndex + suma
                    )
                    state.categories[currentIndex].index =
                        state.categories[currentIndex].index - 1
                    suma++
                }

                currentIndex = state.categories.findIndex(
                    item => item._id === id
                )
                state.categories[currentIndex].index = newIndex
            } else {
                for (let i = start; i < end; i++) {
                    //eslint-disable-next-line
                    currentIndex = state.categories.findIndex(
                        item => item.index === oldIndex - suma
                    )
                    state.categories[currentIndex].index =
                        state.categories[currentIndex].index + 1

                    suma++
                }
                currentIndex = state.categories.findIndex(
                    item => item._id === id
                )
                state.categories[currentIndex].index = newIndex
            }
            return {
                ...state,
                loading: false,
            }
        case GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
                count: action.count,
                loading: false,
                error: null,
            }
        case GET_CATEGORY:
            return {
                ...state,
                category: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_CATEGORY:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case DELETE_CATEGORY:
            if (state.categories && state.categories.length > 0) {
                const deletedIndex = state.categories.findIndex(
                    item => item.index === action.payload.deletedIndex
                )
                for (let i = deletedIndex; i < state.categories.length; i++) {
                    state.categories[i].index = state.categories[i].index - 1
                }
                state.categories = state.categories.filter(
                    item =>
                        item._id.toString() !==
                        action.payload.deletedId.toString()
                )
            }
            return {
                ...state,
                category: null,
                loading: false,
                error: null,
            }
        case UPDATE_CATEGORY:
            return {
                ...state,
                category: action.payload,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                category: {},
                categories: [],
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
