/*eslint no-loop-func: "off"*/
/*eslint-env es6*/

import {
    GET_VEHICLES,
    GET_VEHICLES_BY_MAKE,
    GET_VEHICLE,
    CREATE_VEHICLE,
    DELETE_VEHICLE,
    UPDATE_VEHICLE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    CHANGE_INDEX,
    UPDATE_INDEX,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

export default (state, action) => {
    switch (action.type) {
        case CHANGE_INDEX:
            const { oldIndex, newIndex, start, end } = action.payload
            const old = state.vehicles.findIndex(
                item => item.index === oldIndex
            )
            const id = state.vehicles[old]._id
            let suma = 1
            let currentIndex
            if (end < start) {
                for (let i = end; i < start; i++) {
                    //eslint-disable-next-line
                    currentIndex = state.vehicles.findIndex(
                        item => item.index === oldIndex + suma
                    )
                    if (currentIndex !== -1) {
                        state.vehicles[currentIndex].index =
                            state.vehicles[currentIndex].index - 1
                    }

                    suma++
                }

                currentIndex = state.vehicles.findIndex(item => item._id === id)
                state.vehicles[currentIndex].index = newIndex
            } else {
                for (let i = start; i < end; i++) {
                    //eslint-disable-next-line
                    currentIndex = state.vehicles.findIndex(
                        item => item.index === oldIndex - suma
                    )
                    if (currentIndex !== -1) {
                        state.vehicles[currentIndex].index =
                            state.vehicles[currentIndex].index + 1
                    }

                    suma++
                }
                currentIndex = state.vehicles.findIndex(item => item._id === id)
                state.vehicles[currentIndex].index = newIndex
            }
            return {
                ...state,
                loading: false,
            }
        case GET_VEHICLES_BY_MAKE:
            return {
                ...state,
                vehicles: action.payload,
                count: action.count.total,
                loading: false,
                error: null,
            }
        case GET_VEHICLES:
            return {
                ...state,
                count: action.count,
                vehicles: action.payload,
                loading: false,
                error: null,
            }
        case GET_VEHICLE:
            return {
                ...state,
                vehicle: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_VEHICLE:
            return {
                ...state,
                loading: false,
                error: null,
                success: true,
            }
        case DELETE_VEHICLE:
            if (state.vehicles && state.vehicles.length > 0) {
                const deletedIndex = state.vehicles.findIndex(
                    item => item.index === action.payload.deletedIndex
                )
                for (let i = deletedIndex; i < state.vehicles.length; i++) {
                    state.vehicles[i].index = state.vehicles[i].index - 1
                }
                state.vehicles = state.vehicles.filter(
                    item =>
                        item._id.toString() !==
                        action.payload.deletedId.toString()
                )
            }
            return {
                ...state,
                vehicle: null,
                loading: false,
                error: null,
            }

        case UPDATE_INDEX:
            CustomAlert({
                title: 'Vehículos Reordenados',
                icon: 'success',
            })
            return {
                ...state,
                vehicles: action.payload,
                loading: false,
                error: null,
                success: true,
            }
        case UPDATE_VEHICLE:
            return {
                ...state,
                vehicle: action.payload,
                loading: false,
                error: null,
                success: true,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
                loading: false,
                success: false,
            }
        case CLEAR_STATE:
            return {
                vehicle: {},
                vehicles: [],
                loading: false,
                error: null,
                success: false,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
