import React, { useReducer } from 'react'
import MakeContext from './makeContext'
import MakeReducer from './makeReducer'
import api from '../../api/api'
import {
    GET_MAKES,
    GET_MAKE,
    CREATE_MAKE,
    DELETE_MAKE,
    UPDATE_MAKE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    CHANGE_INDEX,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'
import { useHistory } from 'react-router-dom'

const MakeState = props => {
    const history = useHistory()
    const initialState = {
        makes: [],
        make: {},
        loading: false,
        error: null,
        count: 0,
    }

    const [state, dispatch] = useReducer(MakeReducer, initialState)

    //Change index
    const setIndex = async (newIndex, oldIndex, start, end) => {
        setLoading()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        try {
            const res = await api.post(
                `makes/setIndex`,
                { newIndex, oldIndex },
                config
            )
            dispatch({
                type: CHANGE_INDEX,
                payload: { ...res.data.data, start, end },
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Makes
    const getMakes = async () => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/makes?sort=name`)
            dispatch({ type: GET_MAKES, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Make
    const getMake = async makeId => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/makes/${makeId}`)
            dispatch({ type: GET_MAKE, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const getMakesV3 = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`makes/getMakesV3`, params)
            dispatch({
                type: GET_MAKES,
                payload: res.data.results.data,
                count: res.data.results.pagination.total,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create Make
    const createMake = async make => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }

        clearState()

        const { meta } = make
        //Load Meta Information
        if (meta && meta.fileImage) {
            let metaImage = await api.post(
                '/utils/uploads/image',
                {
                    type: meta.fileImage.type,
                    fileName: meta.fileImage.name,
                    folder: 'make',
                },
                config
            )
            await api.put(metaImage.data.url, meta.fileImage, {
                headers: {
                    'Content-Type': meta.fileImage ? meta.fileImage.type : null,
                },
            })

            make.meta.image = metaImage.data.key
            delete make.meta.fileImage
        }

        setLoading()
        try {
            const res = await api.post(`/makes`, { ...make }, config)
            dispatch({ type: CREATE_MAKE, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Makes
    const getMakesPagination = async ({ limit, page, query }) => {
        setLoading()
        try {
            const res = await api.get(
                `/makes/getMakesDashboard?page=${page +
                    1}&limit=${limit}&searchIndex=name-description&searchText=${query}&sort=index`
            )
            dispatch({
                type: GET_MAKES,
                payload: res.data.data,
                count: res.data.pagination.total,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Make
    const deleteMake = async makeId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/makes/${makeId}`, config)
            dispatch({ type: DELETE_MAKE, payload: res.data.data })
            history.push('/app/management/makes')
            CustomAlert({
                title: 'Make deleted',
                icon: 'error',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Update Make
    const updateMake = async (make, makeId, type, field = 'image') => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()

        try {
            if (type === 'photo') {
                let image = await api.post(
                    '/utils/uploads/image',
                    { type: make.type, fileName: make.name, folder: makeId },
                    config
                )
                await api.put(image.data.url, make, {
                    headers: { 'Content-Type': make ? make.type : null },
                })
                delete make.name
                make[field] = image.data.key
            }
            const { meta } = make
            //Load Meta Information
            if (meta && meta.fileImage) {
                let metaImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: meta.fileImage.type,
                        fileName: meta.fileImage.name,
                        folder: 'make',
                    },
                    config
                )
                await api.put(metaImage.data.url, meta.fileImage, {
                    headers: {
                        'Content-Type': meta.fileImage
                            ? meta.fileImage.type
                            : null,
                    },
                })

                make.meta.image = metaImage.data.key
                delete make.meta.fileImage
            }
            const res = await api.put(`/makes/${makeId}`, { ...make }, config)
            dispatch({ type: UPDATE_MAKE, payload: res.data.data })
            CustomAlert({
                title: 'Make updated',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <MakeContext.Provider
            value={{
                loading: state.loading,
                makes: state.makes,
                make: state.make,
                error: state.error,
                count: state.count,
                getMakes,
                createMake,
                getMake,
                deleteMake,
                updateMake,
                setLoading,
                clearState,
                getMakesPagination,
                setIndex,
                getMakesV3,
            }}
        >
            {props.children}
        </MakeContext.Provider>
    )
}

export default MakeState
