import React, { useReducer } from 'react'
import CampaignContext from './campaignContext'
import CampaignReducer from './campaignReducer'
import api from '../../api/api'
import {
    GET_CAMPAIGNS,
    GET_CAMPAIGN,
    DELETE_CAMPAIGN,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    CREATE_CAMPAIGN,
    UPDATE_CAMPAIGN,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'
import { useHistory } from 'react-router-dom'

const CampaignState = props => {
    const history = useHistory()
    const initialState = {
        campaigns: [],
        campaign: {},
        count: 0,
        loading: false,
        error: null,
    }

    const [state, dispatch] = useReducer(CampaignReducer, initialState)

    const createCampaign = async campaign => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.post(`campaigns`, campaign, config)
            dispatch({ type: CREATE_CAMPAIGN, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const updateCampaign = async (campaign, ID) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.put(`/campaigns/${ID}`, campaign, config)
            dispatch({ type: UPDATE_CAMPAIGN, payload: res.data.data })
            history.push('/app/management/campaigns')
            CustomAlert({
                title: 'Campaign updated',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            if (err && err.response && err.response.data) {
                dispatch({ type: SET_ERROR, payload: err.response.data })
            } else {
                dispatch({ type: SET_ERROR, payload: err })
            }
        }
    }

    //Get Campaigns
    const getCampaigns = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`/campaigns/getCampaignsV3`, params)

            dispatch({ type: GET_CAMPAIGNS, payload: res.data.results })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Campaigns
    const getCampaign = async campaignId => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/campaigns/${campaignId}`)
            dispatch({ type: GET_CAMPAIGN, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Campaign
    const deleteCampaign = async campaignId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/campaigns/${campaignId}`, config)
            dispatch({ type: DELETE_CAMPAIGN, payload: res.data.deletedId })
            history.push('/app/management/campaigns')
            CustomAlert({
                title: 'Campaign deleted',
                icon: 'error',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <CampaignContext.Provider
            value={{
                loading: state.loading,
                campaigns: state.campaigns,
                campaign: state.campaign,
                count: state.count,
                error: state.error,
                createCampaign,
                updateCampaign,
                getCampaigns,
                getCampaign,
                deleteCampaign,
                clearState,
                setLoading,
            }}
        >
            {props.children}
        </CampaignContext.Provider>
    )
}

export default CampaignState
