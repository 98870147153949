import React, { useEffect, Fragment } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
// import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types'
import { colors } from '@material-ui/core'
import { CapitalizeNames } from 'src/utils/capitalize'
import { useTranslation } from 'react-i18next'
import i18next from 'src/utils/i18next'
import { makeStyles } from '@material-ui/core'
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    Link,
    List,
    ListSubheader,
    Typography,
} from '@mui/material'
import {
    Aperture as MakeIcon,
    Truck as VehicleIcon,
    Home as StoreIcon,
    Layers as CategoryIcon,
    User as UserIcon,
    Users as UsersIcon,
    Camera as MediaIcon,
    Tablet as CampaignIcon,
    Briefcase,
    Image,
    Coffee,
} from 'react-feather'
import Logo from 'src/components/Logo'
import useAuth from 'src/hooks/useAuth'
import NavItem from './NavItem'
import ObjExist from 'src/utils/ObjExist'
import Sections from 'src/utils/constans/Sections'

function renderNavItems({ items, pathname, depth = 0 }) {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) =>
                    reduceChildRoutes({ acc, item, pathname, depth }),
                []
            )}
        </List>
    )
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth
    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false,
        })

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                open={Boolean(open)}
                title={item.title}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items,
                })}
            </NavItem>
        )
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                info={item.info}
                key={key}
                title={item.title}
            />
        )
    }

    return acc
}

const useStyles = makeStyles(theme => ({
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        color: colors.grey[500],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    buttonLeaf: {
        color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    icon: {
        color: theme.palette.icon,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
    expandIcon: {
        marginLeft: 'auto',
        height: 16,
        width: 16,
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.primary.main,
        },
    },
    mobileDrawer: {
        width: 256,
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)',
        [theme.breakpoints.up('lg')]: {
            paddingTop: 25,
        },
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64,
    },
    primaryColor: {
        color: theme.palette.primary.main,
    },
}))

const NavBar = ({ onMobileClose, openMobile, ...rest }) => {
    const classes = useStyles()
    const location = useLocation()
    const { user } = useAuth()
    const { t } = useTranslation()

    function renderNavItemsPermissions({ items, pathname, depth = 0 }) {
        return (
            <List disablePadding>
                {items.reduce(
                    (acc, item) =>
                        reduceChildRoutesPermissions({
                            acc,
                            item,
                            pathname,
                            depth,
                        }),
                    []
                )}
            </List>
        )
    }

    function reduceChildRoutesPermissions({ acc, pathname, item, depth }) {
        const key = item.title + depth
        if (item.items) {
            const open = matchPath(pathname, {
                path: item.href,
                exact: false,
            })

            acc.push(
                <NavItem
                    depth={depth}
                    icon={item.icon}
                    info={item.info}
                    key={key}
                    open={Boolean(open)}
                    title={
                        item.title.includes('.') ? t(item.title) : item.title
                    }
                >
                    {renderNavItemsPermissions({
                        depth: depth + 1,
                        pathname,
                        items: item.items,
                    })}
                </NavItem>
            )
        } else {
            if (ObjExist(user, ['permissions', item.href], '').includes('r'))
                acc.push(
                    <NavItem
                        depth={depth}
                        href={item.href}
                        icon={item.icon}
                        info={item.info}
                        key={key}
                        title={
                            item.title.includes('.')
                                ? t(item.title)
                                : item.title
                        }
                    />
                )
        }

        return acc
    }

    useEffect(() => {
        let language
        if (localStorage.getItem('i18nextLng')) {
            language = localStorage.getItem('i18nextLng')
        } else {
            language = 'en'
        }
        i18next.changeLanguage(language)
    }, [])

    // old navbar
    const rockstarSection = [
        {
            subheader: t('Navbar.Control'),
            items: [
                {
                    title: 'SEO',
                    href: '/app/management/seo',
                    icon: Coffee,
                },
                {
                    title: t('Navbar.Makes'),
                    href: '/app/management/makes',
                    icon: MakeIcon,
                },
                {
                    title: t('Navbar.BannersC1'),
                    href: '/app/management/carone',
                    icon: Image,
                },
                {
                    title: t('Navbar.Media'),
                    href: '/app/management/medias',
                    icon: MediaIcon,
                },
                {
                    title: t('Navbar.Cars'),
                    href: '/app/management/cars',
                    icon: VehicleIcon,
                },
                {
                    title: t('Navbar.Vehicles'),
                    href: '/app/management/vehicles',
                    icon: VehicleIcon,
                },
                {
                    title: t('Navbar.Preowneds'),
                    href: '/app/management/preowneds',
                    icon: VehicleIcon,
                },
                {
                    title: t('Navbar.Stores'),
                    href: '/app/management/stores',
                    icon: StoreIcon,
                },
                {
                    title: t('Navbar.Categories'),
                    href: '/app/management/categories',
                    icon: CategoryIcon,
                },
                {
                    title: t('Navbar.Users'),
                    href: '/app/management/users',
                    icon: UserIcon,
                },
                {
                    title: t('Navbar.Jobs'),
                    icon: Briefcase,
                    href: '/app/management/jobs',
                },
                {
                    title: t('Navbar.Applicants'),
                    icon: UserIcon,
                    href: '/app/management/applicants',
                },
            ],
        },
        {
            subheader: 'Marketing',
            items: [
                {
                    title: t('Navbar.Designs'),
                    icon: Image,
                    href: '/app/management/designs',
                },
                {
                    title: t('Navbar.Segmentations'),
                    icon: UsersIcon,
                    href: '/app/management/segmentations',
                },
                {
                    title: t('Navbar.Campaigns'),
                    icon: CampaignIcon,
                    href: '/app/management/campaigns',
                },
            ],
        },
    ]

    const sections = [
        {
            subheader: t('Navbar.Main'),
            items: [
                {
                    title: t('Navbar.Vehicles'),
                    icon: VehicleIcon,
                    href: '/app/management/vehicles',
                },
            ],
        },
    ]

    const sectionsAdmin = [
        {
            subheader: t('Navbar.Main'),
            items: [
                {
                    title: t('Navbar.Vehicles'),
                    icon: VehicleIcon,
                    href: '/app/management/vehicles',
                },
                {
                    title: t('Navbar.BannersC1'),
                    href: '/app/management/carone',
                    icon: Image,
                },
            ],
        },
    ]

    const sectionsAmbos = [
        {
            subheader: t('Navbar.Main'),
            items: [
                {
                    title: t('Navbar.Vehicles'),
                    icon: VehicleIcon,
                    href: '/app/management/vehicles',
                },
                {
                    title: t('Navbar.Preowneds'),
                    icon: VehicleIcon,
                    href: '/app/management/preowneds',
                },
            ],
        },
    ]

    const sectionsAdminAmbos = [
        {
            subheader: t('Navbar.Main'),
            items: [
                {
                    title: t('Navbar.Vehicles'),
                    icon: VehicleIcon,
                    href: '/app/management/vehicles',
                },
                {
                    title: t('Navbar.Preowneds'),
                    icon: VehicleIcon,
                    href: '/app/management/preowneds',
                },
                {
                    title: t('Navbar.BannersC1'),
                    href: '/app/management/carone',
                    icon: Image,
                },
            ],
        },
    ]

    const sectionsSemiNuevo = [
        {
            subheader: t('Navbar.Main'),
            items: [
                {
                    title: t('Navbar.Preowneds'),
                    icon: VehicleIcon,
                    href: '/app/management/preowneds',
                },
            ],
        },
    ]

    const sectionsMk = [
        {
            items: [
                {
                    title: t('Navbar.BannersC1'),
                    href: '/app/management/carone',
                    icon: Image,
                },
            ],
        },
    ]

    const rhSections = [
        {
            subheader: t('Navbar.Main'),
            items: [
                {
                    title: t('Navbar.Jobs'),
                    icon: Briefcase,
                    href: '/app/management/jobs',
                },
                {
                    title: t('Navbar.Applicants'),
                    icon: UserIcon,
                    href: '/app/management/applicants',
                },
            ],
        },
    ]

    const adminMkDigitalSections = [
        {
            subheader: 'Marketing',
            items: [
                {
                    title: t('Navbar.Designs'),
                    icon: Image,
                    href: '/app/management/designs',
                },
                {
                    title: t('Navbar.Segmentations'),
                    icon: UsersIcon,
                    href: '/app/management/segmentations',
                },
                {
                    title: t('Navbar.Campaigns'),
                    icon: CampaignIcon,
                    href: '/app/management/campaigns',
                },
                {
                    title: t('Navbar.Promotions'),
                    icon: Image,
                    href: 'app/management/promotions',
                },
            ],
        },
    ]

    const mkDigitalSections = [
        {
            subheader: 'Marketing',
            items: [
                {
                    title: t('Navbar.Segmentations'),
                    icon: UsersIcon,
                    href: '/app/management/segmentations',
                },
                {
                    title: t('Navbar.Campaigns'),
                    icon: CampaignIcon,
                    href: '/app/management/campaigns',
                },
                {
                    title: t('Navbar.Promotions'),
                    icon: Image,
                    href: 'app/management/promotions',
                },
            ],
        },
    ]

    const designerSections = [
        {
            subheader: 'Marketing',
            items: [
                {
                    title: t('Navbar.Designs'),
                    icon: Image,
                    href: '/app/management/designs',
                },
            ],
        },
    ]

    const mkDigitalSectionsRoles = [
        { role: 'designer', sections: designerSections },
        { role: 'marketing digital', sections: mkDigitalSections },
        { role: 'admin marketing digital', sections: adminMkDigitalSections },
    ]

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    const handleListSubheaderDisplay = section => {
        let display = 'none'
        let sectionUrls = section?.items.map(item => item.href)
        sectionUrls.every(url => {
            if (!user.permissions[url] || user.permissions[url] === '')
                return true
            display = 'list'
            return false
        })
        return display
    }

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
            style={{
                marginTop: 64,
            }}
        >
            {/* <PerfectScrollbar options={{ suppressScrollX: true }}> */}
            <Hidden lgUp>
                <Box p={2} pb={0} display="flex" justifyContent="center">
                    <RouterLink to="/">
                        <Logo width="200" />
                    </RouterLink>
                </Box>
            </Hidden>
            <Box p={0}>
                <Box display="flex" justifyContent="center">
                    <RouterLink to="/app/account">
                        <Avatar
                            alt="User"
                            style={{ width: 75, height: 75 }}
                            src={
                                user && user.image
                                    ? `${user.image}`
                                    : '/app/account'
                            }
                        />
                    </RouterLink>
                </Box>
                <Box mt={2} textAlign="center">
                    <Link
                        component={RouterLink}
                        to="/app/account"
                        variant="h5"
                        color="textPrimary"
                        underline="none"
                    >
                        {user && user.name
                            ? CapitalizeNames(user.name)
                            : 'None'}
                    </Link>
                    {user?.department && user.department !== '' && (
                        <Typography variant="body2" color="textSecondary">
                            {/* {t('Account.Department')}: */}
                            {/* <br/> */}
                            <span
                                className={classes.primaryColor}
                                variant="body2"
                            >
                                {user && user.department
                                    ? ' ' + CapitalizeNames(user.department)
                                    : ''}
                            </span>
                        </Typography>
                    )}
                    <Typography variant="body2" color="textSecondary">
                        {t('Account.YourTier')}:
                        <span className={classes.primaryColor} variant="body2">
                            {user && user.role
                                ? ' ' + CapitalizeNames(user.role)
                                : ''}
                        </span>
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {t('Keys.carType')}:
                        <span className={classes.primaryColor} variant="body2">
                            {user && user.carType
                                ? ' ' + CapitalizeNames(user.carType)
                                : ''}
                        </span>
                    </Typography>
                    {user ? (
                        <Typography
                            pt={4}
                            variant="body2"
                            style={{ textTransform: 'capitalize' }}
                        >
                            {user.role ===
                            ('rockstar' || user.role === 'super admin')
                                ? 'Global'
                                : user && user.makes && user.makes.length > 1
                                ? 'Multimarca'
                                : ''}
                        </Typography>
                    ) : (
                        false
                    )}
                </Box>
            </Box>
            <Divider />

            {user &&
            user.permissions &&
            typeof user.permissions !== 'undefined' &&
            Object.keys(user.permissions).length >= 1 ? (
                <Fragment>
                    {Sections.map(section => (
                        <Box p={2} key={Math.random()}>
                            <List
                                key={section.subheader + Math.random()}
                                subheader={
                                    <ListSubheader
                                        sx={{
                                            display: handleListSubheaderDisplay(
                                                section
                                            ),
                                        }}
                                        disableGutters
                                        disableSticky
                                    >
                                        {section.subheader.includes('.')
                                            ? t(section.subheader)
                                            : section.subheader}
                                    </ListSubheader>
                                }
                            >
                                {renderNavItemsPermissions({
                                    items: section.items,
                                    pathname: location.pathname,
                                })}
                            </List>
                        </Box>
                    ))}
                </Fragment>
            ) : (
                <Fragment>
                    {user &&
                        user.role &&
                        (user.role === 'marketing' || user.role === 'user') &&
                        user.carType &&
                        user.carType === 'nuevo' &&
                        sections.map(section => (
                            <Box
                                p={2}
                                pb={0}
                                key={
                                    Math.random() +
                                    Math.random() +
                                    Math.random()
                                }
                            >
                                <List
                                    style={{ padding: 0 }}
                                    key={section.subheader + Math.random()}
                                    subheader={
                                        <ListSubheader
                                            disableGutters
                                            disableSticky
                                        >
                                            {section.subheader}
                                        </ListSubheader>
                                    }
                                >
                                    {renderNavItems({
                                        items: section.items,
                                        pathname: location.pathname,
                                    })}
                                </List>
                            </Box>
                        ))}
                    {user &&
                        user.role &&
                        user.role === 'admin' &&
                        user.carType &&
                        user.carType === 'nuevo' &&
                        sectionsAdmin.map(section => (
                            <Box
                                p={2}
                                pb={0}
                                key={
                                    Math.random() +
                                    Math.random() +
                                    Math.random()
                                }
                            >
                                <List
                                    key={section.subheader + Math.random()}
                                    subheader={
                                        <ListSubheader
                                            disableGutters
                                            disableSticky
                                        >
                                            {section.subheader}
                                        </ListSubheader>
                                    }
                                >
                                    {renderNavItems({
                                        items: section.items,
                                        pathname: location.pathname,
                                    })}
                                </List>
                            </Box>
                        ))}
                    {user &&
                        user.role &&
                        (user.role === 'marketing' ||
                            user.role === 'user' ||
                            user.role === 'admin') &&
                        user.carType &&
                        user.carType === 'seminuevo' &&
                        sectionsSemiNuevo.map(section => (
                            <Box
                                p={2}
                                pb={0}
                                key={
                                    Math.random() +
                                    Math.random() +
                                    Math.random()
                                }
                            >
                                <List
                                    style={{ padding: 0 }}
                                    key={section.subheader + Math.random()}
                                    subheader={
                                        <ListSubheader
                                            disableGutters
                                            disableSticky
                                        >
                                            {section.subheader}
                                        </ListSubheader>
                                    }
                                >
                                    {renderNavItems({
                                        items: section.items,
                                        pathname: location.pathname,
                                    })}
                                </List>
                            </Box>
                        ))}

                    {user &&
                        user.role &&
                        (user.role === 'marketing' || user.role === 'user') &&
                        user.carType &&
                        user.carType === 'ambos' &&
                        sectionsAmbos.map(section => (
                            <Box
                                p={2}
                                pb={0}
                                key={
                                    Math.random() +
                                    Math.random() +
                                    Math.random()
                                }
                            >
                                <List
                                    style={{ padding: 0 }}
                                    key={section.subheader + Math.random()}
                                    subheader={
                                        <ListSubheader
                                            disableGutters
                                            disableSticky
                                        >
                                            {section.subheader}
                                        </ListSubheader>
                                    }
                                >
                                    {renderNavItems({
                                        items: section.items,
                                        pathname: location.pathname,
                                    })}
                                </List>
                            </Box>
                        ))}
                    {user &&
                        user.role &&
                        user.role === 'marketing' &&
                        sectionsMk.map(section => (
                            <Box
                                pl={2}
                                pb={0}
                                key={
                                    Math.random() +
                                    Math.random() +
                                    Math.random()
                                }
                            >
                                {renderNavItems({
                                    items: section.items,
                                    pathname: location.pathname,
                                })}
                            </Box>
                        ))}

                    {user &&
                        user.role &&
                        user.role === 'admin' &&
                        user.carType &&
                        user.carType === 'ambos' &&
                        sectionsAdminAmbos.map(section => (
                            <Box
                                p={2}
                                pb={0}
                                key={
                                    Math.random() +
                                    Math.random() +
                                    Math.random()
                                }
                            >
                                <List
                                    key={section.subheader + Math.random()}
                                    subheader={
                                        <ListSubheader
                                            disableGutters
                                            disableSticky
                                        >
                                            {section.subheader}
                                        </ListSubheader>
                                    }
                                >
                                    {renderNavItems({
                                        items: section.items,
                                        pathname: location.pathname,
                                    })}
                                </List>
                            </Box>
                        ))}

                    {user &&
                        user.role &&
                        mkDigitalSectionsRoles.map(row => {
                            if (row.role !== user.role) return null
                            return row.sections.map(section => (
                                <Box
                                    p={2}
                                    pb={0}
                                    key={
                                        Math.random() +
                                        Math.random() +
                                        Math.random()
                                    }
                                >
                                    <List
                                        key={section.subheader + Math.random()}
                                        subheader={
                                            <ListSubheader
                                                disableGutters
                                                disableSticky
                                            >
                                                {section.subheader}
                                            </ListSubheader>
                                        }
                                    >
                                        {renderNavItems({
                                            items: section.items,
                                            pathname: location.pathname,
                                        })}
                                    </List>
                                </Box>
                            ))
                        })}

                    {user &&
                        (user.role === 'rockstar' ||
                            user.role === 'super admin') &&
                        rockstarSection.map(section => (
                            <Box p={2} pb={0} key={Math.random()}>
                                <List
                                    key={section.subheader + Math.random()}
                                    subheader={
                                        <ListSubheader
                                            disableGutters
                                            disableSticky
                                        >
                                            {section.subheader}
                                        </ListSubheader>
                                    }
                                >
                                    {renderNavItems({
                                        items: section.items,
                                        pathname: location.pathname,
                                    })}
                                </List>
                            </Box>
                        ))}
                    {user &&
                        user.role === 'rh' &&
                        rhSections.map(section => (
                            <Box p={2} pb={0} key={Math.random()}>
                                <List
                                    key={section.subheader + Math.random()}
                                    subheader={
                                        <ListSubheader
                                            disableGutters
                                            disableSticky
                                        >
                                            {section.subheader}
                                        </ListSubheader>
                                    }
                                >
                                    {renderNavItems({
                                        items: section.items,
                                        pathname: location.pathname,
                                    })}
                                </List>
                            </Box>
                        ))}
                </Fragment>
            )}
            {/* </PerfectScrollbar> */}
        </Box>
    )

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    )
}

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
}

export default NavBar
