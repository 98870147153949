import React, { useReducer } from 'react'
import PlanResultsContext from './planResultsContext'
import PlanResultsReducer from './planResultsReducer'
import api from '../../api/api'
import {
    GET_PLANRESULTS,
    GET_PLANRESULT,
    DELETE_PLANRESULT,
    UPDATE_PLANRESULT,
    CREATE_PLANRESULT,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'
import { useHistory } from 'react-router-dom'

const PlanResultsState = props => {
    const history = useHistory()
    const initialState = {
        planResults: [],
        planResult: {},
        loading: false,
        error: null,
    }

    const [state, dispatch] = useReducer(PlanResultsReducer, initialState)

    //Get PlanResults
    const getPlanResults = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`/planResults/planResultsV3`, params)
            dispatch({
                type: GET_PLANRESULTS,
                payload: res.data.results.data,
                count: res.data.results.pagination.total,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get PlanResult
    const getPlanResultBySlugVerify = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`/planResults/BySlugVerify`, params)
            if (res.data && res.data.success === false) return
            history.push(`/app/management/planResults/${res.data.data._id}`)
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get PlanResult
    const getPlanResult = async planResultId => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/planResults/${planResultId}`)
            dispatch({ type: GET_PLANRESULT, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete PlanResult
    const deletePlanResult = async planResultId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/planResults/${planResultId}`, config)
            dispatch({ type: DELETE_PLANRESULT, payload: res.data.deletedId })
            CustomAlert({
                title: 'deleted',
                icon: 'error',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create PlanResult
    const createPlanResult = async planResult => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': true,
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.post(`/planResults`, planResult, config)
            dispatch({ type: CREATE_PLANRESULT, payload: res.data.data })
            CustomAlert({
                title: 'uploaded',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Update PlanResult
    const updatePlanResult = async (planResult, planResultId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.put(
                `/planResults/${planResultId}`,
                planResult,
                config
            )
            dispatch({
                type: UPDATE_PLANRESULT,
                payload: res.data.data,
                updatedId: res.data.updatedId,
            })
            CustomAlert({
                title: 'updated',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <PlanResultsContext.Provider
            value={{
                loading: state.loading,
                planResults: state.planResults,
                planResult: state.planResult,
                error: state.error,
                getPlanResultBySlugVerify,
                getPlanResults,
                getPlanResult,
                createPlanResult,
                deletePlanResult,
                updatePlanResult,
                clearState,
                setLoading,
            }}
        >
            {props.children}
        </PlanResultsContext.Provider>
    )
}

export default PlanResultsState
