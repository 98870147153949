import {
    GET_VERSIONS,
    GET_VERSIONS_BY_VEHICLE,
    GET_VERSION,
    CREATE_VERSION,
    DELETE_VERSION,
    UPDATE_VERSION,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types'

export default (state, action) => {
    switch (action.type) {
        case GET_VERSIONS_BY_VEHICLE:
            return {
                ...state,
                versions: action.payload,
                loading: false,
                error: null,
            }
        case GET_VERSIONS:
            return {
                ...state,
                versions: action.payload,
                loading: false,
                error: null,
            }
        case GET_VERSION:
            return {
                ...state,
                version: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_VERSION:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case DELETE_VERSION:
            state.versions = state.versions.filter(
                item => item._id.toString() !== action.payload.toString()
            )
            return {
                ...state,
                version: null,
                loading: false,
                error: null,
            }
        case UPDATE_VERSION:
            return {
                ...state,
                version: action.payload,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                version: {},
                versions: [],
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
