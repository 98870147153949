import React, { useReducer } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import api from '../../api/api';
import {
    REGISTER_SUCCESS,
    USER_LOADED,
    LOGIN_SUCCESS,
    LOGOUT,
    LOGIN_FAIL,
    REGISTER_FAIL,
    FORGOT_PASSWORD_FAIL,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    RESET_PASSWORD_FAIL,
    SET_ERROR,
    UPDATE_PROFILE,
    UPDATE_PASSWORD,
    UPDATE_STORE_FROM_PROFILE,
    SET_LOADING,
    CLEAR_STATE,
} from '../types';
import { useSnackbar } from 'notistack';
import { APPLICANT } from 'src/constants';

const AuthState = props => {
    const initialState = {
        token: localStorage.getItem('token'),
        isAuthenticated: false,
        user: {},
        loading: false,
        error: null,
    };
    const { enqueueSnackbar } = useSnackbar();

    const [state, dispatch] = useReducer(AuthReducer, initialState);

    const clearState = () => dispatch({ type: CLEAR_STATE });

    const register = async values => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            const res = await api.post('/auth/register', values, config);
            dispatch({ type: REGISTER_SUCCESS, payload: res.data });

            loadUser();
        } catch (err) {
            dispatch({
                type: REGISTER_FAIL,
                payload: err.response.data,
            });
        }
    };

    //Update Store
    const updateStoreFromProfile = async (store, storeId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            if (store.policiesHtml) delete store.policiesHtml;

            const { promotions } = store;

            const filesPromotions = [];
            let newPromotions = [];
            const oldPromotions = [];

            if (promotions) {
                promotions.map(item => {
                    if (item.file) return filesPromotions.push(item);
                    return oldPromotions.push(item);
                });
            }

            for (let i = 0; i < filesPromotions.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesPromotions[i].file.type,
                        fileName: filesPromotions[i].file.name,
                        folder: store._id,
                    },
                    config
                );
                await api.put(resImage.data.url, filesPromotions[i].file, {
                    headers: {
                        'Content-Type': filesPromotions[i].file
                            ? filesPromotions[i].file.type
                            : null,
                    },
                });
                delete filesPromotions[i].file;
                newPromotions.push({
                    ...filesPromotions[i],
                    image: resImage.data.key,
                });
            }

            if (store.fileMainImage) {
                let image = await api.post(
                    '/utils/uploads/image',
                    {
                        type: store.fileMainImage.type,
                        fileName: store.fileMainImage.name,
                        folder: store._id,
                    },
                    config
                );
                await api.put(image.data.url, store.fileMainImage, {
                    headers: {
                        'Content-Type': store.fileMainImage
                            ? store.fileMainImage.type
                            : null,
                    },
                });
                store.image = image.data.key;
                delete store.fileMainImage;
            }

            if (store.fileContactPhoto) {
                let contact = await api.post(
                    '/utils/uploads/image',
                    {
                        type: store.fileContactPhoto.type,
                        fileName: store.fileContactPhoto.name,
                        folder: store._id,
                    },
                    config
                );

                await api.put(contact.data.url, store.fileContactPhoto, {
                    headers: {
                        'Content-Type': store.fileContactPhoto
                            ? store.fileContactPhoto.type
                            : null,
                    },
                });
                store.contactPhoto = contact.data.key;
                delete store.fileContactPhoto;
            }

            if (store.isMultipleMake) {
                let aux = [];
                store.multiplesMakes.map(item => aux.push(item._id));
                store.multiplesMakes = aux;
            } else {
                store.multiplesMakes = [];
            }

            const res = await api.put(
                `/stores/${storeId}`,
                { ...store, promotions: [...oldPromotions, ...newPromotions] },
                config
            );
            dispatch({
                type: UPDATE_STORE_FROM_PROFILE,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Set Current User
    const loadUser = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };

        try {
            const res = await api.get(`/auth/me`, config);
            dispatch({
                type: USER_LOADED,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: LOGIN_FAIL, payload: err });
        }
    };

    //Login User
    const login = async values => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        setLoading();

        try {
            const res = await api.post('/auth/login', values, config);
            const { role } = res.data;
            if (role === APPLICANT) {
                enqueueSnackbar(
                    'Tu cuenta no está permitida para iniciar sesión',
                    {
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        variant: 'error',
                    }
                );

                return;
            }
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });

            loadUser();
        } catch (err) {
            dispatch({
                type: LOGIN_FAIL,
                payload: err.response.data,
            });
        }
    };

    //Update profile
    const updateProfile = async (values, type, userId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();

        let res;
        try {
            if (type === 'photo') {
                const uploadConfig = await api.post(
                    '/utils/uploads/image',
                    {
                        type: values.type,
                        fileName: values.name,
                        folder: userId,
                    },
                    config
                );

                await api.put(uploadConfig.data.url, values, {
                    headers: {
                        headers: {
                            'Content-Type': values ? values.type : null,
                        },
                    },
                });

                const dataKey = uploadConfig.data.key;

                res = await api.put(
                    `/auth/updatedetails`,
                    { image: dataKey },
                    config
                );
            } else {
                res = await api.put('/auth/updatedetails', values, config);
            }

            dispatch({
                type: UPDATE_PROFILE,
                payload: res.data.data,
            });

            loadUser();
        } catch (err) {
            dispatch({
                type: SET_ERROR,
                payload: err.response.data,
            });
        }
    };

    // Logout
    const logout = () => dispatch({ type: LOGOUT });

    //Forgot Password
    const forgotPassword = async email => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            const res = await api.post(
                '/auth/forgotpassword',
                { ...email },
                config
            );
            dispatch({ type: FORGOT_PASSWORD, payload: res.data });
        } catch (err) {
            dispatch({
                type: FORGOT_PASSWORD_FAIL,
                payload: err.response.data,
            });
        }
    };

    //updatePassword
    const updatePassword = async values => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();

        try {
            const res = await api.put(
                '/auth/updatepassword',
                { ...values },
                config
            );
            dispatch({ type: UPDATE_PASSWORD, payload: res.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Reset Password
    const resetPassword = async values => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        try {
            const res = await api.put(
                `/auth/resetPassword/${values.token}/`,
                { password: values.password },
                config
            );
            dispatch({ type: RESET_PASSWORD, payload: res.data });
        } catch (err) {
            dispatch({ type: RESET_PASSWORD_FAIL, payload: err.response.data });
        }
    };

    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                loading: state.loading,
                isAuthenticated: state.isAuthenticated,
                user: state.user,
                error: state.error,
                register,
                login,
                loadUser,
                logout,
                forgotPassword,
                resetPassword,
                updateProfile,
                updatePassword,
                clearState,
                updateStoreFromProfile,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthState;
