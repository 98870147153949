import {
    GET_PROMOTIONS,
    GET_PROMOTIONS_BY_VEHICLE,
    GET_PROMOTIONS_BY_STORE,
    GET_PROMOTION,
    CREATE_PROMOTION,
    DELETE_PROMOTION,
    UPDATE_PROMOTION,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_PROMOTIONSV3,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

export default (state, action) => {
    switch (action.type) {
        case GET_PROMOTIONS_BY_VEHICLE:
        case GET_PROMOTIONS_BY_STORE:
            return {
                ...state,
                promotions: action.payload,
                loading: false,
                error: null,
            }
        case GET_PROMOTIONS:
            return {
                ...state,
                promotions: action.payload,

                loading: false,
                error: null,
            }
        case GET_PROMOTIONSV3:
            return {
                ...state,
                promotions: action.payload,
                count: action.count.total,
                loading: false,
                error: null,
            }
        case GET_PROMOTION:
            return {
                ...state,
                promocion: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_PROMOTION:
            return {
                ...state,
                promocion: action.payload,
                loading: false,
                error: null,
            }
        case DELETE_PROMOTION:
            state.promotions = state.promotions.filter(
                item => item._id.toString() !== action.payload.toString()
            )
            return {
                ...state,
                promocion: null,
                loading: false,
                error: null,
            }
        case UPDATE_PROMOTION:
            return {
                ...state,
                promocion: action.payload,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                promocion: {},
                promotions: [],
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
