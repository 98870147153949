/*eslint no-loop-func: "off"*/
/*eslint-env es6*/
import {
    GET_STORES,
    CREATE_STORE,
    GET_STORE,
    DELETE_STORE,
    UPDATE_STORE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_STORES_BY_MAKE,
    CHANGE_INDEX,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

export default (state, action) => {
    switch (action.type) {
        case CHANGE_INDEX:
            const { oldIndex, newIndex, start, end } = action.payload
            const old = state.stores.findIndex(item => item.index === oldIndex)
            const id = state.stores[old]._id
            let suma = 1
            let currentIndex
            if (end < start) {
                for (let i = end; i < start; i++) {
                    //eslint-disable-next-line
                    currentIndex = state.stores.findIndex(
                        item => item.index === oldIndex + suma
                    )
                    state.stores[currentIndex].index =
                        state.stores[currentIndex].index - 1
                    suma++
                }

                currentIndex = state.stores.findIndex(item => item._id === id)
                state.stores[currentIndex].index = newIndex
            } else {
                for (let i = start; i < end; i++) {
                    //eslint-disable-next-line
                    currentIndex = state.stores.findIndex(
                        item => item.index === oldIndex - suma
                    )
                    state.stores[currentIndex].index =
                        state.stores[currentIndex].index + 1

                    suma++
                }
                currentIndex = state.stores.findIndex(item => item._id === id)
                state.stores[currentIndex].index = newIndex
            }
            return {
                ...state,
                loading: false,
            }
        case GET_STORES:
            return {
                ...state,
                stores: action.payload,
                count: action.count,
                loading: false,
            }
        case GET_STORES_BY_MAKE:
            return {
                ...state,
                stores: action.payload,
                loading: false,
            }
        case GET_STORE:
            return {
                ...state,
                store: action.payload,
                loading: false,
            }
        case CREATE_STORE:
            return {
                ...state,
                loading: false,
            }
        case DELETE_STORE:
            if (state.stores && state.stores.length > 0) {
                const deletedIndex = state.stores.findIndex(
                    item => item.index === action.payload.deletedIndex
                )
                for (let i = deletedIndex; i < state.stores.length; i++) {
                    state.stores[i].index = state.stores[i].index - 1
                }
                state.stores = state.stores.filter(
                    store =>
                        store._id.toString() !==
                        action.payload.deletedId.toString()
                )
            }
            return {
                ...state,
                store: null,
                loading: false,
            }
        case UPDATE_STORE:
            return {
                ...state,
                store: action.payload,
                loading: false,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                store: {},
                stores: [],
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
