import {
    GET_CARDS,
    GET_CARD,
    UPDATE_CARD,
    DELETE_CARD,
    CREATE_CARD,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

export default (state, action) => {
    switch (action.type) {
        case GET_CARDS:
            return {
                ...state,
                cards: action.payload,
                count: action.count.total,
                loading: false,
                error: null,
            }

        case GET_CARD:
            return {
                ...state,
                card: action.payload,
                loading: false,
                error: null,
            }
        case UPDATE_CARD:
            return {
                ...state,
                card: action.payload,
                loading: false,
                error: null,
            }

        case DELETE_CARD:
            state.cards = state.cards.filter(
                item => item._id.toString() !== action.payload.toString()
            )
            return {
                ...state,
                card: null,
                loading: false,
                error: null,
            }
        case CREATE_CARD:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                card: {},
                cards: [],
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
