import React, { useReducer } from 'react'
import SegmentationContext from './segmentationContext'
import SegmentationReducer from './segmentationReducer'
import api from '../../api/api'
import {
    GET_SEGMENTATIONS,
    GET_SEGMENTATION,
    DELETE_SEGMENTATION,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    CREATE_SEGMENTATION,
    UPDATE_SEGMENTATION,
} from '../types'

const SegmentationState = props => {
    const initialState = {
        segmentations: [],
        segmentation: {},
        count: 0,
        loading: false,
        error: null,
    }

    const [state, dispatch] = useReducer(SegmentationReducer, initialState)

    const createSegmentation = async segmentation => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.post(`segmentations`, segmentation, config)
            dispatch({ type: CREATE_SEGMENTATION, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const updateSegmentation = async (segmentation, ID) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.put(
                `/segmentations/${ID}`,
                segmentation,
                config
            )
            dispatch({ type: UPDATE_SEGMENTATION, payload: res.data.data })
        } catch (err) {
            if (err && err.response && err.response.data) {
                dispatch({ type: SET_ERROR, payload: err.response.data })
            } else {
                dispatch({ type: SET_ERROR, payload: err })
            }
        }
    }

    //Get Segmentations
    const getSegmentations = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(
                `/segmentations/getSegmentationsV3`,
                params
            )

            dispatch({ type: GET_SEGMENTATIONS, payload: res.data.results })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Segmentations
    const getSegmentation = async segmentationId => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/segmentations/${segmentationId}`)
            dispatch({ type: GET_SEGMENTATION, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Segmentation
    const deleteSegmentation = async segmentationId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(
                `/segmentations/${segmentationId}`,
                config
            )
            dispatch({ type: DELETE_SEGMENTATION, payload: res.data.deletedId })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <SegmentationContext.Provider
            value={{
                loading: state.loading,
                segmentations: state.segmentations,
                segmentation: state.segmentation,
                count: state.count,
                error: state.error,
                createSegmentation,
                updateSegmentation,
                getSegmentations,
                getSegmentation,
                deleteSegmentation,
                clearState,
                setLoading,
            }}
        >
            {props.children}
        </SegmentationContext.Provider>
    )
}

export default SegmentationState
