import React, { useReducer } from 'react'
import shoppingReducer from './shoppingReducer'
import ShoppingContext from './shoppingContext'
import api from '../../api/api'
import {
    GET_SHOPPINGS,
    GET_SHOPPING,
    DELETE_SHOPPING,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    CREATE_SHOPPING,
    GET_SHOPPINGSV3,
    UPDATE_SHOPPING,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

const ShoppingState = props => {
    const initialState = {
        shoppings: [],
        shopping: {},
        loading: false,
        error: null,
        count: 0,
    }

    const [state, dispatch] = useReducer(shoppingReducer, initialState)

    //Get providers
    const getShoppings = async () => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/shoppings`)
            dispatch({ type: GET_SHOPPINGS, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Promotion
    const getShopping = async shoppingId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            const res = await api.get(`/shoppings/${shoppingId}`, config)
            dispatch({ type: GET_SHOPPING, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Promotion
    const deleteShopping = async shoppingId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/shoppings/${shoppingId}`, config)
            dispatch({ type: DELETE_SHOPPING, payload: res.data.deletedId })
            CustomAlert({
                title: 'Shopping deleted',
                icon: 'error',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }
    const getShoppingV3 = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`/shoppings/getShoppingv3`, params)
            dispatch({
                type: GET_SHOPPINGSV3,
                payload: res.data.results.data,
                count: res.data.results.pagination,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create Promotion
    const createShopping = async value => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const { gallery } = value

            const filesGallery = []
            let newGallery = []

            gallery.map(item => filesGallery.push(item.file))

            for (let i = 0; i < filesGallery.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesGallery[i].type,
                        fileName: filesGallery[i].name,
                        folder: 'shopping',
                    },
                    config
                )
                await api.put(resImage.data.url, filesGallery[i], {
                    headers: {
                        'Content-Type': filesGallery[i]
                            ? filesGallery[i].type
                            : null,
                    },
                })
                newGallery.push({
                    image: resImage.data.key,
                    type: filesGallery[i].type,
                })
            }

            value = {
                ...value,
                gallery: [...newGallery],
            }

            const res = await api.post(`/shoppings`, { ...value }, config)
            dispatch({ type: CREATE_SHOPPING, payload: res.data.data })
            CustomAlert({
                title: 'Shopping created',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //update provider
    const updateShopping = async (shopping, shoppingId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const { gallery } = shopping

            const filesGallery = []
            let newGallery = []
            const oldGallery = []

            gallery.map(item => {
                if (item.file) return filesGallery.push(item.file)
                return oldGallery.push(item)
            })

            for (let i = 0; i < filesGallery.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesGallery[i].type,
                        fileName: filesGallery[i].name,
                        folder: shopping._id,
                    },
                    config
                )
                await api.put(resImage.data.url, filesGallery[i], {
                    headers: {
                        'Content-Type': filesGallery[i]
                            ? filesGallery[i].type
                            : null,
                    },
                })
                newGallery.push({
                    image: resImage.data.key,
                    type: filesGallery[i].type,
                })
            }

            shopping = {
                ...shopping,
                gallery: [...oldGallery, ...newGallery],
            }

            const res = await api.put(
                `/shoppings/${shoppingId}`,
                shopping,
                config
            )
            dispatch({ type: UPDATE_SHOPPING, payload: res.data.data })
            CustomAlert({
                title: 'shopping updated',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <ShoppingContext.Provider
            value={{
                loading: state.loading,
                shoppings: state.shoppings,
                shopping: state.shopping,
                error: state.error,
                count: state.count,
                createShopping,
                getShoppings,
                getShoppingV3,
                getShopping,
                deleteShopping,
                clearState,
                setLoading,
                updateShopping,
            }}
        >
            {props.children}
        </ShoppingContext.Provider>
    )
}

export default ShoppingState
