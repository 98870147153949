import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment'
import clsx from 'clsx'
import useAuth from 'src/hooks/useAuth'
import useTicket from 'src/hooks/useTickets'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import {
    Avatar,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    SvgIcon,
    Typography,
    makeStyles,
    Tooltip,
} from '@material-ui/core'
import { Badge } from '@mui/material'
import {
    AlertCircle as UrgentIcon,
    Circle as NormalIcon,
    MinusCircle as LowIcon,
} from 'react-feather'
import ReceiptIcon from '@mui/icons-material/Receipt'

const useStyles = makeStyles(theme => ({
    popover: {
        width: 320,
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    urgent: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.secondary.contrastText,
    },
    normal: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.secondary.contrastText,
    },
    low: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.secondary.contrastText,
    },
}))

const Tickets = ({ props, ...rest }) => {
    const classes = useStyles()
    const ref = useRef(null)
    const [isOpen, setOpen] = useState(false)
    const { t } = useTranslation()
    const history = useHistory()
    const { user } = useAuth()
    const {
        notiTickets,
        getTicketRockstar,
        getTicketAssigned,
        getTicketUser,
    } = useTicket()

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (user && user._id) {
            if (user.role === 'rockstar') {
                getTicketRockstar()
            } else if (user.ticketRole) {
                getTicketAssigned(user.ticketRole)
            } else {
                getTicketUser(user._id)
            }
        }
        // eslint-disable-next-line
    }, [user])

    return (
        <div {...rest}>
            <Tooltip title="Tickets">
                <IconButton color="inherit" ref={ref} onClick={handleOpen}>
                    {notiTickets && notiTickets.length > 0 ? (
                        <Badge badgeContent={notiTickets.length} color="error">
                            <SvgIcon fontSize="small">
                                <ReceiptIcon style={{ color: '#f44336' }} />
                            </SvgIcon>
                        </Badge>
                    ) : (
                        <SvgIcon fontSize="small">
                            <ReceiptIcon style={{ color: '#f44336' }} />
                        </SvgIcon>
                    )}
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.popover }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Box p={2}>
                    <Typography variant="h5" color="textPrimary">
                        {t('Tickets.Tickets')}
                    </Typography>
                </Box>

                {notiTickets && notiTickets.length === 0 ? (
                    <>
                        <Box p={2}>
                            <Typography variant="h6" color="textPrimary">
                                {t('Tickets.NoTickets')}
                            </Typography>
                        </Box>
                        <Box p={1} display="flex" justifyContent="center">
                            <Button
                                component={RouterLink}
                                size="small"
                                fullWidth
                                to="/app/management/tickets"
                            >
                                {t('Tickets.SeeMore')}
                            </Button>
                        </Box>
                    </>
                ) : (
                    <>
                        <List disablePadding>
                            {notiTickets &&
                                notiTickets.map(ticket => {
                                    return (
                                        <ListItem
                                            component={RouterLink}
                                            divider
                                            key={ticket?._id}
                                            to="#"
                                            onClick={e => {
                                                history.push(
                                                    `/app/management/tickets/${ticket?._id}`
                                                )
                                            }}
                                        >
                                            <ListItemAvatar>
                                                <Avatar
                                                    className={clsx({
                                                        [classes.icon]: true,
                                                        [classes.low]:
                                                            ticket &&
                                                            ticket?.priority ===
                                                                'low',
                                                        [classes.urget]:
                                                            ticket &&
                                                            ticket?.priority ===
                                                                'urgent',
                                                        [classes.normal]:
                                                            ticket &&
                                                            ticket?.priority ===
                                                                'normal',
                                                    })}
                                                >
                                                    <SvgIcon fontSize="small">
                                                        {ticket &&
                                                            ticket?.priority ===
                                                                'urgent' && (
                                                                <UrgentIcon />
                                                            )}
                                                        {ticket &&
                                                            ticket?.priority ===
                                                                'low' && (
                                                                <LowIcon />
                                                            )}
                                                        {ticket &&
                                                            ticket?.priority ===
                                                                'normal' && (
                                                                <NormalIcon />
                                                            )}
                                                    </SvgIcon>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                                primary={
                                                    user?._id ===
                                                    ticket?.user?._id
                                                        ? t(
                                                              'Tickets.SendYouTicket'
                                                          )
                                                        : ticket?.user?.name +
                                                          ' ' +
                                                          t(
                                                              'Tickets.SendTicket'
                                                          )
                                                }
                                                primaryTypographyProps={{
                                                    variant: 'subtitle2',
                                                    color: 'textPrimary',
                                                }}
                                                secondary={moment(
                                                    ticket?.createdAt
                                                ).fromNow()}
                                            />
                                        </ListItem>
                                    )
                                })}
                        </List>
                        <Box p={1} display="flex" justifyContent="center">
                            <Button
                                component={RouterLink}
                                size="small"
                                to="/app/management/tickets"
                            >
                                {t('Tickets.SeeMore')}
                            </Button>
                        </Box>
                    </>
                )}
            </Popover>
        </div>
    )
}

export default Tickets
