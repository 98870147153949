import React, { useReducer } from 'react'
import StoreContext from './storeContext'
import StoreReducer from './storeReducer'
import api from '../../api/api'
import {
    GET_STORES,
    CREATE_STORE,
    GET_STORE,
    DELETE_STORE,
    UPDATE_STORE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_STORES_BY_MAKE,
    CHANGE_INDEX,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'
import { useHistory } from 'react-router-dom'

const StoreState = props => {
    const history = useHistory()
    const initialState = {
        stores: [],
        store: {},
        loading: false,
        error: null,
        count: 0,
    }

    const [state, dispatch] = useReducer(StoreReducer, initialState)

    //Change index
    const setIndex = async (newIndex, oldIndex, start, end) => {
        setLoading()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        try {
            const res = await api.post(
                `stores/setIndex`,
                { newIndex, oldIndex },
                config
            )
            dispatch({
                type: CHANGE_INDEX,
                payload: { ...res.data.data, start, end },
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Stores
    const getStores = async (sortBy = 'index', filter = '') => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/stores?sort=${sortBy}${filter}`)
            dispatch({ type: GET_STORES, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Stores
    const getStoresPagination = async ({ limit, page, query }) => {
        setLoading()
        try {
            const res = await api.get(
                `/stores/getStoresDashboard?page=${page +
                    1}&limit=${limit}&searchIndex=name-address-description-phone&searchText=${query}&sort=index`
            )
            dispatch({
                type: GET_STORES,
                payload: res.data.data,
                count: res.data.pagination.total,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Stores By Make
    const getStoresByMake = async makeId => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/makes/${makeId}/stores`)
            dispatch({ type: GET_STORES_BY_MAKE, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Store
    const getStore = async storeId => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/stores/${storeId}`)
            dispatch({ type: GET_STORE, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const getStoreV3 = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`stores/getStoreV3`, params)
            dispatch({
                type: GET_STORES,
                payload: res.data.results.data,
                count: res.data.results.pagination.total,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create Store
    const createStore = async store => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()

        try {
            if (store.fileMainImage) {
                let image = await api.post(
                    '/utils/uploads/image',
                    {
                        type: store.fileMainImage.type,
                        fileName: store.fileMainImage.name,
                        folder: store._id,
                    },
                    config
                )

                await api.put(image.data.url, store.fileMainImage, {
                    headers: {
                        'Content-Type': store.fileMainImage
                            ? store.fileMainImage.type
                            : null,
                    },
                })
                store.image = image.data.key
                delete store.fileMainImage
            }

            if (store.fileContactPhoto) {
                let image = await api.post(
                    '/utils/uploads/image',
                    {
                        type: store.fileContactPhoto.type,
                        fileName: store.fileContactPhoto.name,
                        folder: store._id,
                    },
                    config
                )

                await api.put(image.data.url, store.fileContactPhoto, {
                    headers: {
                        'Content-Type': store.fileContactPhoto
                            ? store.fileContactPhoto.type
                            : null,
                    },
                })
                store.contactPhoto = image.data.key
                delete store.fileContactPhoto
            }

            store.make = store.make._id
            const res = await api.post(
                `makes/${store.make}/stores`,
                { ...store },
                config
            )
            dispatch({ type: CREATE_STORE, payload: res.data.data })
            history.push('/app/management/stores')
            CustomAlert({
                title: 'Store created',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Store
    const deleteStore = async storeId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/stores/${storeId}`, config)
            dispatch({ type: DELETE_STORE, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Update Store
    const updateStore = async (store, storeId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            if (store.descriptionHtml) delete store.descriptionHtml

            const { promotions } = store

            const filesPromotions = []
            let newPromotions = []
            const oldPromotions = []

            if (promotions) {
                promotions.map(item => {
                    if (item.file) return filesPromotions.push(item)
                    return oldPromotions.push(item)
                })
            }

            for (let i = 0; i < filesPromotions.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesPromotions[i].file.type,
                        fileName: filesPromotions[i].file.name,
                        folder: store._id,
                    },
                    config
                )
                await api.put(resImage.data.url, filesPromotions[i].file, {
                    headers: {
                        'Content-Type': filesPromotions[i].file
                            ? filesPromotions[i].file.type
                            : null,
                    },
                })
                delete filesPromotions[i].file
                newPromotions.push({
                    ...filesPromotions[i],
                    image: resImage.data.key,
                })
            }

            if (store.fileMainImage) {
                let image = await api.post(
                    '/utils/uploads/image',
                    {
                        type: store.fileMainImage.type,
                        fileName: store.fileMainImage.name,
                        folder: store._id,
                    },
                    config
                )
                await api.put(image.data.url, store.fileMainImage, {
                    headers: {
                        'Content-Type': store.fileMainImage
                            ? store.fileMainImage.type
                            : null,
                    },
                })
                store.image = image.data.key
                delete store.fileMainImage
            }

            if (store.fileContactPhoto) {
                let contact = await api.post(
                    '/utils/uploads/image',
                    {
                        type: store.fileContactPhoto.type,
                        fileName: store.fileContactPhoto.name,
                        folder: store._id,
                    },
                    config
                )

                await api.put(contact.data.url, store.fileContactPhoto, {
                    headers: {
                        'Content-Type': store.fileContactPhoto
                            ? store.fileContactPhoto.type
                            : null,
                    },
                })
                store.contactPhoto = contact.data.key
                delete store.fileContactPhoto
            }

            if (store.isMultipleMake) {
                let aux = []
                store.multiplesMakes.map(item => aux.push(item._id))
                store.multiplesMakes = aux
            } else {
                store.multiplesMakes = []
            }

            const res = await api.put(
                `/stores/${storeId}`,
                { ...store, promotions: [...oldPromotions, ...newPromotions] },
                config
            )
            dispatch({ type: UPDATE_STORE, payload: res.data.data })
            history.push('/app/management/stores')
            CustomAlert({
                title: 'Store updated',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <StoreContext.Provider
            value={{
                loading: state.loading,
                stores: state.stores,
                store: state.store,
                error: state.error,
                count: state.count,
                getStores,
                getStore,
                createStore,
                deleteStore,
                updateStore,
                clearState,
                setLoading,
                setIndex,
                getStoresByMake,
                getStoresPagination,
                getStoreV3,
            }}
        >
            {props.children}
        </StoreContext.Provider>
    )
}

export default StoreState
