import React, { useReducer } from 'react'
import JobCategoryContext from './jobCategoryContext'
import JobCategoryReducer from './jobCategoryReducer'
import api from '../../api/api'
import {
    GET_CATEGORIES,
    GET_CATEGORY,
    DELETE_CATEGORY,
    UPDATE_CATEGORY,
    CREATE_CATEGORY,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    CHANGE_INDEX,
} from '../types'

const JobCategoryState = props => {
    const initialState = {
        jobCategories: [],
        jobCategory: {},
        loading: false,
        error: null,
        count: 0,
    }

    const [state, dispatch] = useReducer(JobCategoryReducer, initialState)

    //Change index
    const setIndex = async (newIndex, oldIndex, start, end) => {
        setLoading()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        try {
            const res = await api.post(
                `jobCategories/setIndex`,
                { newIndex, oldIndex },
                config
            )
            dispatch({
                type: CHANGE_INDEX,
                payload: { ...res.data.data, start, end },
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get JobCategoryCategories
    const getJobCategories = async ({ limit, page, query }) => {
        setLoading()
        try {
            let res
            if (limit) {
                res = await api.get(
                    `jobCategories/getJobCategoryCategoriesDashboard?page=${page +
                        1}&limit=${limit}&searchIndex=title-category-address&searchText=${query}&sort=index`
                )
            } else {
                res = await api.get(`jobCategories`)
            }
            dispatch({
                type: GET_CATEGORIES,
                payload: res.data.data,
                count: res.data.pagination,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get JobCategoryCategories
    const getJobCategory = async jobCategoryId => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/jobCategories/${jobCategoryId}`)
            dispatch({ type: GET_CATEGORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete JobCategory
    const deleteJobCategory = async jobCategoryId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(
                `/jobCategories/${jobCategoryId}`,
                config
            )
            dispatch({ type: DELETE_CATEGORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create JobCategory
    const createJobCategory = async jobCategory => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const { colors, gallery, features, versions } = jobCategory

            const filesColors = []
            let newColors = []

            const filesGallery = []
            let newGallery = []

            const filesFeatures = []
            let newFeatures = []

            const filesVersions = []
            let newVersions = []

            gallery.map(item => filesGallery.push(item.file))

            colors.map(item => filesColors.push(item))

            features.map(item => filesFeatures.push(item))

            versions.map(item => filesVersions.push(item))

            if (jobCategory.fileMainImage) {
                let mainImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: jobCategory.fileMainImage.type,
                        fileName: jobCategory.fileMainImage.name,
                        folder: 'news',
                    },
                    config
                )
                await api.put(mainImage.data.url, jobCategory.fileMainImage, {
                    headers: {
                        'Content-Type': jobCategory.fileMainImage
                            ? jobCategory.fileMainImage.type
                            : null,
                    },
                })
                jobCategory.mainImage = mainImage.data.key
                delete jobCategory.fileMainImage
            }

            if (jobCategory.technicalFile) {
                let technicalImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: jobCategory.technicalFile.type,
                        fileName: jobCategory.technicalFile.name,
                        folder: jobCategory._id,
                    },
                    config
                )
                await api.put(
                    technicalImage.data.url,
                    jobCategory.technicalFile,
                    {
                        headers: {
                            'Content-Type': jobCategory.technicalFile
                                ? jobCategory.technicalFile.type
                                : null,
                        },
                    }
                )
                jobCategory.technicalSheet = technicalImage.data.key
                delete jobCategory.technicalFile
            }

            if (jobCategory.fileBannerImage) {
                let banner = await api.post(
                    '/utils/uploads/image',
                    {
                        type: jobCategory.fileBannerImage.type,
                        fileName: jobCategory.fileBannerImage.name,
                        folder: jobCategory._id,
                    },
                    config
                )
                await api.put(banner.data.url, jobCategory.fileBannerImage, {
                    headers: {
                        'Content-Type': jobCategory.fileBannerImage
                            ? jobCategory.fileBannerImage.type
                            : null,
                    },
                })
                jobCategory.banner = banner.data.key
                delete jobCategory.fileBannerImage
            }

            for (let i = 0; i < filesVersions.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesVersions[i].file.type,
                        fileName: filesVersions[i].file.name,
                        folder: 'news',
                    },
                    config
                )
                await api.put(resImage.data.url, filesVersions[i].file, {
                    headers: {
                        'Content-Type': filesVersions[i].file
                            ? filesVersions[i].file.type
                            : null,
                    },
                })
                delete filesVersions[i].file
                newVersions.push({
                    ...filesVersions[i],
                    image: resImage.data.key,
                })
            }

            for (let i = 0; i < filesFeatures.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesFeatures[i].file.type,
                        fileName: filesFeatures[i].file.name,
                        folder: 'news',
                    },
                    config
                )
                await api.put(resImage.data.url, filesFeatures[i].file, {
                    headers: {
                        'Content-Type': filesFeatures[i].file
                            ? filesFeatures[i].file.type
                            : null,
                    },
                })
                delete filesFeatures[i].file
                newFeatures.push({
                    ...filesFeatures[i],
                    image: resImage.data.key,
                })
            }

            for (let i = 0; i < filesGallery.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesGallery[i].type,
                        fileName: filesGallery[i].name,
                        folder: 'news',
                    },
                    config
                )
                await api.put(resImage.data.url, filesGallery[i], {
                    headers: {
                        'Content-Type': filesGallery[i]
                            ? filesGallery[i].type
                            : null,
                    },
                })
                newGallery.push({ image: resImage.data.key })
            }

            for (let i = 0; i < filesColors.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesColors[i].file.type,
                        fileName: filesColors[i].file.name,
                        folder: 'news',
                    },
                    config
                )
                await api.put(resImage.data.url, filesColors[i].file, {
                    headers: {
                        'Content-Type': filesColors[i].file
                            ? filesColors[i].file.type
                            : null,
                    },
                })
                delete filesColors[i].file
                newColors.push({
                    ...filesColors[i].color,
                    image: resImage.data.key,
                })
            }

            jobCategory = {
                ...jobCategory,
                gallery: [...newGallery],
                colors: [...newColors],
                features: [...newFeatures],
                versions: [...newVersions],
            }

            const res = await api.post(
                `makes/${jobCategory.make}/jobCategories`,
                jobCategory,
                config
            )
            dispatch({ type: CREATE_CATEGORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Update JobCategory
    const updateJobCategory = async (jobCategory, jobCategoryId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const { colors, gallery, features, versions } = jobCategory

            const filesColors = []
            let newColors = []
            const oldColors = []

            const filesGallery = []
            let newGallery = []
            const oldGallery = []

            const filesFeatures = []
            let newFeatures = []
            const oldFeatures = []

            const filesVersions = []
            let newVersions = []
            const oldVersions = []

            gallery.map(item => {
                if (item.file) return filesGallery.push(item.file)
                return oldGallery.push(item)
            })

            colors.map(item => {
                if (item.file) return filesColors.push(item)
                return oldColors.push(item)
            })

            features.map(item => {
                if (item.file) return filesFeatures.push(item)
                return oldFeatures.push(item)
            })

            versions.map(item => {
                if (item.file) return filesVersions.push(item)
                return oldVersions.push(item)
            })

            if (jobCategory.fileMainImage) {
                let mainImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: jobCategory.fileMainImage.type,
                        fileName: jobCategory.fileMainImage.name,
                        folder: jobCategory._id,
                    },
                    config
                )
                await api.put(mainImage.data.url, jobCategory.fileMainImage, {
                    headers: {
                        'Content-Type': jobCategory.fileMainImage
                            ? jobCategory.fileMainImage.type
                            : null,
                    },
                })

                jobCategory.mainImage = mainImage.data.key
                delete jobCategory.fileMainImage
            }

            if (jobCategory.technicalFile) {
                let technicalImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: jobCategory.technicalFile.type,
                        fileName: jobCategory.technicalFile.name,
                        folder: jobCategory._id,
                    },
                    config
                )
                await api.put(
                    technicalImage.data.url,
                    jobCategory.technicalFile,
                    {
                        headers: {
                            'Content-Type': jobCategory.technicalFile
                                ? jobCategory.technicalFile.type
                                : null,
                        },
                    }
                )
                jobCategory.technicalSheet = technicalImage.data.key
                delete jobCategory.technicalFile
            }

            if (jobCategory.fileBannerImage) {
                let banner = await api.post(
                    '/utils/uploads/image',
                    {
                        type: jobCategory.fileBannerImage.type,
                        fileName: jobCategory.fileBannerImage.name,
                        folder: jobCategory._id,
                    },
                    config
                )
                await api.put(banner.data.url, jobCategory.fileBannerImage, {
                    headers: {
                        'Content-Type': jobCategory.fileBannerImage
                            ? jobCategory.fileBannerImage.type
                            : null,
                    },
                })
                jobCategory.banner = banner.data.key
                delete jobCategory.fileBannerImage
            }

            for (let i = 0; i < filesGallery.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesGallery[i].type,
                        fileName: filesGallery[i].name,
                        folder: jobCategory._id,
                    },
                    config
                )
                await api.put(resImage.data.url, filesGallery[i], {
                    headers: {
                        'Content-Type': filesGallery[i]
                            ? filesGallery[i].type
                            : null,
                    },
                })
                newGallery.push({ image: resImage.data.key })
            }

            for (let i = 0; i < filesVersions.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesVersions[i].file.type,
                        fileName: filesVersions[i].file.name,
                        folder: jobCategory._id,
                    },
                    config
                )
                await api.put(resImage.data.url, filesVersions[i].file, {
                    headers: {
                        'Content-Type': filesVersions[i].file
                            ? filesVersions[i].file.type
                            : null,
                    },
                })
                newVersions.push({
                    ...filesVersions[i],
                    image: resImage.data.key,
                })
            }

            for (let i = 0; i < filesColors.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesColors[i].file.type,
                        fileName: filesColors[i].file.name,
                        folder: jobCategory._id,
                    },
                    config
                )
                await api.put(resImage.data.url, filesColors[i].file, {
                    headers: {
                        'Content-Type': filesColors[i].file
                            ? filesColors[i].file.type
                            : null,
                    },
                })
                newColors.push({
                    ...filesColors[i],
                    image: resImage.data.key,
                })
            }

            for (let i = 0; i < filesFeatures.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesFeatures[i].file.type,
                        fileName: filesFeatures[i].file.name,
                        folder: jobCategory._id,
                    },
                    config
                )
                await api.put(resImage.data.url, filesFeatures[i].file, {
                    headers: {
                        'Content-Type': filesFeatures[i].file
                            ? filesFeatures[i].file.type
                            : null,
                    },
                })
                newFeatures.push({
                    ...filesFeatures[i],
                    image: resImage.data.key,
                })
            }

            let aux = []
            jobCategory.availableStore.map(item => aux.push(item._id))
            jobCategory.availableStore = aux

            jobCategory = {
                ...jobCategory,
                gallery: [...oldGallery, ...newGallery],
                colors: [...oldColors, ...newColors],
                features: [...oldFeatures, ...newFeatures],
                versions: [...oldVersions, ...newVersions],
            }

            const res = await api.put(
                `/jobCategories/${jobCategoryId}`,
                jobCategory,
                config
            )
            dispatch({ type: UPDATE_CATEGORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <JobCategoryContext.Provider
            value={{
                loading: state.loading,
                count: state.count,
                jobCategories: state.jobCategories,
                jobCategory: state.jobCategory,
                error: state.error,
                getJobCategories,
                getJobCategory,
                createJobCategory,
                deleteJobCategory,
                updateJobCategory,
                clearState,
                setLoading,
                setIndex,
            }}
        >
            {props.children}
        </JobCategoryContext.Provider>
    )
}

export default JobCategoryState
