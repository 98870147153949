import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoginLayout from 'src/layouts/LoginLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import GuestGuard from 'src/components/GuestGuard';
import HighGuard from 'src/components/HighGuard';
import MediumGuard from 'src/components/MediumGuard';
import RhGuard from 'src/components/RhGuard';
import { Box } from '@mui/material';

export const renderRoutes = (routes = []) => (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Box;
                const permissions = route.permissions || '';
                const Layout = route.layout || Fragment;
                const Component = route.component;
                let path = route.path || '';
                path = route.permissionPath || path;
                path = path.split(':');
                if (path.length >= 2) {
                    path = path[0].slice(0, -1);
                } else {
                    path = path[0];
                }
                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={props => (
                            <Guard {...{ permissions, path }}>
                                <Layout>
                                    {route.routes ? (
                                        renderRoutes(route.routes)
                                    ) : (
                                        <Component {...props} />
                                    )}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView')),
    },
    {
        exact: true,
        guard: GuestGuard,
        layout: LoginLayout,
        path: '/login',
        component: lazy(() => import('src/views/auth/LoginView')),
    },
    {
        exact: true,
        path: '/login-unprotected',
        component: lazy(() => import('src/views/auth/LoginView')),
    },
    {
        exact: true,
        path: '/management/tickets',
        component: lazy(() => import('src/views/tickets/TicketListView')),
        layout: DashboardLayout,
    },
    {
        exact: true,
        path: '/app/create/ticket',
        component: lazy(() => import('src/views/tickets/TicketCreateView')),
        layout: DashboardLayout,
    },
    {
        exact: true,
        path: '/app/management/tickets/:id',
        component: lazy(() => import('src/views/tickets/TicketDetailsForm')),
        layout: DashboardLayout,
    },
    // {
    //   exact: true,
    //   guard: GuestGuard,
    //   path: '/register',
    //   component: lazy(() => import('src/views/auth/RegisterView'))
    // },
    {
        exact: true,
        guard: GuestGuard,
        path: '/forgotPassword',
        component: lazy(() => import('src/views/auth/ForgotPasswordView')),
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/resetPassword/:token',
        component: lazy(() => import('src/views/auth/ResetPassword')),
    },
    // {
    //   exact: true,
    //   path: '/register-unprotected',
    //   component: lazy(() => import('src/views/auth/RegisterView'))
    // },
    {
        path: '/app',
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/app/account',
                component: lazy(() => import('src/views/account/AccountView')),
            },
            // {
            //   guard: AuthGuard,
            //   exact: true,
            //   path: '/app/test',
            //   component: lazy(() => import('src/views/test'))
            // },
            {
                exact: true,
                path: '/app/management/tickets',
                component: lazy(() =>
                    import('src/views/tickets/TicketListView')
                ),
            },
            {
                exact: true,
                path: '/app/store-account',
                component: lazy(() =>
                    import('src/views/storeAccount/AccountView')
                ),
            },
            {
                guard: HighGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/makes',
                component: lazy(() => import('src/views/make/MakeListView')),
            },
            {
                guard: MediumGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/medias',
                component: lazy(() => import('src/views/media')),
            },
            {
                guard: HighGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/seo',
                component: lazy(() => import('src/views/seo')),
            },
            {
                guard: MediumGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/carone',
                component: lazy(() => import('src/views/banners')),
            },
            {
                guard: HighGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/stores',
                component: lazy(() => import('src/views/store/StoreListView')),
            },
            {
                guard: HighGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/carone-contigo',
                component: lazy(() =>
                    import('src/views/complaint/ComplaintListView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/vehicles',
                component: lazy(() =>
                    import('src/views/vehicle/VehicleListView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/preowneds',
                component: lazy(() => import('src/views/preowned/ListView')),
            },

            {
                guard: RhGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/jobs',
                component: lazy(() => import('src/views/job/JobListView')),
            },
            {
                guard: RhGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/applicants',
                component: lazy(() =>
                    import('src/views/applicant/ApplicantListView')
                ),
            },
            {
                guard: HighGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/users',
                component: lazy(() => import('src/views/user/UserListView')),
            },
            {
                guard: HighGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/cars',
                component: lazy(() => import('src/views/car/CarListView')),
            },
            {
                guard: HighGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/categories',
                component: lazy(() =>
                    import('src/views/category/CategoryListView')
                ),
            },
            {
                guard: HighGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/promotions',
                component: lazy(() =>
                    import('src/views/promotion/PromotionListView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/providers',
                component: lazy(() =>
                    import('src/views/provider/ProviderListView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'c',
                permissionPath: '/app/management/providers',
                exact: true,
                path: '/app/create/providers',
                component: lazy(() =>
                    import('src/views/provider/ProviderCreateView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/providers/:id',
                component: lazy(() =>
                    import('src/views/provider/ProviderDeatilsView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/shoppings',
                component: lazy(() =>
                    import('src/views/shopping/ShoppingListView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'c',
                permissionPath: '/app/management/shoppings',
                exact: true,
                path: '/app/create/shoppings',
                component: lazy(() =>
                    import('src/views/shopping/ShoppingCreateView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/shoppings/:id',
                component: lazy(() =>
                    import('src/views/shopping/ShoppingDetailsView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/stores/:id',
                component: lazy(() =>
                    import('src/views/store/StoreDetailsView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/carone-contigo/:id',
                component: lazy(() =>
                    import('src/views/complaint/ComplaintDetailsView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/cars/:id',
                component: lazy(() => import('src/views/car/CarDetailsView')),
            },
            {
                guard: RhGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/jobs/:id',
                component: lazy(() => import('src/views/job/JobDetailsView')),
            },

            {
                guard: RhGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/applicants/:id',
                component: lazy(() =>
                    import('src/views/applicant/ApplicantDetailsView')
                ),
            },
            {
                guard: HighGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/makes/:id',
                component: lazy(() => import('src/views/make/MakeDetailsView')),
            },
            {
                guard: MediumGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/vehicles/:id',
                component: lazy(() =>
                    import('src/views/vehicle/VehicleDetailsView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/preowneds/:id',
                component: lazy(() =>
                    import('src/views/preowned/PreownedDetailsView')
                ),
            },
            {
                guard: HighGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/categories/:id',
                component: lazy(() =>
                    import('src/views/category/CategoryDetailsView')
                ),
            },
            {
                guard: HighGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/users/:id',
                component: lazy(() => import('src/views/user/UserDetailsView')),
            },
            {
                guard: HighGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/promotions/:id',
                component: lazy(() =>
                    import('src/views/promotion/PromotionDetailsView')
                ),
            },
            {
                guard: HighGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/makes/:id/edit',
                component: lazy(() => import('src/views/make/MakeEditView')),
            },
            {
                guard: HighGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/categories/:id/edit',
                component: lazy(() =>
                    import('src/views/category/CategoryEditView')
                ),
            },
            {
                guard: HighGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/cars/:id/edit',
                component: lazy(() => import('src/views/car/CarEditView')),
            },
            {
                guard: HighGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/users/:id/edit',
                component: lazy(() => import('src/views/user/UserEditView')),
            },
            {
                guard: HighGuard,
                permissions: 'c',
                permissionPath: '/app/management/makes',
                exact: true,
                path: '/app/create/make',
                component: lazy(() => import('src/views/make/MakeCreateView')),
            },
            {
                guard: HighGuard,
                permissions: 'c',
                permissionPath: '/app/management/cars',
                exact: true,
                path: '/app/create/car',
                component: lazy(() => import('src/views/car/CarCreateView')),
            },
            {
                guard: RhGuard,
                permissions: 'c',
                permissionPath: '/app/management/jobs',
                exact: true,
                path: '/app/create/job',
                component: lazy(() => import('src/views/job/JobCreateView')),
            },
            {
                guard: HighGuard,
                permissions: 'c',
                permissionPath: '/app/management/stores',
                exact: true,
                path: '/app/create/store',
                component: lazy(() =>
                    import('src/views/store/StoreCreateView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'c',
                permissionPath: '/app/management/vehicles',
                exact: true,
                path: '/app/create/vehicle',
                component: lazy(() =>
                    import('src/views/vehicle/VehicleCreateView')
                ),
            },
            {
                guard: MediumGuard,
                permissionPath: '/app/management/preowneds',
                permissions: 'c',
                exact: true,
                path: '/app/create/preowned',
                component: lazy(() =>
                    import('src/views/preowned/PreownedCreateView')
                ),
            },
            {
                guard: HighGuard,
                permissions: 'c',
                permissionPath: '/app/management/categories',
                exact: true,
                path: '/app/create/category',
                component: lazy(() =>
                    import('src/views/category/CategoryCreateView')
                ),
            },
            {
                guard: HighGuard,
                permissions: 'c',
                permissionPath: '/app/management/users',
                exact: true,
                path: '/app/create/user',
                component: lazy(() => import('src/views/user/UserCreateView')),
            },
            {
                guard: HighGuard,
                permissions: 'c',
                permissionPath: '/app/management/promotions',
                exact: true,
                path: '/app/create/promotions',
                component: lazy(() =>
                    import('src/views/promotion/PromotionCreateView')
                ),
            },
            {
                exact: true,
                path: '/app/management',
                component: () => <Redirect to="/app/management/vehicles" />,
            },
            //Plan results routes
            {
                guard: HighGuard,
                permissions: 'c',
                permissionPath: '/app/management/planResults',
                exact: true,
                path: '/app/create/planResults',
                component: lazy(() =>
                    import('src/views/planResults/CreateView')
                ),
            },
            {
                guard: HighGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/planResults',
                component: lazy(() => import('src/views/planResults/ListView')),
            },
            {
                guard: HighGuard,
                permissions: 'u',
                permissionPath: '/app/management/planResults',
                exact: true,
                path: '/app/management/planResults/:id',
                component: lazy(() =>
                    import('src/views/planResults/DetailsView')
                ),
            },
            //Plan routes
            {
                guard: HighGuard,
                permissions: 'c',
                permissionPath: '/app/management/plan',
                exact: true,
                path: '/app/create/plan',
                component: lazy(() => import('src/views/plan/CreateView')),
            },
            {
                guard: HighGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/plan',
                component: lazy(() => import('src/views/plan/ListView')),
            },
            {
                guard: HighGuard,
                permissions: 'u',
                permissionPath: '/app/management/plan',
                exact: true,
                path: '/app/management/plan/:id',
                component: lazy(() => import('src/views/plan/DetailsView')),
            },
            //? Campaigns Routes
            {
                guard: MediumGuard,
                permissions: 'c',
                permissionPath: '/app/management/campaigns',
                exact: true,
                path: '/app/create/campaign',
                component: lazy(() => import('src/views/campaign/CreateView')),
            },
            {
                guard: MediumGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/campaigns',
                component: lazy(() => import('src/views/campaign/ListView')),
            },
            {
                guard: MediumGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/presentation-cards',
                component: lazy(() => import('src/views/card/CardListView')),
            },
            {
                guard: MediumGuard,
                permissions: 'c',
                permissionPath: '/app/management/presentation-cards',
                exact: true,
                path: '/app/create/presentation-card',
                component: lazy(() => import('src/views/card/CardCreateView')),
            },
            {
                guard: MediumGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/presentation-cards/:id',
                component: lazy(() => import('src/views/card/CardDetailsView')),
            },
            {
                guard: MediumGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/campaigns/:id',
                component: lazy(() => import('src/views/campaign/DetailsView')),
            },
            //? Designs Routes
            {
                guard: MediumGuard,
                permissions: 'c',
                permissionPath: '/app/management/designs-paid',
                exact: true,
                path: '/app/create/design-paid',
                component: lazy(() => import('src/views/design/CreateView')),
            },
            {
                guard: MediumGuard,
                permissions: 'c',
                permissionPath: '/app/management/designs-organic',
                exact: true,
                path: '/app/create/design-organic',
                component: lazy(() => import('src/views/design/CreateView')),
            },
            {
                guard: MediumGuard,
                permissions: 'c',
                permissionPath: '/app/management/designs-video',
                exact: true,
                path: '/app/create/design-video',
                component: lazy(() => import('src/views/design/CreateView')),
            },

            /* {
          guard: MediumGuard,
          permissions:"r",
          exact: true,
          path: '/app/management/designs',
          component: lazy(() => import('src/views/design/ListView'))
        }, */
            {
                guard: MediumGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/designs-paid',
                component: lazy(() => import('src/views/design/ListView')),
            },

            {
                guard: MediumGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/designs-paid/:id',
                component: lazy(() => import('src/views/design/DetailsView')),
            },
            {
                guard: MediumGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/designs-organic/:id',
                component: lazy(() => import('src/views/design/DetailsView')),
            },
            {
                guard: MediumGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/designs-organic',
                component: lazy(() => import('src/views/design/ListView')),
            },

            {
                guard: MediumGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/designs-video/:id',
                component: lazy(() => import('src/views/design/DetailsView')),
            },
            {
                guard: MediumGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/designs-video',
                component: lazy(() => import('src/views/design/ListView')),
            },

            {
                guard: MediumGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/calendar-designs',
                component: lazy(() => import('src/views/calendarDesign/')),
            },

            //? segmentations Routes
            {
                guard: MediumGuard,
                permissions: 'c',
                permissionPath: '/app/management/segmentations',
                exact: true,
                path: '/app/create/segmentation',
                component: lazy(() =>
                    import('src/views/segmentation/CreateView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'r',
                exact: true,
                path: '/app/management/segmentations',
                component: lazy(() =>
                    import('src/views/segmentation/ListView')
                ),
            },
            {
                guard: MediumGuard,
                permissions: 'u',
                exact: true,
                path: '/app/management/segmentations/:id',
                component: lazy(() =>
                    import('src/views/segmentation/DetailsView')
                ),
            },
            {
                exact: true,
                path: '/app',
                component: () => <Redirect to="/app/management/vehicles" />,
            },
            {
                component: () => <Redirect to="/404" />,
            },
        ],
    },
    {
        path: '*',
        routes: [
            {
                exact: true,
                path: '/',
                component: () => <Redirect to="/app/management/vehicles" />,
            },
            {
                component: () => <Redirect to="/404" />,
            },
        ],
    },
];

export default routes;
