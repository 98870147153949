import React, { useReducer } from 'react'
import PlanContext from './planContext'
import planReducer from './planReducer'
import api from '../../api/api'
import {
    GET_PLANS,
    GET_PLAN,
    IS_FOUND,
    DELETE_PLAN,
    UPDATE_PLAN,
    CREATE_PLAN,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'
import { useHistory } from 'react-router-dom'

const PlanState = props => {
    const history = useHistory()
    const initialState = {
        plans: [],
        plan: {},
        count: 0,
        isFound: false,
        loading: false,
        error: null,
    }

    const [state, dispatch] = useReducer(planReducer, initialState)

    //Get Plans
    const getPlans = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`/plans/plansV3`, params)
            dispatch({
                type: GET_PLANS,
                payload: res.data.results.data,
                count: res.data.results.pagination.total,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get PlanResult
    const getPlanBySlugVerify = async params => {
        clearState()
        try {
            const res = await api.post(`/plans/BySlugVerify`, params)
            if (res.data && res.data.success === false) return
            dispatch({ type: IS_FOUND, payload: true })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Plan
    const getPlan = async planId => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/plans/${planId}`)
            dispatch({ type: GET_PLAN, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Plan
    const deletePlan = async planId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/plans/${planId}`, config)
            dispatch({ type: DELETE_PLAN, payload: res.data.deletedId })
            CustomAlert({
                title: 'deleted',
                icon: 'error',
                timer: ErrorShowTime,
            })
            history.push(`/app/management/plan`)
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create Plan
    const createPlan = async plan => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': true,
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const formatedTables = await Promise.all(
                plan.tables.map(async tabla => {
                    const formatedTable = await Promise.all(
                        tabla.table.map(async item => {
                            if (item.fileImage) {
                                try {
                                    const image = await api.post(
                                        '/utils/uploads/image',
                                        {
                                            type: item.fileImage.type,
                                            fileName: item.fileImage.name,
                                            folder: 'presentation_cards',
                                        },
                                        config
                                    )

                                    await api.put(
                                        image.data.url,
                                        item.fileImage,
                                        {
                                            headers: {
                                                'Content-Type': item.fileImage
                                                    ? item.fileImage.type
                                                    : null,
                                            },
                                        }
                                    )

                                    delete item.fileImage
                                    return { ...item, image: image.data.key }
                                } catch (error) {
                                    dispatch({
                                        type: SET_ERROR,
                                        payload: error.response.data,
                                    })
                                    return { ...item }
                                }
                            }
                            return { ...item }
                        })
                    )

                    return { ...tabla, table: formatedTable }
                })
            )

            const res = await api.post(
                `/plans`,
                { ...plan, tables: formatedTables },
                config
            )
            dispatch({ type: CREATE_PLAN, payload: res.data.data })
            CustomAlert({
                title: 'Plan Created',
                icon: 'success',
                timer: ErrorShowTime,
            })
            history.push(`/app/management/plan`)
        } catch (err) {
            console.log('ERROR:', err)
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Update Plan
    const updatePlan = async (plan, planId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const formatedTables = await Promise.all(
                plan.tables.map(async tabla => {
                    const formatedTable = await Promise.all(
                        tabla.table.map(async item => {
                            if (item.fileImage) {
                                try {
                                    const image = await api.post(
                                        '/utils/uploads/image',
                                        {
                                            type: item.fileImage.type,
                                            fileName: item.fileImage.name,
                                            folder: 'presentation_cards',
                                        },
                                        config
                                    )

                                    await api.put(
                                        image.data.url,
                                        item.fileImage,
                                        {
                                            headers: {
                                                'Content-Type': item.fileImage
                                                    ? item.fileImage.type
                                                    : null,
                                            },
                                        }
                                    )

                                    delete item.fileImage
                                    return { ...item, image: image.data.key }
                                } catch (error) {
                                    dispatch({
                                        type: SET_ERROR,
                                        payload: error.response.data,
                                    })
                                    return { ...item }
                                }
                            }
                            return { ...item }
                        })
                    )

                    return { ...tabla, table: formatedTable }
                })
            )

            const res = await api.put(
                `/plans/${planId}`,
                { ...plan, tables: formatedTables },
                config
            )
            dispatch({
                type: UPDATE_PLAN,
                payload: res.data.data,
                updatedId: res.data.updatedId,
            })
            CustomAlert({
                title: 'Updated Plan',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <PlanContext.Provider
            value={{
                loading: state.loading,
                plans: state.plans,
                plan: state.plan,
                isFound: state.isFound,
                error: state.error,
                count: state.count,
                getPlans,
                getPlan,
                createPlan,
                deletePlan,
                updatePlan,
                clearState,
                setLoading,
                getPlanBySlugVerify,
            }}
        >
            {props.children}
        </PlanContext.Provider>
    )
}

export default PlanState
