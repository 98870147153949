import React, { useReducer } from 'react'
import JobContext from './jobContext'
import JobReducer from './jobReducer'
import api from '../../api/api'
import {
    GET_JOBS,
    GET_JOB,
    DELETE_JOB,
    UPDATE_JOB,
    CREATE_JOB,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    CHANGE_INDEX,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'
import { useHistory } from 'react-router-dom'

const JobState = props => {
    const history = useHistory()
    const initialState = {
        jobs: [],
        job: {},
        loading: false,
        error: null,
        count: 0,
    }

    const [state, dispatch] = useReducer(JobReducer, initialState)

    //Change index
    const setIndex = async (newIndex, oldIndex, start, end) => {
        setLoading()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        try {
            const res = await api.post(
                `jobs/setIndex`,
                { newIndex, oldIndex },
                config
            )
            dispatch({
                type: CHANGE_INDEX,
                payload: { ...res.data.data, start, end },
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get all jobs old
    // const getJobs = async () => {
    //   clearState();
    //   setLoading();
    //   try {
    //     const res = await api.get(`/jobs?sort=name`);
    //     dispatch({ type: GET_JOBS, payload: res.data.data , count: res.data.pagination});
    //   } catch (err) {
    //     dispatch({ type: SET_ERROR, payload: err.response.data})
    //   }
    // };

    //Get jobs v3
    const getJobs = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`/jobs/getJobsV3`, params)
            dispatch({ type: GET_JOBS, payload: res.data.results })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Jobs
    const getJobsPagination = async ({ limit, page, query }) => {
        setLoading()
        try {
            const res = await api.get(
                `jobs/getJobsDashboard?page=${page +
                    1}&limit=${limit}&searchIndex=title-category-address&searchText=${query}`
            )
            dispatch({
                type: GET_JOBS,
                payload: res.data.data,
                count: res.data.pagination,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Jobs
    const getJob = async jobId => {
        setLoading()
        try {
            const res = await api.get(`/jobs/${jobId}`)
            dispatch({ type: GET_JOB, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Job
    const deleteJob = async jobId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': true,
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/jobs/${jobId}`, config)

            dispatch({ type: DELETE_JOB, payload: res.data.data })
            history.push('/app/management/jobs')
            CustomAlert({
                title: 'Job Deleted',
                icon: 'error',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create Job
    const createJob = async job => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': true,
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const { meta } = job

            //Load Meta Information
            if (meta && meta.fileImage) {
                let metaImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: meta.fileImage.type,
                        fileName: meta.fileImage.name,
                        folder: job._id,
                    },
                    config
                )
                await api.put(metaImage.data.url, meta.fileImage, {
                    headers: {
                        'Content-Type': meta.fileImage
                            ? meta.fileImage.type
                            : null,
                    },
                })

                job.meta.image = metaImage.data.key
                delete job.meta.fileImage
            }

            if (job.descriptionHtml) delete job.descriptionHtml
            const res = await api.post(`jobs`, job, config)
            dispatch({ type: CREATE_JOB, payload: res.data.data })
            history.push('/app/management/jobs')
            CustomAlert({
                title: 'Job created',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Update Job
    const updateJob = async (job, jobId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const { meta } = job

            //Load Meta Information
            if (meta && meta.fileImage) {
                let metaImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: meta.fileImage.type,
                        fileName: meta.fileImage.name,
                        folder: job._id,
                    },
                    config
                )
                await api.put(metaImage.data.url, meta.fileImage, {
                    headers: {
                        'Content-Type': meta.fileImage
                            ? meta.fileImage.type
                            : null,
                    },
                })

                job.meta.image = metaImage.data.key
                delete job.meta.fileImage
            }

            const res = await api.put(`/jobs/${jobId}`, job, config)
            dispatch({ type: UPDATE_JOB, payload: res.data.data })
            CustomAlert({
                title: 'Job updated',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <JobContext.Provider
            value={{
                loading: state.loading,
                count: state.count,
                jobs: state.jobs,
                job: state.job,
                error: state.error,
                getJobsPagination,
                getJobs,
                getJob,
                createJob,
                deleteJob,
                updateJob,
                clearState,
                setLoading,
                setIndex,
            }}
        >
            {props.children}
        </JobContext.Provider>
    )
}

export default JobState
