import {
    GET_MEDIAS,
    GET_MEDIAS_BY_VEHICLE,
    GET_MEDIA,
    CREATE_MEDIA,
    DELETE_MEDIA,
    UPDATE_MEDIA,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_MEDIAS_MAIN_BANNER,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

export default (state, action) => {
    switch (action.type) {
        case GET_MEDIAS_MAIN_BANNER:
            return {
                ...state,
                mediasMainBanner: action.payload,
                loading: false,
                error: null,
            }
        case GET_MEDIAS_BY_VEHICLE:
            return {
                ...state,
                medias: action.payload,
                loading: false,
                error: null,
            }
        case GET_MEDIAS:
            return {
                ...state,
                medias: action.payload,
                loading: false,
                error: null,
            }
        case GET_MEDIA:
            return {
                ...state,
                media: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_MEDIA:
            if (action.tipo) {
                state[action.tipo].push(action.payload)
            } else {
                state.medias.push(action.payload)
            }
            return {
                ...state,
                media: action.payload,
                loading: false,
                error: null,
            }
        case DELETE_MEDIA:
            if (action.tipo) {
                state[action.tipo] = state[action.tipo].filter(
                    item => item._id.toString() !== action.payload.toString()
                )
            } else {
                state.medias = state.medias.filter(
                    item => item._id.toString() !== action.payload.toString()
                )
            }
            return {
                ...state,
                media: null,
                loading: false,
                error: null,
            }
        case UPDATE_MEDIA:
            let id = state.medias.findIndex(
                item => item._id.toString() === action.updatedId.toString()
            )
            state.medias[id] = action.payload
            let idBanner = state.mediasMainBanner.findIndex(
                item => item._id.toString() === action.updatedId.toString()
            )
            state.mediasMainBanner[idBanner] = action.payload

            return {
                ...state,
                media: action.payload,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                media: {},
                medias: [],
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
