/*eslint no-loop-func: "off"*/
/*eslint-env es6*/
import {
    GET_JOBS,
    GET_JOB,
    CREATE_JOB,
    DELETE_JOB,
    UPDATE_JOB,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    CHANGE_INDEX,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

export default (state, action) => {
    switch (action.type) {
        case CHANGE_INDEX:
            const { oldIndex, newIndex, start, end } = action.payload
            const old = state.jobs.findIndex(item => item.index === oldIndex)
            const id = state.jobs[old]._id
            let suma = 1
            let currentIndex
            if (end < start) {
                for (let i = end; i < start; i++) {
                    //eslint-disable-next-line
                    currentIndex = state.jobs.findIndex(
                        item => item.index === oldIndex + suma
                    )
                    state.jobs[currentIndex].index =
                        state.jobs[currentIndex].index - 1
                    suma++
                }

                currentIndex = state.jobs.findIndex(item => item._id === id)
                state.jobs[currentIndex].index = newIndex
            } else {
                for (let i = start; i < end; i++) {
                    //eslint-disable-next-line
                    currentIndex = state.jobs.findIndex(
                        item => item.index === oldIndex - suma
                    )
                    state.jobs[currentIndex].index =
                        state.jobs[currentIndex].index + 1

                    suma++
                }
                currentIndex = state.jobs.findIndex(item => item._id === id)
                state.jobs[currentIndex].index = newIndex
            }
            return {
                ...state,
                loading: false,
            }
        case GET_JOBS:
            return {
                ...state,
                jobs: action.payload.data,
                count: action.payload.pagination.total,
                loading: false,
                error: null,
            }
        case GET_JOB:
            return {
                ...state,
                job: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_JOB:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case DELETE_JOB:
            if (state.jobs && state.jobs.length > 0) {
                state.jobs = state.jobs.filter(
                    job => job._id !== action.payload.deletedId
                )
            }
            return {
                ...state,
                job: null,
                loading: false,
                error: null,
            }
        case UPDATE_JOB:
            return {
                ...state,
                job: action.payload,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                job: {},
                jobs: [],
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
