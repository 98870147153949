import {
    SET_LOADING,
    GET_TICKETS,
    GET_TICKET,
    GET_TICKETS_NOTIFICATION,
    DELETE_TICKET,
    CREATE_TICKET,
    UPDATE_TICKET,
    CLEAR_STATE,
    SET_ERROR,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

export default (state, action) => {
    switch (action.type) {
        case GET_TICKETS:
            return {
                ...state,
                tickets: action.payload.data,
                count: action.payload.pagination.total,
                loading: false,
                error: null,
            }
        case GET_TICKET:
            return {
                ...state,
                ticket: action.payload,

                loading: false,
                error: null,
            }
        case GET_TICKETS_NOTIFICATION:
            return {
                ...state,
                notiTickets: action.payload,
                loading: false,
                error: null,
            }
        case DELETE_TICKET:
            state.ticket = state.ticket.filter(
                item => item._id.toString() !== action.payload.toString()
            )
            return {
                ...state,
                ticket: [],
                notiTickets: [],
                loading: false,
                error: null,
            }
        case CREATE_TICKET:
            return {
                ...state,
                ticket: action.payload,
                loading: false,
                error: null,
            }
        case UPDATE_TICKET:
            return {
                ...state,
                ticket: action.payload,
                loading: false,
                error: null,
            }
        case CLEAR_STATE:
            return {
                ticket: {},
                tickets: [],
                notiTickets: [],
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}
