export const ROCKSTAR = 'rockstar';
export const SUPER_ADMIN = 'super admin';
export const ADMIN = 'admin';
export const USER = 'user';
export const RH = 'rh';
export const APPLICANT = 'applicant';
export const MARKETING = 'marketing';
export const DESIGNER = 'designer';
export const MARKETING_DIGITAL = 'marketing digital';
export const ADMIN_MARKETING_DIGITAL = 'admin marketing digital';
export const SHOPPING = 'shopping';

export const ROLES = [
  //?  God level
  ROCKSTAR,
  //?  Group level
  SUPER_ADMIN,
  //?  Make level
  ADMIN,
  ADMIN_MARKETING_DIGITAL,
  MARKETING,
  RH,
  //?  Store level
  DESIGNER,
  MARKETING_DIGITAL,
  //?  User level
  USER,
  APPLICANT,
  SHOPPING
];

export type IRoles = typeof ROLES[number];
