import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    AppBar,
    Box,
    IconButton,
    Toolbar,
    makeStyles,
    Typography,
    Hidden,
    SvgIcon,
} from '@material-ui/core'
import { THEMES } from 'src/constants'
import Account from './Account'
import Settings from './Settings'
import { Menu as MenuIcon } from 'react-feather'
import Notifications from './Notification'
import Tickets from './Tickets'

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...(theme.name === THEMES.LIGHT
            ? {
                  boxShadow: 'none',
                  backgroundColor: theme.palette.primary.main,
              }
            : {}),
        ...(theme.name === THEMES.ONE_DARK
            ? {
                  backgroundColor: theme.palette.background.default,
              }
            : {}),
    },
    toolbar: {
        minHeight: 64,
    },
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5,
    },
}))

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles()

    return (
        <AppBar className={clsx(classes.root, className)} {...rest}>
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen}>
                        <SvgIcon fontSize="small">
                            <MenuIcon />
                        </SvgIcon>
                    </IconButton>
                </Hidden>
                <IconButton
                    color="inherit"
                    component={RouterLink}
                    to="/app/management/vehicles"
                >
                    <Typography variant="h2" color="initial">
                        Car One
                    </Typography>
                </IconButton>

                <Box ml={2} flexGrow={1} />
                <Box ml={2}>
                    <Notifications />
                </Box>
                <Tickets />
                <Settings />
                <Box ml={2}>
                    <Account />
                </Box>
            </Toolbar>
        </AppBar>
    )
}

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
}

TopBar.defaultProps = {
    onMobileNavOpen: () => {},
}

export default TopBar
