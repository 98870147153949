import React, { useReducer } from 'react';
import PreownedContext from './preownedContext';
import PreownedReducer from './preownedReducer';
import api from '../../api/api';
import {
    GET_PREOWNEDS,
    GET_PREOWNEDS_BY_MAKE,
    GET_PREOWNED,
    DELETE_PREOWNED,
    UPDATE_PREOWNED,
    CREATE_PREOWNED,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    CHANGE_INDEX,
} from '../types';

const PreownedState = props => {
    const initialState = {
        preowneds: [],
        preowned: {},
        loading: false,
        error: null,
        count: 0,
        success: false,
    };

    const [state, dispatch] = useReducer(PreownedReducer, initialState);

    //Get Preowneds
    const getPreownedsByMake = async (makeId, { page, limit, query }) => {
        setLoading();
        try {
            const res = await api.get(
                `/preowneds/getPreownedsDashboard?page=${page +
                    1}&limit=${limit}&searchIndex=model-category-make-year&searchText=${query}&makeUser=${makeId}&dash=2`
            );
            dispatch({
                type: GET_PREOWNEDS_BY_MAKE,
                payload: res.data.data,
                count: res.data.pagination,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Preowneds
    const getPreownedsByMultipleMakes = async (
        arrayMakes,
        user,
        { page, limit, query }
    ) => {
        setLoading();
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            };

            const res = await api.post(
                `/preowneds/getPreownedsMutipleDashboard?page=${page +
                    1}&limit=${limit}&searchIndex=model-category-make-year&searchText=${query}&sort=-createdAt&dash=2&user=${
                    user._id
                }`,
                { makes: arrayMakes },
                config
            );

            dispatch({
                type: GET_PREOWNEDS_BY_MAKE,
                payload: res.data.data,
                count: res.data.pagination,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    const getAllPreownedsByMake = async makeId => {
        setLoading();
        try {
            const res = await api.get(`/preowneds?make=${makeId}`);
            dispatch({
                type: GET_PREOWNEDS_BY_MAKE,
                payload: res.data.data,
                count: res.data.pagination,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Change index
    const setIndex = async (newIndex, oldIndex, start, end) => {
        setLoading();
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        try {
            const res = await api.post(
                `preowneds/setIndex`,
                { newIndex, oldIndex },
                config
            );
            dispatch({
                type: CHANGE_INDEX,
                payload: { ...res.data.data, start, end },
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Preowneds
    const getPreowneds = async ({ limit, page, query }) => {
        setLoading();
        try {
            const res = await api.get(
                `/preowneds/getPreownedsDashboard?page=${page +
                    1}&limit=${limit}&searchIndex=model-category-make-year&searchText=${query}&sort=-createdAt`
            );
            dispatch({
                type: GET_PREOWNEDS,
                payload: res.data.data,
                count: res.data.pagination,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Preowneds
    const getPreowned = async preownedId => {
        clearState();
        setLoading();
        try {
            const res = await api.get(`/preowneds/${preownedId}`);
            dispatch({ type: GET_PREOWNED, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Delete Preowned
    const deletePreowned = async preownedId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            const res = await api.delete(`/preowneds/${preownedId}`, config);
            dispatch({ type: DELETE_PREOWNED, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Create Preowned
    const createPreowned = async preowned => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            const { gallery, meta } = preowned;
            const filesGallery = [];
            let newGallery = [];

            gallery.map(item => filesGallery.push(item.file));

            //Load Meta Information
            if (meta && meta.fileImage) {
                let metaImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: meta.fileImage.type,
                        fileName: meta.fileImage.name,
                        folder: 'preowneds',
                    },
                    config
                );
                await api.put(metaImage.data.url, meta.fileImage, {
                    headers: {
                        'Content-Type': meta.fileImage
                            ? meta.fileImage.type
                            : null,
                    },
                });

                preowned.meta.image = metaImage.data.key;
                delete preowned.meta.fileImage;
            }

            if (preowned.fileMainImage) {
                let mainImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: preowned.fileMainImage.type,
                        fileName: preowned.fileMainImage.name,
                        folder: 'news',
                    },
                    config
                );
                await api.put(mainImage.data.url, preowned.fileMainImage, {
                    headers: {
                        'Content-Type': preowned.fileMainImage
                            ? preowned.fileMainImage.type
                            : null,
                    },
                });
                preowned.mainImage = mainImage.data.key;
                delete preowned.fileMainImage;
            }

            for (let i = 0; i < filesGallery.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesGallery[i].type,
                        fileName: filesGallery[i].name,
                        folder: 'news',
                    },
                    config
                );
                await api.put(resImage.data.url, filesGallery[i], {
                    headers: {
                        'Content-Type': filesGallery[i]
                            ? filesGallery[i].type
                            : null,
                    },
                });
                newGallery.push({ image: resImage.data.key });
            }

            preowned = { ...preowned, gallery: [...newGallery] };

            const res = await api.post(`preowneds`, preowned, config);
            dispatch({ type: CREATE_PREOWNED, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update Preowned
    const updatePreowned = async (preowned, preownedId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            const { gallery, meta } = preowned;

            const filesGallery = [];
            let newGallery = [];
            const oldGallery = [];
            gallery.map(item => {
                if (item.file) return filesGallery.push(item.file);
                return oldGallery.push(item);
            });

            //Load Meta Information
            if (meta && meta.fileImage) {
                let metaImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: meta.fileImage.type,
                        fileName: meta.fileImage.name,
                        folder: preowned._id,
                    },
                    config
                );
                await api.put(metaImage.data.url, meta.fileImage, {
                    headers: {
                        'Content-Type': meta.fileImage
                            ? meta.fileImage.type
                            : null,
                    },
                });

                preowned.meta.image = metaImage.data.key;
                delete preowned.meta.fileImage;
            }

            if (preowned.fileMainImage) {
                let mainImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: preowned.fileMainImage.type,
                        fileName: preowned.fileMainImage.name,
                        folder: preowned._id,
                    },
                    config
                );
                await api.put(mainImage.data.url, preowned.fileMainImage, {
                    headers: {
                        'Content-Type': preowned.fileMainImage
                            ? preowned.fileMainImage.type
                            : null,
                    },
                });

                preowned.mainImage = mainImage.data.key;
                delete preowned.fileMainImage;
            }

            for (let i = 0; i < filesGallery.length; i++) {
                let resImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: filesGallery[i].type,
                        fileName: filesGallery[i].name,
                        folder: preowned._id,
                    },
                    config
                );
                await api.put(resImage.data.url, filesGallery[i], {
                    headers: {
                        'Content-Type': filesGallery[i]
                            ? filesGallery[i].type
                            : null,
                    },
                });
                newGallery.push({ image: resImage.data.key });
            }

            preowned = { ...preowned, gallery: [...oldGallery, ...newGallery] };
            const res = await api.put(
                `/preowneds/${preownedId}`,
                preowned,
                config
            );
            dispatch({ type: UPDATE_PREOWNED, payload: res.data.data });
        } catch (err) {
            if (err && err.response && err.response.data) {
                dispatch({ type: SET_ERROR, payload: err.response.data });
            } else {
                dispatch({ type: SET_ERROR, payload: err });
            }
        }
    };

    const getPreownedsV3 = async params => {
        clearState();
        setLoading();
        try {
            const res = await api.post(`/preowneds/getPreownedsV3`, params);
            dispatch({
                type: GET_PREOWNEDS,
                payload: res.data.results.data,
                count: res.data.results.pagination,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <PreownedContext.Provider
            value={{
                loading: state.loading,
                count: state.count,
                preowneds: state.preowneds,
                preowned: state.preowned,
                error: state.error,
                success: state.success,
                getPreownedsByMake,
                getPreowneds,
                getPreownedsV3,
                getPreowned,
                createPreowned,
                deletePreowned,
                updatePreowned,
                clearState,
                setLoading,
                setIndex,
                getAllPreownedsByMake,
                getPreownedsByMultipleMakes,
            }}
        >
            {props.children}
        </PreownedContext.Provider>
    );
};

export default PreownedState;
