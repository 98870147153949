import React, { useReducer } from 'react';
import TicketContext from './TicketContext';
import TicketReducer from './TicketReducer';
import api from '../../api/api';
import {
    SET_LOADING,
    GET_TICKETS,
    GET_TICKET,
    DELETE_TICKET,
    CREATE_TICKET,
    UPDATE_TICKET,
    SET_ERROR,
    CLEAR_STATE,
    GET_TICKETS_NOTIFICATION,
} from '../types';
import CustomAlert from '../../components/CustomAlert';
import ErrorShowTime from 'src/constants/ErrorShowTime';

const TicketState = props => {
    const initialState = {
        tickets: [],
        ticket: {},
        loading: false,
        error: null,
        count: 0,
        notiTickets: [],
    };

    const [state, dispatch] = useReducer(TicketReducer, initialState);
    //Get Tickets
    const getTickets = async values => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        clearState();
        setLoading();
        try {
            const res = await api.post(
                `/tickets/advanceResults`,
                { ...values },
                config
            );
            dispatch({ type: GET_TICKETS, payload: res.data.results });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };
    //Get Single Item by ID
    const getTicket = async ticketId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        clearState();
        setLoading();
        try {
            const res = await api.get(`/tickets/${ticketId}`, config);
            dispatch({
                type: GET_TICKET,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };
    //Delete ticket
    const deleteTicket = async ticketId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            const res = await api.delete(`/tickets/${ticketId}`, config);
            dispatch({ type: DELETE_TICKET, payload: res.data.deletedId });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Create ticket
    const createTicket = async (ticket, files = []) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            let image = [];
            if (files === null) files = [];
            for (let i = 0; i < files.length; i++) {
                let resp = await api.post(
                    `/utils/uploads/image`,
                    {
                        type: files[i].type,
                        fileName: files[i].name,
                        folder: 'ticket',
                    },
                    config
                );
                const { data } = resp;
                const { url, key } = data;
                await api.put(url, files[i], {
                    headers: { 'Content-Type': files[i].type },
                });
                image.push(key);
            }
            ticket.media = image;
            const res = await api.post(`/tickets`, { ...ticket }, config);
            dispatch({ type: CREATE_TICKET, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update ticket
    const updateTicket = async (ticket, files = [], ticketId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            let imagec = [];
            if (files === '') files = [];
            for (let i = 0; i < files.length; i++) {
                let resp = await api.post(
                    `/utils/uploads/image`,
                    {
                        type: files[i].type,
                        fileName: files[i].name,
                        folder: 'ticketComment',
                    },
                    config
                );
                const { data } = resp;
                const { url, key } = data;
                await api.put(url, files[i], {
                    headers: { 'Content-Type': files[i].type },
                });
                imagec.push(key);
            }
            ticket.comment.images = imagec;
            const res = await api.put(
                `/tickets/${ticketId}`,
                { ...ticket },
                config
            );
            dispatch({ type: UPDATE_TICKET, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update ticket
    const updateTicket2 = async (ticket, ticketId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        clearState();
        setLoading();
        try {
            const res = await api.put(
                `/tickets/${ticketId}`,
                { ...ticket },
                config
            );
            dispatch({ type: UPDATE_TICKET, payload: res.data.data });
            CustomAlert({
                title: 'Status Updated',
                icon: 'success',
                timer: ErrorShowTime,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Tickets
    const getTicketRockstar = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            const res = await api.post(
                `/tickets/notification/rockstar`,
                config
            );
            dispatch({
                type: GET_TICKETS_NOTIFICATION,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Tickets
    const getTicketAssigned = async roleType => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            const res = await api.post(
                `/tickets/notification/assigned`,
                { roleType },
                config
            );
            dispatch({
                type: GET_TICKETS_NOTIFICATION,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Tickets
    const getTicketUser = async userId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            const res = await api.post(
                `/tickets/notification/user`,
                { userId },
                config
            );
            dispatch({
                type: GET_TICKETS_NOTIFICATION,
                payload: res.data.data,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <TicketContext.Provider
            value={{
                loading: state.loading,
                tickets: state.tickets,
                ticket: state.ticket,
                error: state.error,
                count: state.count,
                notiTickets: state.notiTickets,
                setLoading,
                clearState,
                getTickets,
                getTicket,
                updateTicket,
                deleteTicket,
                createTicket,
                updateTicket2,
                getTicketRockstar,
                getTicketAssigned,
                getTicketUser,
            }}
        >
            {props.children}
        </TicketContext.Provider>
    );
};

export default TicketState;
