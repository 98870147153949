import CustomAlert from 'src/components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'
import {
    GET_DESIGNS,
    GET_DESIGN,
    CREATE_DESIGN,
    DELETE_DESIGN,
    UPDATE_DESIGN,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types'

export default (state, action) => {
    switch (action.type) {
        case GET_DESIGNS:
            return {
                ...state,
                designs: action.payload.data,
                count: action.payload.pagination.total,
                loading: false,
                error: null,
            }
        case GET_DESIGN:
            return {
                ...state,
                design: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_DESIGN:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case DELETE_DESIGN:
            state.designs = state.designs.filter(
                item => item._id.toString() !== action.payload.toString()
            )
            return {
                ...state,
                design: null,
                loading: false,
                error: null,
            }
        case UPDATE_DESIGN:
            let findDesign = state?.designs?.findIndex?.(
                design => design?._id === action?.payload?._id
            )
            let newDesigns = state.designs
            if (findDesign !== -1) newDesigns[findDesign] = action.payload
            return {
                ...state,
                design: action.payload,
                designs: newDesigns,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                design: {},
                designs: action?.dontClean.includes('designs')
                    ? state?.designs
                    : [],
                count: 0,
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
