import React, { useState, useRef, useEffect } from 'react'
import {
    Box,
    Button,
    FormControlLabel,
    IconButton,
    Popover,
    SvgIcon,
    Switch,
    Tooltip,
    Typography,
    makeStyles,
} from '@material-ui/core'
import { Settings as SettingsIcon } from 'react-feather'
import i18next from 'src/utils/i18next'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5,
    },
    popover: {
        width: 320,
        padding: theme.spacing(2),
    },
}))

const Settings = () => {
    const classes = useStyles()
    const ref = useRef(null)
    const [language, setLanguage] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        if (localStorage.getItem('language')) {
            i18next.changeLanguage(localStorage.getItem('language'))
            if (localStorage.getItem('language') === 'es') {
                setLanguage(true)
            } else {
                setLanguage(false)
            }
        } else {
            localStorage.setItem('language', 'en')
            i18next.changeLanguage(localStorage.getItem('language'))
        }
    }, [])

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSave = () => {
        if (language) {
            localStorage.setItem('language', 'es')
        }
        if (!language) {
            localStorage.setItem('language', 'en')
        }
        i18next.changeLanguage(localStorage.getItem('language'))
        setOpen(false)
    }

    return (
        <>
            <Tooltip title={t('Settings.Settings')}>
                <IconButton color="inherit" onClick={handleOpen} ref={ref}>
                    <SvgIcon fontSize="small">
                        <SettingsIcon />
                    </SvgIcon>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.popover }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Typography variant="h4" color="textPrimary">
                    {t('Settings.Settings')}
                </Typography>
                <Box mt={2} px={1}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={language}
                                edge="start"
                                name="language"
                                onChange={event => {
                                    setLanguage(!language)
                                }}
                            />
                        }
                        label="EN/ES"
                    />
                </Box>
                <Box mt={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={handleSave}
                    >
                        {t('Settings.Save')}
                    </Button>
                </Box>
            </Popover>
        </>
    )
}

export default Settings
