import {
    GET_SHOPPINGS,
    GET_SHOPPING,
    CREATE_SHOPPING,
    DELETE_SHOPPING,
    UPDATE_SHOPPING,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_SHOPPINGSV3,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

export default (state, action) => {
    switch (action.type) {
        case GET_SHOPPINGS:
            return {
                ...state,
                shoppings: action.payload,

                loading: false,
                error: null,
            }
        case GET_SHOPPINGSV3:
            return {
                ...state,
                shoppings: action.payload,
                count: action.count.total,
                loading: false,
                error: null,
            }
        case GET_SHOPPING:
            return {
                ...state,
                shopping: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_SHOPPING:
            return {
                ...state,
                shopping: action.payload,
                loading: false,
                error: null,
            }
        case DELETE_SHOPPING:
            state.shoppings = state.shoppings.filter(
                item => item._id.toString() !== action.payload.toString()
            )
            return {
                ...state,
                shopping: null,
                loading: false,
                error: null,
            }
        case UPDATE_SHOPPING:
            return {
                ...state,
                shopping: action.payload,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                shopping: {},
                shoppings: [],
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
