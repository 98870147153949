import {
    GET_PREOWNEDS,
    GET_PREOWNEDS_BY_MAKE,
    GET_PREOWNED,
    CREATE_PREOWNED,
    DELETE_PREOWNED,
    UPDATE_PREOWNED,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    CHANGE_INDEX,
} from '../types'

export default (state, action) => {
    switch (action.type) {
        case CHANGE_INDEX:
            const { oldIndex, newIndex, start, end } = action.payload
            const old = state.preowneds.findIndex(
                item => item.index === oldIndex
            )
            const id = state.preowneds[old]._id
            let suma = 1
            let currentIndex
            if (end < start) {
                for (let i = end; i < start; i++) {
                    //eslint-disable-next-line
                    currentIndex = state.preowneds.findIndex(
                        //eslint-disable-next-line
                        item => item.index === oldIndex + suma
                    )
                    state.preowneds[currentIndex].index =
                        state.preowneds[currentIndex].index - 1
                    suma++
                }

                currentIndex = state.preowneds.findIndex(
                    item => item._id === id
                )
                state.preowneds[currentIndex].index = newIndex
            } else {
                for (let i = start; i < end; i++) {
                    //eslint-disable-next-line
                    currentIndex = state.preowneds.findIndex(
                        //eslint-disable-next-line
                        item => item.index === oldIndex - suma
                    )
                    state.preowneds[currentIndex].index =
                        state.preowneds[currentIndex].index + 1

                    suma++
                }
                currentIndex = state.preowneds.findIndex(
                    item => item._id === id
                )
                state.preowneds[currentIndex].index = newIndex
            }
            return {
                ...state,
                loading: false,
            }
        case GET_PREOWNEDS_BY_MAKE:
            return {
                ...state,
                preowneds: action.payload,
                count: action.count.total,
                loading: false,
                error: null,
            }
        case GET_PREOWNEDS:
            return {
                ...state,
                count: action.count.total,
                preowneds: action.payload,
                loading: false,
                error: null,
            }
        case GET_PREOWNED:
            return {
                ...state,
                preowned: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_PREOWNED:
            return {
                ...state,
                loading: false,
                error: null,
                success: true,
            }
        case DELETE_PREOWNED:
            if (state.preowneds && state.preowneds.length > 0) {
                const deletedIndex = state.preowneds.findIndex(
                    item => item.index === action.payload.deletedIndex
                )
                for (let i = deletedIndex; i < state.preowneds.length; i++) {
                    if (
                        state &&
                        state.preowned &&
                        state.preowned[i] &&
                        state.preowned[i].index
                    ) {
                        state.preowneds[i].index = state.preowneds[i].index - 1
                    }
                }
                state.preowneds = state.preowneds.filter(
                    item =>
                        item._id.toString() !==
                        action.payload.deletedId.toString()
                )
            }
            return {
                ...state,
                preowned: null,
                loading: false,
                error: null,
            }
        case UPDATE_PREOWNED:
            return {
                ...state,
                preowned: action.payload,
                loading: false,
                error: null,
                success: true,
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
                success: false,
            }
        case CLEAR_STATE:
            return {
                preowned: {},
                preowneds: [],
                loading: false,
                error: null,
                success: false,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
