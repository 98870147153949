import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from 'src/hooks/useAuth'

const MediumGuard = ({ children, permissions, path }) => {
    const [loadedUser, setLoadedUser] = useState(false)
    const { loadUser, user } = useAuth()
    const history = useHistory()

    if (!localStorage.getItem('token')) {
        history.push('/login')
    }

    if (!loadedUser && !user) {
        loadUser()
        setLoadedUser(true)
    }

    useEffect(() => {
        if (!user || !user._id) return
        if (
            user &&
            user.permissions &&
            typeof user.permissions !== 'undefined' &&
            Object.keys(user.permissions).length >= 1
        ) {
            let access = false
            let accessArr = []
            if (user.permissions[path]) {
                ;[...permissions].forEach(permission => {
                    if (user.permissions[path].includes(permission))
                        accessArr.push(true)
                    else {
                        accessArr.push(false)
                    }
                })
            }

            if (accessArr.length >= 1)
                access = !accessArr.some(row => row === false)

            if (!access) {
                let redirect = ''
                Object.entries(user.permissions).forEach(row => {
                    let [name, value] = row
                    if (value.includes('r') && redirect === '') redirect = name
                })
                history.push(`${redirect}`)
            }
        } else if (
            user &&
            user.role &&
            user.role !== 'super admin' &&
            user.role !== 'rockstar' &&
            user.role !== 'marketing'
        ) {
            if (user.role === 'rh') {
                history.push('/app/management/jobs')
            } else if (user.role === 'marketing digital') {
                history.push('/app/management/segmentations')
            } else if (
                ['designer', 'admin marketing digital'].includes(user.role)
            ) {
                history.push('/app/management/designs')
            } else {
                switch (user.carType) {
                    case 'seminuevo':
                        if (!history.location.pathname.includes('preowned')) {
                            history.push('/app/management/preowneds')
                        }
                        break
                    default:
                        // history.push("/app/management/vehicles")
                        break
                }
            }
        }

        //eslint-disable-next-line
    }, [user])

    return <>{children}</>
}

MediumGuard.propTypes = {
    children: PropTypes.node,
}

export default MediumGuard
