import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from 'src/hooks/useAuth'

const HighGuard = ({ children, permissions, path }) => {
    const { user } = useAuth()
    const history = useHistory()

    if (!localStorage.getItem('token')) {
        history.push('/login')
    }

    useEffect(() => {
        if (
            user &&
            user.permissions &&
            typeof user.permissions !== 'undefined' &&
            Object.keys(user.permissions).length >= 1
        ) {
            let access = false
            let accessArr = []
            if (user.permissions[path]) {
                ;[...permissions].forEach(permission => {
                    if (user.permissions[path].includes(permission))
                        accessArr.push(true)
                    else {
                        accessArr.push(false)
                    }
                })
            }

            if (accessArr.length >= 1)
                access = !accessArr.some(row => row === false)

            if (access) {
            } else {
                let redirect = ''
                Object.entries(user.permissions).forEach(row => {
                    let [name, value] = row
                    if (value.includes('r') && redirect === '') redirect = name
                })
                history.push(`${redirect}`)
            }
        } else if (
            user &&
            user.role &&
            user.role !== 'super admin' &&
            user.role !== 'rockstar'
        ) {
            history.push('/app/management/vehicles')
        }

        //eslint-disable-next-line
    }, [user])

    return <>{children}</>
}

HighGuard.propTypes = {
    children: PropTypes.node,
}

export default HighGuard
