import {
    Aperture as MakeIcon,
    Truck as VehicleIcon,
    Home as StoreIcon,
    Layers as CategoryIcon,
    User as UserIcon,
    Users as UsersIcon,
    Camera as MediaIcon,
    Tablet as CampaignIcon,
    Briefcase,
    Image,
    Calendar,
    Archive,
    Shield,
} from 'react-feather';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { ADMIN_MARKETING_DIGITAL, ROCKSTAR } from 'src/constants';

const Sections = [
    {
        subheader: 'Navbar.Control',
        items: [
            {
                title: 'Navbar.Makes',
                href: '/app/management/makes',
                canAddDlc: [ROCKSTAR],
                icon: MakeIcon,
            },
            {
                title: 'Navbar.BannersC1',
                href: '/app/management/carone',
                canAddDlc: [ROCKSTAR],
                icon: Image,
            },
            {
                title: 'Navbar.Media',
                href: '/app/management/medias',
                canAddDlc: [ROCKSTAR],
                icon: MediaIcon,
            },
            {
                title: 'Navbar.Cars',
                href: '/app/management/cars',
                canAddDlc: [ROCKSTAR],
                icon: VehicleIcon,
            },
            {
                title: 'Navbar.Vehicles',
                href: '/app/management/vehicles',
                canAddDlc: [ROCKSTAR, ADMIN_MARKETING_DIGITAL],
                icon: VehicleIcon,
            },
            {
                title: 'Navbar.Preowneds',
                href: '/app/management/preowneds',
                canAddDlc: [ROCKSTAR, ADMIN_MARKETING_DIGITAL],
                icon: VehicleIcon,
            },
            {
                title: 'Navbar.Stores',
                href: '/app/management/stores',
                canAddDlc: [ROCKSTAR],
                icon: StoreIcon,
            },
            {
                title: 'Navbar.Categories',
                href: '/app/management/categories',
                canAddDlc: [ROCKSTAR],
                icon: CategoryIcon,
            },
            {
                title: 'Navbar.Users',
                href: '/app/management/users',
                canAddDlc: [ROCKSTAR],
                icon: UserIcon,
            },
            {
                title: 'Navbar.Jobs',
                icon: Briefcase,
                canAddDlc: [ROCKSTAR],
                href: '/app/management/jobs',
            },
            {
                title: 'Navbar.Applicants',
                icon: UserIcon,
                canAddDlc: [ROCKSTAR],
                href: '/app/management/applicants',
            },
            {
                title: 'Navbar.Complaints',
                icon: Shield,
                canAddDlc: [ROCKSTAR],
                href: '/app/management/carone-contigo',
            },
            {
                title: 'Navbar.Suppliers',
                icon: StorefrontIcon,
                canAddDlc: [ROCKSTAR],
                href: '/app/management/providers',
            },
            {
                title: 'Navbar.Shopping',
                icon: ShoppingCartIcon,
                canAddDlc: [ROCKSTAR],
                href: '/app/management/shoppings',
            },
        ],
    },
    {
        subheader: 'Marketing',
        items: [
            {
                title: 'Navbar.CalendarDesigns',
                icon: Calendar,
                href: '/app/management/calendar-designs',
                canAddDlc: [ROCKSTAR, ADMIN_MARKETING_DIGITAL],
                defaultPermissions: ['marketing digital'],
            },
            {
                title: 'Navbar.Segmentations',
                icon: UsersIcon,
                href: '/app/management/segmentations',
                canAddDlc: [ROCKSTAR, ADMIN_MARKETING_DIGITAL],
                defaultPermissions: ['marketing digital'],
            },
            {
                title: 'Navbar.Campaigns',
                icon: CampaignIcon,
                href: '/app/management/campaigns',
                canAddDlc: [ROCKSTAR, ADMIN_MARKETING_DIGITAL],
                defaultPermissions: ['marketing digital'],
            },
            {
                title: 'Navbar.PresentationCard',
                icon: UserIcon,
                href: '/app/management/presentation-cards',
                canAddDlc: [ROCKSTAR, ADMIN_MARKETING_DIGITAL],
                defaultPermissions: ['marketing digital'],
            },
            {
                title: 'Navbar.Promotions',
                icon: Image,
                href: '/app/management/promotions',
                canAddDlc: [ROCKSTAR, ADMIN_MARKETING_DIGITAL],
                defaultPermissions: ['marketing digital'],
            },
            {
                title: 'Navbar.Plan',
                icon: Archive,
                href: '/app/management/plan',
                canAddDlc: [ROCKSTAR, ADMIN_MARKETING_DIGITAL],
                defaultPermissions: ['marketing'],
            },
        ],
    },
    {
        subheader: 'Navbar.Designs',
        items: [
            {
                title: `Solicitar Video`,
                icon: Image,
                canAddDlc: [ROCKSTAR, ADMIN_MARKETING_DIGITAL],
                href: '/app/management/designs-video',
            },
            {
                title: 'Navbar.DesignsPaid',
                icon: Image,
                canAddDlc: [ROCKSTAR, ADMIN_MARKETING_DIGITAL],
                href: '/app/management/designs-paid',
            },
            {
                title: 'Navbar.DesignsOrganic',
                icon: Image,
                canAddDlc: [ROCKSTAR, ADMIN_MARKETING_DIGITAL],
                href: '/app/management/designs-organic',
            },
        ],
    },
];

export default Sections;
