/*eslint no-loop-func: "off"*/
/*eslint-env es6*/
import {
    GET_MAKES,
    CREATE_MAKE,
    GET_MAKE,
    DELETE_MAKE,
    UPDATE_MAKE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    CHANGE_INDEX,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

export default (state, action) => {
    switch (action.type) {
        case CHANGE_INDEX:
            const { oldIndex, newIndex, start, end } = action.payload
            const old = state.makes.findIndex(item => item.index === oldIndex)
            const id = state.makes[old]._id

            let suma = 1
            let currentIndex
            if (end < start) {
                for (let i = end; i < start; i++) {
                    currentIndex = state.makes.findIndex(
                        item => item.index === oldIndex + suma
                    )
                    state.makes[currentIndex].index =
                        state.makes[currentIndex].index - 1
                    suma++
                }

                currentIndex = state.makes.findIndex(item => item._id === id)
                state.makes[currentIndex].index = newIndex
            } else {
                for (let i = start; i < end; i++) {
                    currentIndex = state.makes.findIndex(
                        item => item.index === oldIndex - suma
                    )
                    state.makes[currentIndex].index =
                        state.makes[currentIndex].index + 1

                    suma++
                }
                currentIndex = state.makes.findIndex(item => item._id === id)
                state.makes[currentIndex].index = newIndex
            }
            return {
                ...state,
                loading: false,
            }
        case GET_MAKES:
            return {
                ...state,
                makes: action.payload,
                loading: false,
                count: action.count,
                error: null,
            }
        case GET_MAKE:
            return {
                ...state,
                make: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_MAKE:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case DELETE_MAKE:
            if (state.makes && state.makes.length > 0) {
                const deletedIndex = state.makes.findIndex(
                    item => item.index === action.payload.deletedIndex
                )
                for (let i = deletedIndex; i < state.makes.length; i++) {
                    state.makes[i].index = state.makes[i].index - 1
                }
                state.makes = state.makes.filter(
                    make =>
                        make._id.toString() !==
                        action.payload.deletedId.toString()
                )
            }
            return {
                ...state,
                make: null,
                loading: false,
                error: null,
            }
        case UPDATE_MAKE:
            return {
                ...state,
                make: action.payload,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                make: {},
                makes: [],
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
