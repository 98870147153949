// Auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'

// User
export const UPDATE_USER = 'UPDATE_USER'
export const GET_USER_BY_ID = 'GET_USER_BY_ID'
export const GET_USERS = 'GET_USERS'
export const GET_USER = 'GET_USER'
export const DELETE_USER = 'DELETE_USER'
export const CREATE_USER = 'CREATE_USER'
export const GET_USERS_BY_STORE = 'GET_USERS_BY_STORE'

// Vehicles
export const GET_VEHICLES = 'GET_VEHICLES'
export const GET_VEHICLE = 'GET_VEHICLE'
export const GET_VEHICLES_BY_MAKE = 'GET_VEHICLES_BY_MAKE'
export const CREATE_VEHICLE = 'CREATE_VEHICLE'
export const DELETE_VEHICLE = 'DELETE_VEHICLE'
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE'

// Preowneds
export const GET_PREOWNEDS = 'GET_PREOWNEDS'
export const GET_PREOWNED = 'GET_PREOWNED'
export const GET_PREOWNEDS_BY_MAKE = 'GET_PREOWNEDS_BY_MAKE'
export const CREATE_PREOWNED = 'CREATE_PREOWNED'
export const DELETE_PREOWNED = 'DELETE_PREOWNED'
export const UPDATE_PREOWNED = 'UPDATE_PREOWNED'

// Jobs
export const GET_JOBS = 'GET_JOBS'
export const GET_JOB = 'GET_JOB'
export const GET_JOBS_BY_MAKE = 'GET_JOBS_BY_MAKE'
export const CREATE_JOB = 'CREATE_JOB'
export const DELETE_JOB = 'DELETE_JOB'
export const UPDATE_JOB = 'UPDATE_JOB'

// Promotion
export const GET_PROMOTIONS = 'GET_PROMOTIONS'
export const GET_PROMOTION = 'GET_PROMOTION'
export const GET_PROMOTIONS_BY_VEHICLE = 'GET_PROMOTIONS_BY_VEHICLE'
export const CREATE_PROMOTION = 'CREATE_PROMOTION'
export const DELETE_PROMOTION = 'DELETE_PROMOTION'
export const UPDATE_PROMOTION = 'UPDATE_PROMOTION'
export const GET_PROMOTIONS_BY_STORE = 'GET_PROMOTIONS_BY_STORE'
export const GET_PROMOTIONSV3 = 'GET_PROMOTIONSV3'

// Versions
export const GET_VERSIONS = 'GET_VERSIONS'
export const GET_VERSION = 'GET_VERSION'
export const GET_VERSIONS_BY_VEHICLE = 'GET_VERSIONS_BY_VEHICLE'
export const CREATE_VERSION = 'CREATE_VERSION'
export const DELETE_VERSION = 'DELETE_VERSION'
export const UPDATE_VERSION = 'UPDATE_VERSION'

// Media
export const GET_MEDIAS = 'GET_MEDIAS'
export const GET_MEDIA = 'GET_MEDIA'
export const GET_MEDIAS_BY_VEHICLE = 'GET_MEDIAS_BY_VEHICLE'
export const CREATE_MEDIA = 'CREATE_MEDIA'
export const DELETE_MEDIA = 'DELETE_MEDIA'
export const UPDATE_MEDIA = 'UPDATE_MEDIA'
export const GET_MEDIAS_MAIN_BANNER = 'GET_MEDIAS_MAIN_BANNER'

// Categories
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORY = 'GET_CATEGORY'
export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'

// Makes
export const GET_MAKES = 'GET_MAKES'
export const GET_MAKE = 'GET_MAKE'
export const CREATE_MAKE = 'CREATE_MAKE'
export const DELETE_MAKE = 'DELETE_MAKE'
export const UPDATE_MAKE = 'UPDATE_MAKE'

// Stores
export const GET_STORES = 'GET_STORES'
export const GET_STORES_BY_MAKE = 'GET_STORES_BY_MAKE'
export const GET_STORE = 'GET_STORE'
export const CREATE_STORE = 'CREATE_STORE'
export const DELETE_STORE = 'DELETE_STORE'
export const UPDATE_STORE = 'UPDATE_STORE'
export const UPDATE_STORE_FROM_PROFILE = 'UPDATE_STORE_FROM_PROFILE'

// Document
export const GET_DOCUMENTS = 'GET_DOCUMENTS'
export const GET_DOCUMENTS_BY_STORE = 'GET_DOCUMENTS_BY_STORE'
export const GET_DOCUMENT = 'GET_DOCUMENT'
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT'
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT'

// Status
export const GET_STATUSES = 'GET_ALL_STATUS'
export const GET_STATUS = 'GET_STATUS'
export const CREATE_STATUS = 'CREATE_STATUS'
export const DELETE_STATUS = 'DELETE_STATUS'
export const UPDATE_STATUS = 'UPDATE_STATUS'

// Sources
export const GET_SOURCES = 'GET_SOURCES'
export const GET_SOURCE = 'GET_SOURCE'
export const CREATE_SOURCE = 'CREATE_SOURCE'
export const DELETE_SOURCE = 'DELETE_SOURCE'
export const UPDATE_SOURCE = 'UPDATE_SOURCE'

// Mails
export const CREATE_MAIL = 'CREATE_MAIL'
export const CREATE_MAIL_ATTACHMENT = 'CREATE_MAIL_ATTACHMENT'

// Activities
export const GET_ACTIVITIES = 'GET_ACTIVITIES'
export const GET_ACTIVITY = 'GET_ACTIVITY'
export const CREATE_ACTIVITY = 'CREATE_ACTIVITY'
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY'
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY'
export const GET_ACTIVITIES_BY_USER = 'GET_ACTIVITIES_BY_USER'
export const GET_ACTIVITIES_BY_LEAD = 'GET_ACTIVITIES_BY_STORE'
export const GET_ACTIVITIES_BY_OMSGLOBAL = 'GET_ACTIVITIES_BY_OMSGLOBAL'
export const GET_ACTIVITIES_BY_QUESTLEAD = 'GET_ACTIVITIES_BY_QUESTLEAD'
export const GET_ACTIVITIES_AR = 'GET_ACTIVITIES_AR'

// Comments
export const GET_COMMENTS_BY_LEAD = 'GET_COMMENTS_BY_LEAD'
export const CREATE_COMMENT = 'CREATE_COMMENT'
export const GET_COMMENTS_BY_OMSGLOBAL = 'GET_COMMENTS_BY_OMSGLOBAL'
export const GET_COMMENTS_BY_QUESTLEAD = 'GET_COMMENTS_BY_QUESTLEAD'

// Alert
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'

// Profile
export const GET_PROFILE = 'GET_PROFILE'
export const CREATE_PROFILE = 'CREATE_PROFILE'
export const GET_PROFILE_BY_USER = 'GET_PROFILE_BY_USER'

// Loading
export const SET_LOADING = 'SET_LOADING'

// Error
export const SET_ERROR = 'SET_ERROR'

// Success
export const SET_SUCCESS = 'SET_SUCCESS'

// Language
export const SET_LANGUAGE = 'SET_LANGUAGE'

// Clear States
export const CLEAR_STATE = 'CLEAR_STATE'
export const CLEAR_CHARTS_STATE = 'CLEAR_CHARTS_STATE'

// Charts
export const GET_CHART = 'GET_CHART'

//Oms Leads
export const GET_OMSGLOBAL = 'GET_OMSGLOBAL'
export const GET_OMSGLOBALS = 'GET_OMSGLOBALS'
export const GET_OMSGLOBALS_BY_STORE = 'GET_OMSGLOBALS_BY_STORE'
export const GET_OMSGLOBALS_BY_USER = 'GET_OMSGLOBALS_BY_USER'
export const UPDATE_OMSGLOBAL = 'UPDATE_OMSGLOBAL'
export const DELETE_OMSGLOBAL = 'DELETE_OMSGLOBAL'
export const CREATE_OMSGLOBAL = 'CREATE_OMSGLOBAL'
export const GET_OMSGLOBALS_CHART = 'GET_OMSGLOBALS_CHART'
export const GET_OMSGLOBALS_BY_STATUS = 'GET_OMSGLOBALS_BY_STATUS'
export const GET_OMSGLOBALS_AR = 'GET_OMSGLOBALS_AR'
export const GET_OMSGLOBALS_SOLD = 'GET_OMSGLOBALS_SOLD'
export const GET_OMSGLOBALS_NEW = 'GET_OMSGLOBALS_NEW'
export const GET_OMSGLOBALS_FOLLOW = 'GET_OMSGLOBALS_FOLLOW'
export const GET_OMSGLOBALS_DATE = 'GET_OMSGLOBALS_DATE'
export const GET_LAST_OMSGLOBALS = 'GET_LAST_OMSGLOBALS'
export const GET_ALL_CHART_OMSGLOBALS = 'GET_ALL_CHART_OMSGLOBALS'

//Quest Leads
export const GET_QUESTLEAD = 'GET_QUESTLEAD'
export const GET_QUESTLEADS = 'GET_QUESTLEADS'
export const GET_QUESTLEADS_BY_STORE = 'GET_QUESTLEADS_BY_STORE'
export const GET_QUESTLEADS_BY_USER = 'GET_QUESTLEADS_BY_USER'
export const UPDATE_QUESTLEAD = 'UPDATE_QUESTLEAD'
export const DELETE_QUESTLEAD = 'DELETE_QUESTLEAD'
export const CREATE_QUESTLEAD = 'CREATE_QUESTLEAD'
export const GET_QUESTLEADS_CHART = 'GET_QUESTLEADS_CHART'
export const GET_QUESTLEADS_BY_STATUS = 'GET_QUESTLEADS_BY_STATUS'
export const GET_QUESTLEADS_AR = 'GET_QUESTLEADS_AR'
export const GET_QUESTLEADS_SOLD = 'GET_QUESTLEADS_SOLD'
export const GET_QUESTLEADS_NEW = 'GET_QUESTLEADS_NEW'
export const GET_QUESTLEADS_FOLLOW = 'GET_QUESTLEADS_FOLLOW'
export const GET_QUESTLEADS_DATE = 'GET_QUESTLEADS_DATE'
export const GET_LAST_QUESTLEADS = 'GET_LAST_QUESTLEADS'
export const GET_ALL_CHART_QUESTLEADS = 'GET_ALL_CHART_QUESTLEADS'

// Wsp
export const ADD_CONVERSATION = 'ADD_CONVERSATION'

// Companies
export const CALL_USER = 'CALL_USER'

// Companies
export const GET_COMPANIES = 'GET_COMPANIES'
export const GET_COMPANY = 'GET_COMPANY'
export const CREATE_COMPANY = 'CREATE_COMPANY'
export const DELETE_COMPANY = 'DELETE_COMPANY'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'

// Extras
export const LOAD_CSV = 'LOAD_CSV'
export const SET_DELETING_MANY = 'SET_DELETING_MANY'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const SET_VALUE = 'SET_VALUE'
export const CHANGE_INDEX = 'CHANGE_INDEX'
export const UPDATE_INDEX = 'UPDATE_INDEX'

// Cars
export const GET_CARS = 'GET_CARS'
export const GET_CAR = 'GET_CAR'
export const UPDATE_CAR = 'UPDATE_CAR'
export const DELETE_CAR = 'DELETE_CAR'
export const CREATE_CAR = 'CREATE_CAR'
export const GET_CARS_BY_MAKE = 'GET_CARS_BY_MAKE'

// Designs
export const GET_DESIGNS = 'GET_DESIGNS'
export const GET_DESIGN = 'GET_DESIGN'
export const CREATE_DESIGN = 'CREATE_DESIGN'
export const DELETE_DESIGN = 'DELETE_DESIGN'
export const UPDATE_DESIGN = 'UPDATE_DESIGN'

// segmentations
export const GET_SEGMENTATIONS = 'GET_SEGMENTATIONS'
export const GET_SEGMENTATION = 'GET_SEGMENTATION'
export const CREATE_SEGMENTATION = 'CREATE_SEGMENTATION'
export const DELETE_SEGMENTATION = 'DELETE_SEGMENTATION'
export const UPDATE_SEGMENTATION = 'UPDATE_DESIGN'

// Campaigns
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS'
export const GET_CAMPAIGN = 'GET_CAMPAIGN'
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN'
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN'
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'

// TICKETS
export const GET_TICKETS = 'GET_TICKETS'
export const CREATE_TICKET = 'CREATE_TICKET'
export const DELETE_TICKET = 'DELETE_TICKET'
export const GET_TICKET = 'GET_TICKET'
export const UPDATE_TICKET = 'UPDATE_TICKET'
export const GET_TICKETS_NOTIFICATION = 'GET_TICKETS_NOTIFICATION'

// CARDS
export const GET_CARDS = 'GET_CARDS'
export const GET_CARD = 'GET_CARD'
export const UPDATE_CARD = 'UPDATE_CARD'
export const DELETE_CARD = 'DELETE_CARD'
export const CREATE_CARD = 'CREATE_CARD'
export const GET_CARDS_BY_STORE = 'GET_CARDS_BY_STORE'

//HISTORY
export const GET_HISTORY_BY_VEHICLE = 'GET_HISTORY_BY_VEHICLE'
export const CREATE_HISTORY = 'CREATE_HISTORY'
export const GET_HISTORY_BY_PREOWNED = 'GET_HISTORY_BY_PREOWNED'
export const GET_HISTORY = 'GET_HISTORY'

// COMPLAINTS
export const GET_COMPLAINTS = 'GET_COMPLAINTS'
export const GET_COMPLAINT = 'GET_COMPLAINT'
export const CREATE_COMPLAINT = 'CREATE_COMPLAINT'
export const DELETE_COMPLAINT = 'DELETE_COMPLAINT'
export const UPDATE_COMPLAINT = 'UPDATE_COMPLAINT'

//PLAN RESULTS
export const GET_PLANRESULTS = 'GET_PLANRESULTS'
export const GET_PLANRESULT = 'GET_PLANRESULT'
export const CREATE_PLANRESULT = 'CREATE_PLANRESULT'
export const DELETE_PLANRESULT = 'DELETE_PLANRESULT'
export const UPDATE_PLANRESULT = 'UPDATE_PLANRESULT'

//PLAN
export const GET_PLANS = 'GET_PLANS'
export const GET_PLAN = 'GET_PLAN'
export const IS_FOUND = 'IS_FOUND'
export const CREATE_PLAN = 'CREATE_PLAN'
export const DELETE_PLAN = 'DELETE_PLAN'
export const UPDATE_PLAN = 'UPDATE_PLAN'

// Notifications
export const FILTER_NOTIFICATION_BY_IS_READ = 'FILTER_NOTIFICATION_BY_IS_READ'
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const GET_ALL_NOTIFICATIONS_BY_USER = 'GET_ALL_NOTIFICATIONS_BY_USER'
export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS'
export const GET_NOTIFICATIONS_BY_STORE = 'GET_NOTIFICATIONS_BY_STORE'
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const UPDATE_NOTIFICATION_LIST = 'UPDATE_NOTIFICATION_LIST'
export const DELETE_FROM_NOTIFICATION_LIST = 'DELETE_FROM_NOTIFICATION_LIST'
export const GET_UNREAD_CHATS = 'GET_UNREAD_CHATS'
export const UPDATE_NOTIFICATION_VIEW = 'UPDATE_NOTIFICATION_VIEW'
export const GET_NOTIFICATIONS_BY_USER = 'GET_NOTIFICATIONS_BY_USER'
export const GET_NOTIFICATIONS_BY_SHOPPING = 'GET_NOTIFICATIONS_BY_SHOPPING'

// Provider
export const GET_PROVIDERS = 'GET_PROVIDERS'
export const GET_PROVIDER = 'GET_PROVIDER'
export const CREATE_PROVIDER = 'CREATE_PROVIDER'
export const DELETE_PROVIDER = 'DELETE_PROVIDER'
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER'
export const GET_PROVIDERSV3 = 'GET_PROVIDERSV3'

// Shopping
export const GET_SHOPPINGS = 'GET_SHOPPINGS'
export const GET_SHOPPING = 'GET_SHOPPING'
export const CREATE_SHOPPING = 'CREATE_SHOPPING'
export const DELETE_SHOPPING = 'DELETE_SHOPPING'
export const UPDATE_SHOPPING = 'UPDATE_SHOPPING'
export const GET_SHOPPINGSV3 = 'GET_SHOPPINGSV3'
