import React, { useReducer } from 'react'
import PromotionContext from './promotionContext'
import PromotionReducer from './promotionReducer'
import api from '../../api/api'
import {
    GET_PROMOTIONS,
    GET_PROMOTIONS_BY_VEHICLE,
    GET_PROMOTIONS_BY_STORE,
    GET_PROMOTION,
    DELETE_PROMOTION,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    CREATE_PROMOTION,
    GET_PROMOTIONSV3,
    UPDATE_PROMOTION,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'
import { useHistory } from 'react-router-dom'

const PromotionState = props => {
    const history = useHistory()
    const initialState = {
        promotions: [],
        promocion: {},
        loading: false,
        error: null,
        count: 0,
    }

    const [state, dispatch] = useReducer(PromotionReducer, initialState)

    const getPromotionsByStore = async storeId => {
        setLoading()
        try {
            const res = await api.get(`stores/${storeId}/promotions`)
            dispatch({ type: GET_PROMOTIONS_BY_STORE, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Promotions
    const getPromotionsByVehicle = async vehicleId => {
        setLoading()
        try {
            const res = await api.get(`vehicles/${vehicleId}/promotions`)
            dispatch({
                type: GET_PROMOTIONS_BY_VEHICLE,
                payload: res.data.data,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Promotions
    const getPromotions = async () => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/promotions?sort=createdAt`)
            dispatch({ type: GET_PROMOTIONS, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Promotion
    const getPromotion = async promotionId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        clearState()
        setLoading()
        try {
            const res = await api.get(`/promotions/${promotionId}`, config)
            dispatch({ type: GET_PROMOTION, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Promotion
    const deletePromotion = async promotionId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/promotions/${promotionId}`, config)
            dispatch({ type: DELETE_PROMOTION, payload: res.data.deletedId })
            history.push('/app/management/promotions')
            CustomAlert({
                title: 'Promotion deleted',
                icon: 'error',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }
    const getPromotionsV3 = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`/promotions/getPromotionsV3`, params)
            dispatch({
                type: GET_PROMOTIONSV3,
                payload: res.data.results.data,
                count: res.data.results.pagination,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create Promotion
    const createPromotion = async promotion => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            let image = await api.post(
                '/utils/uploads/image',
                {
                    type: promotion.photo.type,
                    fileName: promotion.photo.name,
                    folder: 'Promotion',
                },
                config
            )

            await api.put(image.data.url, promotion.photo, {
                headers: {
                    'Content-Type': promotion.photo
                        ? promotion.photo.type
                        : null,
                },
            })
            promotion.image = image.data.key
            delete promotion.photo
            if (promotion.contentHtml) delete promotion.contentHtml
            if (promotion.legalesHtml) delete promotion.legalesHtml
            //se manda la info de la promocion a la api para crear una nueva promocion
            const res = await api.post(`/promotions`, { ...promotion }, config)
            dispatch({ type: CREATE_PROMOTION, payload: res.data.data })
            CustomAlert({
                title: 'Promotion created',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }
    //update Promotion
    const updatePromotion = async (promotion, promotionId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            //se pregunta si existe un cambio en la foto de la promocion para poder actualizarla
            if (promotion.photo) {
                let image = await api.post(
                    '/utils/uploads/image',
                    {
                        type: promotion.photo.type,
                        fileName: promotion.photo.name,
                        folder: 'Promotion',
                    },
                    config
                )

                await api.put(image.data.url, promotion.photo, {
                    headers: {
                        'Content-Type': promotion.photo
                            ? promotion.photo.type
                            : null,
                    },
                })
                promotion.image = image.data.key
                delete promotion.photo
            }
            const res = await api.put(
                `/promotions/${promotionId}`,
                promotion,
                config
            )
            dispatch({ type: UPDATE_PROMOTION, payload: res.data.data })
            CustomAlert({
                title: 'Promotion updated',
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //get Promotion by title
    const getPromotionByTitle = async promotiontitle => {
        clearState()
        setLoading()
        try {
            const res = await api.get(
                `/promotions/title/promotion/${promotiontitle}`
            )
            dispatch({ type: GET_PROMOTION, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <PromotionContext.Provider
            value={{
                loading: state.loading,
                promotions: state.promotions,
                promocion: state.promocion,
                error: state.error,
                count: state.count,
                createPromotion,
                getPromotionsByVehicle,
                getPromotionsByStore,
                getPromotions,
                getPromotionsV3,
                getPromotion,
                deletePromotion,
                clearState,
                setLoading,
                getPromotionByTitle,
                updatePromotion,
            }}
        >
            {props.children}
        </PromotionContext.Provider>
    )
}

export default PromotionState
