import React, { useRef, useState, useEffect } from 'react'
import {
    Avatar,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    SvgIcon,
    Tooltip,
    Typography,
    makeStyles,
    Badge,
} from '@material-ui/core'
import { Bell as BellIcon, MessageCircle as MessageIcon } from 'react-feather'
import moment from 'moment'
import useNotification from 'src/hooks/useNotification'
import useAuth from 'src/hooks/useAuth'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    popover: {
        width: 320,
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
}))

const Notifications = ({ props, ...rest }) => {
    const { user } = useAuth()
    const classes = useStyles()
    const ref = useRef(null)
    const [isOpen, setOpen] = useState(false)
    const { t } = useTranslation()
    const history = useHistory()
    const {
        getNotificationsByUser,
        getNotificationsAdmin,
        notifications,
        updateNotification,
        getNotificationsDesign,
        getNotificationsByTicket,
        getNotificationsByShopping,
    } = useNotification()

    useEffect(() => {
        if (user && user._id) {
            if (user.role === 'rockstar' || user.ticketRole) {
                getNotificationsByTicket(user._id, false)
            } else if (
                user.role === 'user' ||
                user.role === 'marketing' ||
                user.role === 'marketing digital'
            ) {
                getNotificationsByUser(user._id, false)
            } else if (
                user.role === 'admin' ||
                user.role === 'admin marketing digital'
            ) {
                getNotificationsAdmin(false, user._id)
            } else if (user.role === 'designer') {
                getNotificationsDesign(
                    false,
                    user._id,
                    user.mediaType,
                    user.designType
                )
            } else if (
                user &&
                user.shoppingDepartment &&
                ['shopping', 'mtkCorp', 'mtkStore'].includes(
                    user.shoppingDepartment
                )
            ) {
                getNotificationsByShopping(
                    user._id,
                    false,
                    user.shoppingDepartment
                )
            }
        }
        // eslint-disable-next-line
    }, [user])

    const handleMarkAllAsRead = () => {
        if (notifications && notifications.length > 0) {
            notifications.map(notification =>
                updateNotification(notification._id)
            )
        }
    }
    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const updateNotiAndRedirect = notification => {
        updateNotification(notification?._id)
        if (
            notification &&
            notification.type &&
            notification.type.includes('design')
        ) {
            if (notification.type.includes('video')) {
                history.push(
                    `/app/management/designs-video/${notification.design}`
                )
            } else if (notification.type.includes('organic')) {
                history.push(
                    `/app/management/designs-organic/${notification.design}`
                )
            } else {
                history.push(
                    `/app/management/designs-paid/${notification.design}`
                )
            }
        } else if (
            notification &&
            notification.type &&
            notification.type.includes('shopping')
        ) {
            history.push(`/app/management/shopping/${notification.shopping}`)
        } else {
            history.push(`/app/management/tickets/${notification.ticket}`)
        }
    }

    return (
        user && (
            <div {...rest}>
                <Tooltip title="Notifications">
                    <IconButton color="inherit" ref={ref} onClick={handleOpen}>
                        {notifications && notifications.length > 0 ? (
                            <Badge
                                badgeContent={notifications.length}
                                color="error"
                            >
                                <SvgIcon fontSize="small">
                                    <BellIcon />
                                </SvgIcon>
                            </Badge>
                        ) : (
                            <SvgIcon fontSize="small">
                                <BellIcon />
                            </SvgIcon>
                        )}
                    </IconButton>
                </Tooltip>
                <Popover
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    classes={{ paper: classes.popover }}
                    anchorEl={ref.current}
                    onClose={handleClose}
                    open={isOpen}
                >
                    <Box p={2}>
                        <Typography variant="h5" color="textPrimary">
                            {t('Notifications.Notifications')}
                        </Typography>
                    </Box>
                    <Box p={1} display="flex" justifyContent="center">
                        <Button
                            component={RouterLink}
                            size="small"
                            to="#"
                            onClick={handleMarkAllAsRead}
                        >
                            {t('Notifications.AllAsRead')}
                        </Button>
                    </Box>
                    {notifications && notifications.length === 0 ? (
                        <>
                            <Box p={2}>
                                <Typography variant="h6" color="textPrimary">
                                    {t('Notifications.NoNotifications')}
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <div>
                            <List disablePadding>
                                {user &&
                                    notifications &&
                                    notifications.length > 0 &&
                                    notifications.map(notification => {
                                        return (
                                            <ListItem
                                                component={RouterLink}
                                                divider
                                                key={notification?._id}
                                                to="#"
                                                onClick={e =>
                                                    updateNotiAndRedirect(
                                                        notification
                                                    )
                                                }
                                            >
                                                <ListItemAvatar>
                                                    <Avatar
                                                        className={classes.icon}
                                                    >
                                                        <SvgIcon fontSize="small">
                                                            <MessageIcon />
                                                        </SvgIcon>
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={t(
                                                        `Notifications.Have-${notification?.type}`
                                                    )}
                                                    primaryTypographyProps={{
                                                        variant: 'subtitle2',
                                                        color: 'textPrimary',
                                                    }}
                                                    secondary={moment(
                                                        notification?.createdAt
                                                    ).fromNow()}
                                                />
                                            </ListItem>
                                        )
                                    })}
                            </List>
                        </div>
                    )}
                </Popover>
            </div>
        )
    )
}
export default Notifications
