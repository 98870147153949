import {
    GET_CATEGORIES,
    GET_CATEGORY,
    CREATE_CATEGORY,
    DELETE_CATEGORY,
    UPDATE_CATEGORY,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    CHANGE_INDEX,
} from '../types'

export default (state, action) => {
    switch (action.type) {
        case CHANGE_INDEX:
            const { oldIndex, newIndex, start, end } = action.payload
            const old = state.jobCategories.findIndex(
                item => item.index === oldIndex
            )
            const id = state.jobCategories[old]._id
            let sum = 1
            let currentIndex
            if (end < start) {
                for (let i = end; i < start; i++) {
                    const CURRENT_SUM = sum
                    //eslint-disable-next-line
                    currentIndex = state.jobCategories.findIndex(
                        item => item.index === oldIndex + CURRENT_SUM
                    )
                    state.jobCategories[currentIndex].index =
                        state.jobCategories[currentIndex].index - 1
                    sum++
                }

                currentIndex = state.jobCategories.findIndex(
                    item => item._id === id
                )
                state.jobCategories[currentIndex].index = newIndex
            } else {
                for (let i = start; i < end; i++) {
                    const CURRENT_SUM = sum
                    //eslint-disable-next-line
                    currentIndex = state.jobCategories.findIndex(
                        item => item.index === oldIndex - CURRENT_SUM
                    )
                    state.jobCategories[currentIndex].index =
                        state.jobCategories[currentIndex].index + 1

                    sum++
                }
                currentIndex = state.jobCategories.findIndex(
                    item => item._id === id
                )
                state.jobCategories[currentIndex].index = newIndex
            }
            return {
                ...state,
                loading: false,
            }
        case GET_CATEGORIES:
            return {
                ...state,
                count: action.count.total,
                jobCategories: action.payload,
                loading: false,
                error: null,
            }
        case GET_CATEGORY:
            return {
                ...state,
                jobCategory: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_CATEGORY:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case DELETE_CATEGORY:
            if (state.jobCategories && state.jobCategories.length > 0) {
                const deletedIndex = state.jobCategories.findIndex(
                    item => item.index === action.payload.deletedIndex
                )
                for (
                    let i = deletedIndex;
                    i < state.jobCategories.length;
                    i++
                ) {
                    state.jobCategories[i].index =
                        state.jobCategories[i].index - 1
                }
                state.jobCategories = state.jobCategories.filter(
                    item =>
                        item._id.toString() !==
                        action.payload.deletedId.toString()
                )
            }
            return {
                ...state,
                jobCategory: null,
                loading: false,
                error: null,
            }
        case UPDATE_CATEGORY:
            return {
                ...state,
                jobCategory: action.payload,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                jobCategory: {},
                jobCategories: [],
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
