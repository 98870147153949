import React, { useReducer } from 'react'
import CategoryContext from './categoryContext'
import CategoryReducer from './categoryReducer'
import api from '../../api/api'
import {
    GET_CATEGORIES,
    GET_CATEGORY,
    DELETE_CATEGORY,
    UPDATE_CATEGORY,
    CREATE_CATEGORY,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    CHANGE_INDEX,
    UPDATE_INDEX,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'
import { useHistory } from 'react-router-dom'

const CategoryState = props => {
    const history = useHistory()
    const initialState = {
        categories: [],
        category: {},
        loading: false,
        error: null,
        count: 0,
    }

    const [state, dispatch] = useReducer(CategoryReducer, initialState)

    //Get Categories
    const getCategoriesPagination = async ({ limit, page, query }) => {
        setLoading()
        try {
            const res = await api.get(
                `/categories/getCategoriesDashboard?page=${page +
                    1}&limit=${limit}&searchIndex=name&searchText=${query}&sort=index`
            )
            dispatch({
                type: GET_CATEGORIES,
                payload: res.data.data,
                count: res.data.pagination.total,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Change index
    const setIndex = async (newIndex, oldIndex, start, end) => {
        setLoading()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        try {
            const res = await api.post(
                `categories/setIndex`,
                { newIndex, oldIndex },
                config
            )
            dispatch({
                type: CHANGE_INDEX,
                payload: { ...res.data.data, start, end },
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Categories
    const getCategories = async () => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/categories?sort=model`)
            dispatch({ type: GET_CATEGORIES, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Categories
    const getCategory = async categoryId => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/categories/${categoryId}`)
            dispatch({ type: GET_CATEGORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const getCategoriesV3 = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`categories/getCategoriesV3`, params)
            dispatch({
                type: GET_CATEGORIES,
                payload: res.data.results.data,
                count: res.data.results.pagination.total,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Category
    const deleteCategory = async categoryId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/categories/${categoryId}`, config)
            dispatch({ type: DELETE_CATEGORY, payload: res.data.data })
            history.push('/app/management/categories')
            CustomAlert({
                title: 'Category deleted',
                icon: 'error',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create Category
    const createCategory = async category => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.post(`/categories`, category, config)
            dispatch({ type: CREATE_CATEGORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Update Category
    const updateCategory = async (category, categoryId, type) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            let res
            if (type === 'photo') {
                let image = await api.post(
                    '/utils/uploads/image',
                    {
                        type: category.type,
                        fileName: category.name,
                        folder: categoryId,
                    },
                    config
                )
                await api.put(image.data.url, category, {
                    headers: {
                        'Content-Type': category ? category.type : null,
                    },
                })
                res = await api.put(
                    `/categories/${categoryId}`,
                    { image: image.data.key },
                    config
                )
            } else {
                res = await api.put(
                    `/categories/${categoryId}`,
                    { ...category },
                    config
                )
            }

            dispatch({ type: UPDATE_CATEGORY, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const updateIndex = async ({ newData, oldData }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        try {
            let indexes = oldData.map(data => data.index)
            let updatedData = []
            let categories = newData.map((data, dataIndex) => {
                updatedData.push({
                    ...data,
                    index: indexes[dataIndex],
                })
                return {
                    _id: data._id,
                    index: indexes[dataIndex],
                }
            })

            await api.post(`categories/updateIndex`, { categories }, config)
            dispatch({
                type: UPDATE_INDEX,
                payload: updatedData,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <CategoryContext.Provider
            value={{
                loading: state.loading,
                categories: state.categories,
                category: state.category,
                error: state.error,
                count: state.count,
                getCategories,
                getCategory,
                createCategory,
                deleteCategory,
                updateCategory,
                clearState,
                setLoading,
                setIndex,
                getCategoriesPagination,
                getCategoriesV3,
                updateIndex,
            }}
        >
            {props.children}
        </CategoryContext.Provider>
    )
}

export default CategoryState
