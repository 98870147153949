import {
    GET_PROVIDERS,
    GET_PROVIDER,
    CREATE_PROVIDER,
    DELETE_PROVIDER,
    UPDATE_PROVIDER,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_PROVIDERSV3,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

export default (state, action) => {
    switch (action.type) {
        case GET_PROVIDERS:
            return {
                ...state,
                proveedores: action.payload,

                loading: false,
                error: null,
            }
        case GET_PROVIDERSV3:
            return {
                ...state,
                proveedores: action.payload,
                count: action.count.total,
                loading: false,
                error: null,
            }
        case GET_PROVIDER:
            return {
                ...state,
                proveedor: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_PROVIDER:
            return {
                ...state,
                proveedor: action.payload,
                loading: false,
                error: null,
            }
        case DELETE_PROVIDER:
            return {
                ...state,
                proveedor: null,
                loading: false,
                error: null,
            }
        case UPDATE_PROVIDER:
            return {
                ...state,
                proveedor: action.payload,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                proveedor: {},
                proveedores: [],
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
