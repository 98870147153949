import {
    GET_HISTORY_BY_VEHICLE,
    CREATE_HISTORY,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    GET_HISTORY_BY_PREOWNED,
    GET_HISTORY,
} from '../types'
export default (state, action) => {
    switch (action.type) {
        case GET_HISTORY_BY_VEHICLE:
            return {
                ...state,
                histories: action.payload,
                loading: false,
                error: null,
            }
        case GET_HISTORY_BY_PREOWNED:
            return {
                ...state,
                histories: action.payload,
                loading: false,
                error: null,
            }
        case GET_HISTORY:
            return {
                ...state,
                histories: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_HISTORY:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case CLEAR_STATE:
            return {
                history: {},
                histories: [],
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}
