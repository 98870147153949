import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@material-ui/core'

const MainLayout = ({ children }) => {
    const styles = {
        wrapper: {
            backgroundColor: '#E6E6EC',
            backgroundImage: 'url("/static/backgroundLogin.jpeg")',
            backgroundSize: '100%',
            backgroundPosition: 'center',
            display: 'flex',
            minHeight: '100vh',
            overflow: 'hidden',
            paddingTop: '1%',
        },
    }

    return (
        <div style={styles.wrapper}>
            <Container maxWidth="lg">{children}</Container>
        </div>
    )
}

MainLayout.propTypes = {
    children: PropTypes.node,
}

export default MainLayout
