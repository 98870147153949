import React, { useReducer } from 'react'
import ApplicantContext from './applicantContext'
import ApplicantReducer from './applicantReducer'
import api from '../../api/api'
import {
    GET_JOBS,
    GET_JOB,
    DELETE_JOB,
    UPDATE_JOB,
    CREATE_JOB,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    CHANGE_INDEX,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

const ApplicantState = props => {
    const initialState = {
        jobs: [],
        job: {},
        applicants: [],
        applicant: {},
        loading: false,
        error: null,
        count: 0,
    }

    const [state, dispatch] = useReducer(ApplicantReducer, initialState)

    //Change index
    const setIndex = async (newIndex, oldIndex, start, end) => {
        setLoading()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        try {
            const res = await api.post(
                `jobs/setIndex`,
                { newIndex, oldIndex },
                config
            )
            dispatch({
                type: CHANGE_INDEX,
                payload: { ...res.data.data, start, end },
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const getApplicants = async ({ limit, page, query }) => {
        setLoading()
        try {
            const res = await api.get(
                `applicants?page=${page +
                    1}&limit=${limit}&searchIndex=title-name-email&searchText=${query}&sort=index`
            )
            dispatch({
                type: GET_JOBS,
                payload: res.data.data,
                count: res.data.pagination,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const getApplicantsV3 = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`applicants/getApplicantsV3`, params)
            dispatch({
                type: GET_JOBS,
                payload: res.data.results.data,
                count: res.data.results.pagination.total,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const getApplicantsAdvancedResults = async options => {
        setLoading()
        try {
            const res = await api.post(`applicants/advancedResults`, options)
            dispatch({
                type: GET_JOBS,
                payload: res.data.data,
                count: res.data.pagination.total,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const getApplicant = async jobId => {
        setLoading()
        try {
            const res = await api.get(`/applicants/${jobId}`)
            dispatch({ type: GET_JOB, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Job
    const deleteApplicant = async jobId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/applicants/${jobId}`, config)
            dispatch({ type: DELETE_JOB, payload: res.data.data })
            CustomAlert({
                title: 'Applicant Deleted',
                icon: 'error',
                timer: ErrorShowTime,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create Job
    const createJob = async job => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.post(`jobs`, job, config)
            dispatch({ type: CREATE_JOB, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Update Job
    const updateJob = async (job, jobId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.put(`/jobs/${jobId}`, job, config)
            dispatch({ type: UPDATE_JOB, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <ApplicantContext.Provider
            value={{
                loading: state.loading,
                count: state.count,
                job: state.job,
                jobs: state.jobs,
                applicants: state.applicants,
                applicant: state.applicant,
                error: state.error,
                getApplicants,
                getApplicantsV3,
                getApplicant,
                createJob,
                deleteApplicant,
                updateJob,
                getApplicantsAdvancedResults,
                clearState,
                setLoading,
                setIndex,
            }}
        >
            {props.children}
        </ApplicantContext.Provider>
    )
}

export default ApplicantState
