import React, { useReducer } from 'react';
import ComplaintContext from './complaintContext';
import ComplaintReducer from './complaintReducer';
import api from '../../api/api';
import {
    GET_COMPLAINTS,
    GET_COMPLAINT,
    DELETE_COMPLAINT,
    UPDATE_COMPLAINT,
    CREATE_COMPLAINT,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
} from '../types';
import CustomAlert from '../../components/CustomAlert';
import ErrorShowTime from 'src/constants/ErrorShowTime';
import { useHistory } from 'react-router-dom';

const ComplaintState = props => {
    const history = useHistory();
    const initialState = {
        complaints: [],
        complaint: {},
        loading: false,
        error: null,
        count: 0,
    };

    const [state, dispatch] = useReducer(ComplaintReducer, initialState);

    const getComplaintsV3 = async params => {
        clearState();
        setLoading();
        try {
            const res = await api.post(`complaints/getComplaintsV3`, params);
            dispatch({
                type: GET_COMPLAINTS,
                payload: res.data.results.data,
                count: res.data.results.pagination.total,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Complaints
    const getComplaintsPagination = async ({ limit, page, query }) => {
        setLoading();
        try {
            const res = await api.get(
                `complaints/getComplaintsDashboard?page=${page +
                    1}&limit=${limit}&searchIndex=title-category-address&searchText=${query}`
            );
            dispatch({
                type: GET_COMPLAINTS,
                payload: res.data.data,
                count: res.data.pagination,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Get Complaints
    const getComplaint = async complaintId => {
        setLoading();
        try {
            const res = await api.get(`/complaints/${complaintId}`);
            dispatch({ type: GET_COMPLAINT, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Delete Complaint
    const deleteComplaint = async complaintId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': true,
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            const res = await api.delete(`/complaints/${complaintId}`, config);

            dispatch({ type: DELETE_COMPLAINT, payload: res.data.data });
            history.push('/app/management/carone-contigo');
            CustomAlert({
                title: 'Complain deleted',
                icon: 'error',
                timer: ErrorShowTime,
            });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Create Complaint
    const createComplaint = async complaint => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': true,
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            const { meta } = complaint;

            //Load Meta Information
            if (meta && meta.fileImage) {
                let metaImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: meta.fileImage.type,
                        fileName: meta.fileImage.name,
                        folder: complaint._id,
                    },
                    config
                );
                await api.put(metaImage.data.url, meta.fileImage, {
                    headers: {
                        'Content-Type': meta.fileImage
                            ? meta.fileImage.type
                            : null,
                    },
                });

                complaint.meta.image = metaImage.data.key;
                delete complaint.meta.fileImage;
            }

            if (complaint.descriptionHtml) delete complaint.descriptionHtml;
            const res = await api.post(`complaints`, complaint, config);
            dispatch({ type: CREATE_COMPLAINT, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Update Complaint
    const updateComplaint = async (complaint, complaintId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        setLoading();
        try {
            const { meta } = complaint;

            //Load Meta Information
            if (meta && meta.fileImage) {
                let metaImage = await api.post(
                    '/utils/uploads/image',
                    {
                        type: meta.fileImage.type,
                        fileName: meta.fileImage.name,
                        folder: complaint._id,
                    },
                    config
                );
                await api.put(metaImage.data.url, meta.fileImage, {
                    headers: {
                        'Content-Type': meta.fileImage
                            ? meta.fileImage.type
                            : null,
                    },
                });

                complaint.meta.image = metaImage.data.key;
                delete complaint.meta.fileImage;
            }

            const res = await api.put(
                `/complaints/${complaintId}`,
                complaint,
                config
            );
            dispatch({ type: UPDATE_COMPLAINT, payload: res.data.data });
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data });
        }
    };

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE });

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <ComplaintContext.Provider
            value={{
                loading: state.loading,
                count: state.count,
                complaints: state.complaints,
                complaint: state.complaint,
                error: state.error,
                getComplaintsPagination,
                getComplaintsV3,
                getComplaint,
                createComplaint,
                deleteComplaint,
                updateComplaint,
                clearState,
                setLoading,
            }}
        >
            {props.children}
        </ComplaintContext.Provider>
    );
};

export default ComplaintState;
