import {
    GET_PLANRESULTS,
    GET_PLANRESULT,
    CREATE_PLANRESULT,
    DELETE_PLANRESULT,
    UPDATE_PLANRESULT,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

export default (state, action) => {
    switch (action.type) {
        case GET_PLANRESULTS:
            return {
                ...state,
                planResults: action.payload,
                loading: false,
                error: null,
            }
        case GET_PLANRESULT:
            return {
                ...state,
                planResult: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_PLANRESULT:
            state.planResults.push(action.payload)
            return {
                ...state,
                planResult: action.payload,
                loading: false,
                error: null,
            }
        case DELETE_PLANRESULT:
            state.planResults = state.planResults.filter(
                item => item._id.toString() !== action.payload.toString()
            )
            return {
                ...state,
                planResult: null,
                loading: false,
                error: null,
            }
        case UPDATE_PLANRESULT:
            let id = state.planResults.findIndex(
                item => item._id.toString() === action.updatedId.toString()
            )
            state.planResults[id] = action.payload
            return {
                ...state,
                planResult: action.payload,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                planResults: [],
                planResult: {},
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
