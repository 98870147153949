import axios from 'axios';

let baseURL;

if (process.env.NODE_ENV === 'production') {
    baseURL = 'https://api.dashboard.caronecloud.com/api/v1';
} else {
    baseURL = 'http://localhost:5000/api/v1';
}
const WARBOX_API = 'https://api.warbox.io/api/v1';

export { WARBOX_API };

export default axios.create({
    baseURL: baseURL,
});
