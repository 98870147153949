import React, { useReducer } from 'react'
import CarContext from './carContext'
import CarReducer from './carReducer'
import api from '../../api/api'
import {
    GET_CARS,
    GET_CAR,
    DELETE_CAR,
    UPDATE_CAR,
    CREATE_CAR,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    GET_CARS_BY_MAKE,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'
import { useHistory } from 'react-router-dom'

const CarState = props => {
    const history = useHistory()
    const initialState = {
        cars: [],
        car: {},
        loading: false,
        error: null,
        count: 0,
    }

    const [state, dispatch] = useReducer(CarReducer, initialState)

    //Get Cars
    const getCars = async ({ limit, page, query }) => {
        setLoading()
        try {
            const res = await api.get(
                `/cars/getCarsDashboard?page=${page +
                    1}&limit=${limit}&searchIndex=model-category-make-year&searchText=${query}&sort=-createdAt`
            )
            dispatch({
                type: GET_CARS,
                payload: res.data.data,
                count: res.data.pagination,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Cars
    const getCarsByMakeId = async makeId => {
        setLoading()
        try {
            const res = await api.get(`/makes/${makeId}/cars`)
            dispatch({
                type: GET_CARS_BY_MAKE,
                payload: res.data.data,
                count: 0,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Cars
    const getCar = async carId => {
        clearState()
        setLoading()
        try {
            const res = await api.get(`/cars/${carId}`)
            dispatch({ type: GET_CAR, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const getCarsV3 = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`cars/getCarsV3`, params)
            dispatch({
                type: GET_CARS,
                payload: res.data.results.data,
                count: res.data.results.pagination.total,
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Car
    const deleteCar = async carId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/cars/${carId}`, config)
            dispatch({ type: DELETE_CAR, payload: res.data.data })
            CustomAlert({
                title: 'Car Deleted',
                icon: 'error',
                timer: ErrorShowTime,
            })
            history.push('/app/management/cars')
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Create Car
    const createCar = async car => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.post(`cars`, car, config)
            dispatch({ type: CREATE_CAR, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Update Car
    const updateCar = async (car, carId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.put(`/cars/${carId}`, car, config)
            dispatch({ type: UPDATE_CAR, payload: res.data.data })
        } catch (err) {
            if (err && err.response && err.response.data) {
                dispatch({ type: SET_ERROR, payload: err.response.data })
            } else {
                dispatch({ type: SET_ERROR, payload: err })
            }
        }
    }

    //Clear State
    const clearState = () => dispatch({ type: CLEAR_STATE })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    const getCarsByMultipleMakes = async values => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.post(`/cars/byMakes`, { ...values }, config)
            dispatch({
                type: GET_CARS,
                payload: res.data.data,
                count: res.data.pagination,
            })
        } catch (err) {
            if (err && err.response && err.response.data) {
                dispatch({ type: SET_ERROR, payload: err.response.data })
            } else {
                dispatch({ type: SET_ERROR, payload: err })
            }
        }
    }

    return (
        <CarContext.Provider
            value={{
                loading: state.loading,
                count: state.count,
                cars: state.cars,
                car: state.car,
                error: state.error,
                getCars,
                getCar,
                createCar,
                deleteCar,
                updateCar,
                clearState,
                setLoading,
                getCarsByMakeId,
                getCarsByMultipleMakes,
                getCarsV3,
            }}
        >
            {props.children}
        </CarContext.Provider>
    )
}

export default CarState
