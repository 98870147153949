import {
    GET_CARS,
    GET_CAR,
    CREATE_CAR,
    DELETE_CAR,
    UPDATE_CAR,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_CARS_BY_MAKE,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

export default (state, action) => {
    switch (action.type) {
        case GET_CARS_BY_MAKE:
            return {
                ...state,
                cars: action.payload,
                count: action.count.total,
                loading: false,
                error: null,
            }
        case GET_CARS:
            return {
                ...state,
                count: action.count,
                cars: action.payload,
                loading: false,
                error: null,
            }
        case GET_CAR:
            return {
                ...state,
                car: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_CAR:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case DELETE_CAR:
            if (state.cars && state.cars.length > 0) {
                state.cars = state.cars.filter(
                    item =>
                        item._id.toString() !==
                        action.payload.deletedId.toString()
                )
            }
            return {
                ...state,
                car: null,
                loading: false,
                error: null,
            }
        case UPDATE_CAR:
            return {
                ...state,
                car: action.payload,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                car: {},
                cars: [],
                count: 0,
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
