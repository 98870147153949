import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import TopBar from './TopBar'
import useAuth from 'src/hooks/useAuth'
import NavBar from './NavBar'
import { socket } from 'src/service/socket'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256,
        },
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
    },
}))

const DashboardLayout = ({ children }) => {
    const classes = useStyles()
    const [isMobileNavOpen, setMobileNavOpen] = useState(false)
    const { loadUser, user } = useAuth()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    useEffect(() => {
        loadUser()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const keys = []
        for (var k in socket._callbacks) keys.push(k)
        keys.map(item => {
            if (socket._callbacks[item].length > 1)
                socket._callbacks[item] = [socket._callbacks[item][0]]

            return false
        })
    }, [])

    useEffect(() => {
        if (user && user.stores) {
            if (user.role === 'rockstar') {
                socket.emit('login', 'rockstar-tickets-room')
            } else if (user.ticketRole) {
                socket.emit('login', `${user.ticketRole}-tickets-room`)
            } else {
                socket.emit('login', `${user._id}-tickets-room`)
            }
            user.stores.forEach(store => {
                if (
                    user.role === 'admin' ||
                    user.role === 'admin marketing digital'
                ) {
                    socket.emit('login', `${store._id._id}-room-design`)
                }
                if (user.mediaType === 'video') {
                    socket.emit(
                        'login',
                        `${store._id._id}-room-design${user.mediaType}`
                    )
                } else {
                    if (user.designType !== 'both') {
                        socket.emit(
                            'login',
                            `${store._id._id}-room-design${user.designType}`
                        )
                    } else {
                        socket.emit(
                            'login',
                            `${store._id._id}-room-designorganic`
                        )
                        socket.emit('login', `${store._id._id}-room-designpaid`)
                    }
                }
            })
        }

        if (user && user.shoppingDepartment) {
            if (user.shoppingDepartment === 'mtkCorp') {
                socket.emit('login', `mtkCorp-room-shopping`)
            } else if (user.shoppingDepartment === 'mtkStore') {
                socket.emit('login', `mtkStore-room-shopping`)
            } else if (user.shoppingDepartment === 'shopping') {
                socket.emit('shopping', `mtkStore-room-shopping`)
            }
        }
        // eslint-disable-next-line
    }, [user])

    useEffect(() => {
        socket.on('getNotificationDesign', data => {
            enqueueSnackbar(t(`Notifications.Create-${data.type}`), {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'info',
            })
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        socket.on('getNotificationDesignAdmin', data => {
            enqueueSnackbar(t(`Notifications.Create-${data.type}`), {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'info',
            })
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        socket.on('getTicketRockstar', data => {
            enqueueSnackbar(t(`Notifications.NewTicket`), {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'info',
            })
        })

        socket.on('getTicketAssignet', data => {
            enqueueSnackbar(t(`Notifications.NewTicket`), {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'info',
            })
        })

        socket.on('getNotificationYourTicket', data => {
            enqueueSnackbar(t(`Notifications.UpdateTicket`), {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'info',
            })
        })

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        socket.on('getShopping', data => {
            enqueueSnackbar(t(`Notifications.NewShopping`), {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'info',
            })
        })
        // eslint-disable-next-line
    }, [])

    return (
        <div className={classes.root}>
            <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
            <NavBar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
            />
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        {children && children}
                    </div>
                </div>
            </div>
        </div>
    )
}

DashboardLayout.propTypes = {
    children: PropTypes.node,
}

export default DashboardLayout
