import {
    GET_COMPLAINTS,
    GET_COMPLAINT,
    CREATE_COMPLAINT,
    DELETE_COMPLAINT,
    UPDATE_COMPLAINT,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
} from '../types'
import CustomAlert from '../../components/CustomAlert'
import ErrorShowTime from 'src/constants/ErrorShowTime'

export default (state, action) => {
    switch (action.type) {
        case GET_COMPLAINTS:
            return {
                ...state,
                complaints: action.payload,
                count: action.count,
                loading: false,
                error: null,
            }
        case GET_COMPLAINT:
            return {
                ...state,
                complaint: action.payload,
                loading: false,
                error: null,
            }
        case CREATE_COMPLAINT:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case DELETE_COMPLAINT:
            if (state.complaints && state.complaints.length > 0) {
                state.complaints = state.complaints.filter(
                    complaint => complaint._id !== action.payload.deletedId
                )
            }
            return {
                ...state,
                complaint: null,
                loading: false,
                error: null,
            }
        case UPDATE_COMPLAINT:
            return {
                ...state,
                complaint: action.payload,
                loading: false,
                error: null,
            }
        case SET_ERROR:
            CustomAlert({
                title: action?.payload?.error || action?.payload,
                icon: 'error',
                timer: ErrorShowTime,
            })
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLEAR_STATE:
            return {
                complaint: {},
                complaints: [],
                loading: false,
                error: null,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
