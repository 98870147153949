import React, { useReducer } from 'react'
import DesignContext from './designContext'
import DesignReducer from './designReducer'
import api from '../../api/api'
import {
    GET_DESIGNS,
    GET_DESIGN,
    DELETE_DESIGN,
    SET_ERROR,
    SET_LOADING,
    CLEAR_STATE,
    CREATE_DESIGN,
    UPDATE_DESIGN,
} from '../types'
import ErrorShowTime from 'src/constants/ErrorShowTime'
import CustomAlert from 'src/components/CustomAlert'

const DesignState = props => {
    const initialState = {
        designs: [],
        count: 0,
        design: {},
        loading: false,
        error: null,
    }

    const [state, dispatch] = useReducer(DesignReducer, initialState)

    const createDesign = async design => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            let imagesArr = []
            Object.entries(design).forEach(async row => {
                let [key, obj] = row
                if (
                    obj?.file ||
                    (typeof obj == 'object' &&
                        obj?.length >= 1 &&
                        obj?.some(img => img?.file || img?.value))
                ) {
                    imagesArr.push(key)
                } else if (obj?.value) {
                    delete design[key]
                }
            })

            for (let index = 0; index < imagesArr.length; index++) {
                const key = imagesArr[index]
                if (design?.[key]?.length >= 1) {
                    let newImagesArr = []
                    for (let index = 0; index < design[key].length; index++) {
                        const image = design[key][index]
                        if (image?.file) {
                            let newFullImage = {}
                            // first main Image
                            let newImage = await api.post(
                                '/utils/uploads/image',
                                {
                                    type: image.file.type,
                                    fileName: image.file.name,
                                    folder: 'designs',
                                },
                                config
                            )
                            await api.put(newImage.data.url, image.file, {
                                headers: {
                                    'Content-Type': image.file
                                        ? 'application/octet-stream'
                                        : null,
                                },
                            })
                            newFullImage.value = newImage.data.key
                            // then tumbnail
                            if (image?.file?.tumbnailFile) {
                                newImage = await api.post(
                                    '/utils/uploads/image',
                                    {
                                        type: image.file.type,
                                        fileName: image.file.name,
                                        folder: 'designs-tumbnails',
                                    },
                                    config
                                )
                                await api.put(
                                    newImage.data.url,
                                    image.file.tumbnailFile,
                                    {
                                        headers: {
                                            'Content-Type': image.file
                                                ? 'application/octet-stream'
                                                : null,
                                        },
                                    }
                                )
                                newFullImage.tumbnail = newImage.data.key
                            }
                            if (image?.file) delete image.file

                            newImagesArr.push({ ...image, ...newFullImage })
                        } else {
                            newImagesArr.push(image)
                        }
                    }
                    design[key] = newImagesArr
                } else {
                    let newImage = await api.post(
                        '/utils/uploads/image',
                        {
                            type: design[key].file.type,
                            fileName: design[key].file.name,
                            folder: 'designs',
                        },
                        config
                    )
                    await api.put(newImage.data.url, design[key].file, {
                        headers: {
                            'Content-Type': design[key].file
                                ? 'application/octet-stream'
                                : null,
                        },
                    })
                    design[key] = newImage.data.key
                }
            }
            const res = await api.post(`designs`, design, config)
            dispatch({ type: CREATE_DESIGN, payload: res.data.data })
            let mediaType = res?.data?.data?.mediaType
            CustomAlert({
                title: `${mediaType === 'video' ? 'Video' : 'Diseño'} Creado`,
                icon: 'success',
                timer: ErrorShowTime,
                // handleThen: () => {
                //   if (mediaType === 'video')
                //     CustomAlert({
                //       title: 'Estará listo en 2 días hábiles',
                //       icon: 'info'
                //     });
                // }
            })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    const updateDesign = async (design, ID) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()

        try {
            let imagesArr = []
            Object.entries(design).forEach(async row => {
                let [key, obj] = row
                if (
                    obj?.file ||
                    (typeof obj == 'object' &&
                        obj?.length >= 1 &&
                        obj?.some(img => img?.file || img?.value))
                ) {
                    imagesArr.push(key)
                } else if (obj?.value) {
                    delete design[key]
                }
            })

            for (let index = 0; index < imagesArr.length; index++) {
                const key = imagesArr[index]
                if (design?.[key]?.length >= 1) {
                    let newImagesArr = []
                    for (let index = 0; index < design[key].length; index++) {
                        const image = design[key][index]
                        if (image?.file) {
                            let newFullImage = {}
                            // first main Image
                            let newImage = await api.post(
                                '/utils/uploads/image',
                                {
                                    type: image.file.type,
                                    fileName: image.file.name,
                                    folder: 'designs',
                                },
                                config
                            )
                            await api.put(newImage.data.url, image.file, {
                                headers: {
                                    'Content-Type': image.file
                                        ? 'application/octet-stream'
                                        : null,
                                },
                            })
                            newFullImage.value = newImage.data.key
                            // then tumbnail
                            if (image?.file?.tumbnailFile) {
                                newImage = await api.post(
                                    '/utils/uploads/image',
                                    {
                                        type: image.file.type,
                                        fileName: image.file.name,
                                        folder: 'designs-tumbnails',
                                    },
                                    config
                                )
                                await api.put(
                                    newImage.data.url,
                                    image.file.tumbnailFile,
                                    {
                                        headers: {
                                            'Content-Type': image.file
                                                ? 'application/octet-stream'
                                                : null,
                                        },
                                    }
                                )
                                newFullImage.tumbnail = newImage.data.key
                            }
                            if (image?.file) delete image.file

                            newImagesArr.push({ ...image, ...newFullImage })
                        } else {
                            newImagesArr.push(image)
                        }
                    }
                    design[key] = newImagesArr
                } else {
                    let newImage = await api.post(
                        '/utils/uploads/image',
                        {
                            type: design[key].file.type,
                            fileName: design[key].file.name,
                            folder: 'designs',
                        },
                        config
                    )
                    await api.put(newImage.data.url, design[key].file, {
                        headers: {
                            'Content-Type': design[key].file
                                ? 'application/octet-stream'
                                : null,
                        },
                    })
                    design[key] = newImage.data.key
                }
            }
            const res = await api.put(`/designs/${ID}`, design, config)
            dispatch({ type: UPDATE_DESIGN, payload: res.data.data })
            let mediaType = res?.data?.data?.mediaType
            CustomAlert({
                title: `${
                    mediaType === 'video' ? 'Video' : 'Diseño '
                } Actualizado`,
                icon: 'success',
                timer: ErrorShowTime,
            })
        } catch (err) {
            if (err && err.response && err.response.data) {
                dispatch({ type: SET_ERROR, payload: err.response.data })
            } else {
                dispatch({ type: SET_ERROR, payload: err })
            }
        }
    }

    //Get Designs
    const getDesigns = async params => {
        clearState()
        setLoading()
        try {
            const res = await api.post(`/designs/getDesignsV3`, params)
            dispatch({ type: GET_DESIGNS, payload: res.data.results })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Get Designs
    const getDesign = async (designId, dontClean = []) => {
        clearState(dontClean)
        setLoading()
        try {
            const res = await api.get(`/designs/${designId}`)
            dispatch({ type: GET_DESIGN, payload: res.data.data })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Delete Design
    const deleteDesign = async designId => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        setLoading()
        try {
            const res = await api.delete(`/designs/${designId}`, config)
            dispatch({ type: DELETE_DESIGN, payload: res.data.deletedId })
        } catch (err) {
            dispatch({ type: SET_ERROR, payload: err.response.data })
        }
    }

    //Clear State
    const clearState = (dontClean = []) =>
        dispatch({ type: CLEAR_STATE, dontClean })

    //Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return (
        <DesignContext.Provider
            value={{
                loading: state.loading,
                designs: state.designs,
                count: state.count,
                design: state.design,
                error: state.error,
                createDesign,
                updateDesign,
                getDesigns,
                getDesign,
                deleteDesign,
                clearState,
                setLoading,
            }}
        >
            {props.children}
        </DesignContext.Provider>
    )
}

export default DesignState
